import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom'
import {Breadcrumb, Button, Card} from 'antd'
import axios from "axios";
import {useApiContext} from "../providers/ApiProvider";
import CsvImport from "../components/Form/CsvImport";

const Private = (props) => {

  const hpeEndpoint = "http://api.hotelpricexplorer.com/v1/etb"
  const [accounts, setAccounts] = useState([])
  const [apiState, apiDispatch] = useApiContext();
  const {apiCreateEntity, apiGooglePlaceAutocomplete, apiGooglePlaceDetail} = apiDispatch;
  const [loading, setLoading] = useState(true)
    const [modalVisibles, setModalVisible] = useState(false)
  useEffect(() => {
    // fetchHPEAccounts()
  }, [])

  async function get(url, external_dispatcher) {
    let response = await axios({
      method: 'get',
      url: url,
    });
    external_dispatcher(response.data)
  }

  function fetchHPEAccounts() {
    get(hpeEndpoint, response => {
      console.log(response.data);
      setHpeAccounts(response.data);
    })
  }


  async function findPlace() {
    accounts.map(async account => {
      if (account.hasOwnProperty('url')) {
        let accountUrl = urlParser(account.url)
        console.log(accountUrl)
        const googleResponse = await apiGooglePlaceAutocomplete(account.name)
        if (googleResponse.predictions.length > 0) {
          let possibleMatch = googleResponse.predictions.filter(prediction => prediction.types.length > 0 && prediction.types.includes("lodging"))
          console.log(possibleMatch)
          if (possibleMatch.length > 0) {
            let googlePlace = await apiGooglePlaceDetail(possibleMatch[0].place_id)
            console.log(googlePlace)
            if (googlePlace.status === 'OK' && googlePlace.result.website.includes(accountUrl)) {
              console.log('its a match ')
              handlePlacesCreation(account, possibleMatch[0], googlePlace)
            } else {
              console.log('no match')
            }
          }
        }
      }
    })

  }


  async function handlePlacesCreation(account, prediction, place) {

    let mmcPlace = {}
    mmcPlace.name = place.result.name ? place.result.name : account.name;
    mmcPlace.gps = place.result.gps ? place.result.gps : null;
    mmcPlace.phone = place.result.phone ? place.result.phone : '';
    mmcPlace.address = place.result.address ? place.result.address : null;
    mmcPlace.city = place.result.city ? place.result.city : null;
    mmcPlace.zip = place.result.zip ? place.result.zip : null;
    mmcPlace.country = place.result.country ? place.result.country : null;
    mmcPlace.countryCode = place.result.countryCode ? place.result.countryCode : null;
    mmcPlace.openingHours = place.result.openingHours ? place.result.openingHours : null;
    mmcPlace.rating = place.result.rating ? place.result.rating : null;
    mmcPlace.userRatingsTotal = place.result.userRatingsTotal ? place.result.userRatingsTotal : null;
    mmcPlace.website = place.result.website ? place.result.website : null;
    mmcPlace.types = place.result.types ? place.result.types : null;
    mmcPlace.gPlaceId = prediction.place_id;
    mmcPlace.account = account['@id']
    const createResponse = await apiCreateEntity('mmc_places', mmcPlace)
    console.log('created', createResponse)
  }

  function urlParser(url) {
    url = url.replace('http://', '').replace('https://', '')
    url = url.match(/^(www)?.*\.[a-z]{2,3}/)[0];
    console.log(url)
    return url
  }

  let cardTitle = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Link to="/">Private</Link>
      </Breadcrumb.Item>
    </Breadcrumb>
  );

  return (
    <Card title={cardTitle}>
      {/*Hpe recupération and account auto création*/}
      {/*{*/}
      {/*  hpeAccounts.length ?*/}
      {/*    <Button type="primary" onClick={searchAccount}>Create account from hpe</Button>*/}
      {/*    : null*/}
      {/*}*/}
      <CsvImport modal onClose={()=>setModalVisible(false)} visible={modalVisibles}/>
      <Button onClick={()=>setModalVisible(true)} >Open</Button>
      {/*<Button type="primary" onClick={findPlace} disabled={loading}>find place </Button>*/}

    </Card>
  );
};

export default Private;

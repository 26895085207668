import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Input, Select } from 'antd'
import countries from 'i18n-iso-countries';

import FaIcon from '../FaIcon.js';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons/faExclamationCircle'
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons/faExclamationTriangle'
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck'

import { useApiContext } from '../../providers/ApiProvider.js';
import Countdown from '../../components/Countdown.js';

countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
const countriesFr = countries.getNames("fr");

const FormCountry = ({onChange, name, fullname, value, ...rest}) => {

  const originalValue = ''+(value || '')
  const [internalValue, setInternalValue ] = useState(originalValue)
  const [saveNeeded, setSaveNeeded ] = useState(false)

  const handleInputChange = (newValue) => {
    let chunk = {};
    chunk[name] = newValue
    onChange(chunk);

    setInternalValue(newValue);
    setSaveNeeded(newValue!==originalValue);
  }

  let options = [];
  for(let countryCode in countriesFr) {
    options.push(<Select.Option key={countryCode} value={countryCode}>{countriesFr[countryCode]}</Select.Option>);
  }

  const suffix = internalValue==='' ? <FaIcon icon={faExclamationCircle} style={{color:"#ff9800"}} /> : (saveNeeded ? <FaIcon icon={faExclamationCircle} style={{color:"#dd5246"}} /> : <FaIcon icon={faCheck} />);

  return (
    <Input.Group className="formInput" style={{ display: 'flex', flexDirection:'row' }}>
      <Select
        suffixIcon={suffix}
        showSearch
        style={{flex:1}}
        defaultValue={internalValue}
        onChange={handleInputChange}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        {...rest}
        >
        {options}
      </Select>
    </Input.Group>
  );
};

export default FormCountry;

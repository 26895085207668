import React, { useState, useEffect } from 'react';
import { Checkbox } from 'antd'


const FormCheckbox = ({ children, onChange, name, value, contentVersion, ...rest }) => {

  const originalValue = value ? true : false;
  const [internalValue, setInternalValue] = useState(originalValue)
  const [saveNeeded, setSaveNeeded] = useState(false)

  useEffect(() => {
    let newValue = value ? true : false
    console.log(newValue, value)
    setInternalValue(newValue)
  }, [contentVersion])

  const handleInputChange = (event) => {
    let chunk = {};
    let newValue = event.target.checked;
    chunk[name] = newValue
    onChange(chunk);

    setInternalValue(newValue);
    setSaveNeeded(newValue !== originalValue);
  }

  return <Checkbox name={name} checked={internalValue} onChange={handleInputChange}>{children}</Checkbox>
};

export default FormCheckbox;

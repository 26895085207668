export const checkLocalStorage = (itemName, defaultValue = {}) => {
  let localStorage = window.localStorage;
  let item = JSON.parse(localStorage.getItem(itemName));
  // console.log('sto', item)
  if (item) {
    return item;
  }
  return defaultValue

}

export const saveFilterInStorage = (itemName, value = {}) => {
  let localStorage = window.localStorage;
  localStorage.setItem(itemName, JSON.stringify(value));
}

export const clearLocalStorage = (itemName) => {
  let localStorage = window.localStorage;
  localStorage.removeItem(itemName)
}

export const cleanUrl = (url) => {
  const pattern = /^(http|https):\/\//;
  if (url.match(pattern)) return url;

  return 'http://' + url;
}

export const forceHttpsUrl = (url) => {
  return 'https://' + url.replace(/^(http|https):\/\//, '')
}

export function convertToSlug(Text) {
  return Text.toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');
}

import React from "react";
import {Breadcrumb, Card} from "antd";
import {Link} from "react-router-dom";
import ScrapperDash from "../components/Scrapper/ScrapperDash";

const Scrapper = () => {

  let cardTitle = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Link to="/">Home</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Link to="/scrapper/">Scrapper</Link>
      </Breadcrumb.Item>
    </Breadcrumb>
  );

  return (
    <Card title={cardTitle}>
      <ScrapperDash />
    </Card>
  );

}

export default Scrapper

const hydratools = {
  prePatchEntity: function(entity){
    // cleanup all neested documents attributes => object replaced by IRI

    let _entity = JSON.parse(JSON.stringify(entity));
    for(let i in _entity)
    {
      if(_entity[i] && _entity[i]['@id'])
        _entity[i] = _entity[i]['@id'];

      if(_entity[i] && _entity[i].length !== undefined)
      {
        for(let j in _entity[i])
        {
          if(_entity[i][j] && _entity[i][j]['@id'])
            _entity[i][j] = _entity[i][j]['@id'];
        }
      }
    }
    return _entity;
  }
}

export default hydratools

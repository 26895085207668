import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import { Tag, Button } from 'antd';

import ApiCollectionLatest from '../ApiCollectionLatest.js';

import FaIcon from '../FaIcon.js';
import { faPhoneRotary } from '@fortawesome/pro-solid-svg-icons/faPhoneRotary'
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope'

import ContactRoleForm from './ContactRoleForm.js'


const ContactRoleDash = ({id, endpoint, cardTitle, ...rest}) => {

  const [selectedItem, setSelectedItem ] = useState(null)
  const [creationMode, setCreationMode ] = useState(false)

  const [contentVersion, setContentVersion ] = useState(0)

  const handleCreationFormClose = () => {
    setCreationMode(false);
  }
  const handleCreationFormChange = () => {
  }

  const handleEditFormClose = () => {
    setSelectedItem(null);
  }
  const handleEditFormChange = () => {
    setContentVersion((new Date()).getTime());
  }

  const columns = [
    {
      title: '',
      dataIndex: 'name',
      search: true,
      sorter: true,
      render: (name,record) => <React.Fragment>
        {name}
      </React.Fragment>
    }
  ];

  if(creationMode)
    return <ContactRoleForm modal item_id="new" entity_id={id} endpoint={endpoint} onClose={handleCreationFormClose} onChange={handleCreationFormChange}/>;

  return (
    <React.Fragment>
      <ApiCollectionLatest
        title={(currentPageData) => <React.Fragment>
            <Button size="small" onClick={()=>{setCreationMode(true)}}>+</Button>
          <b> {cardTitle ? cardTitle : "Rôles des contacts"}</b>
        </React.Fragment>}
        fetch_endpoint="contact_roles"
        columns={columns}
        pageSize={5}
        // onClick={(item) => setSelectedItem({...item}) }
        contentVersion={contentVersion}
        {...rest}
      />
      {/*{selectedItem ? <ContactDrawer item={selectedItem} onClose={handleEditFormClose} onChange={handleEditFormChange} /> : null}*/}
    </React.Fragment>
  );
};

export default ContactRoleDash;

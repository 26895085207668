import React, {useState} from 'react';
import {Button, Card, Typography} from 'antd';

import ApiCollectionLatest from '../ApiCollectionLatest.js';
import moment from "moment";
import FaIcon from "../FaIcon";
import {faUser} from "@fortawesome/pro-solid-svg-icons/faUser";
import {faBell, faBellSlash, faClock} from "@fortawesome/pro-light-svg-icons";
import CommentForm from "./CommentForm";
import {useApiContext} from "../../providers/ApiProvider";
import {commentRelationResolver} from "./_helpers";
import {Link} from "react-router-dom";

const CommentDash = ({id, endpoint, cardTitle, customSearch = {},  ...rest}) => {

  const [apiState, apiDispatch] = useApiContext();
  const {apiUpdateEntity} = apiDispatch;
  const [selectedItem, setSelectedItem] = useState(null)
  const [creationMode, setCreationMode] = useState(false)

  const [contentVersion, setContentVersion] = useState(0)

  const handleCreationFormClose = () => {
    setCreationMode(false);
  }
  const handleCreationFormChange = () => {
    setContentVersion((new Date()).getTime());
  }

  const handleEditFormClose = () => {
    setSelectedItem(null);
  }
  const handleEditFormChange = () => {
    setContentVersion((new Date()).getTime());
  }

  const updateAlert = async (item, property) => {
    let data = {
      [property]: moment(new Date()).format('')
    }
    const update_response = await apiUpdateEntity('comments', item.id, data)
    console.log(update_response);
    handleEditFormChange()
  }

  const columns = [
    {
      title: 'Texte',
      dataIndex: 'text',
      render: (text, record) => <Typography.Paragraph
        ellipsis={{rows: 3}}
        style={{
          whiteSpace: "pre-wrap",
          overflowWrap: "break-word",
          overflowX: "hidden"
        }}>
        {text}
      </Typography.Paragraph>
    },
    {
      title: 'user',
      dataIndex: 'createdAt',
      width: "15%",
      render: (createdAt, record) => <small>
        <FaIcon icon={faUser}/> {record.author.username}
        <br/>
        <FaIcon icon={faClock}/> {moment(createdAt).format("HH:mm DD-MM-YY")}
      </small>
    },
    {
      title: "alert",
      dataIndex: 'alertDate',
      width: "15%",
      render: (alertDate, record) =>
        !alertDate ?
          <small>
            <span style={{cursor: 'pointer', color: 'blue'}} onClick={(e) => {
              e.stopPropagation();
              updateAlert(record, 'alertDate')
            }}>
              <FaIcon icon={faBell}/> Créer une alerte
            </span>
          </small>
          :
          <small>
            <span style={{color: !record.alertEndDate ? 'red' : ''}}>
              <FaIcon icon={faBell}/> {moment(alertDate).format("HH:mm DD-MM-YY")}
            </span>
            <br/>
            {
              record.alertEndDate ?
                <>
                  <FaIcon icon={faBellSlash}/> {moment(record.alertEndDate).format("HH:mm DD-MM-YY")}
                </>
                : <span style={{cursor: 'pointer', color: 'green'}} onClick={(e) => {
                  e.stopPropagation();
                  updateAlert(record, 'alertEndDate')
                }}><FaIcon icon={faBellSlash}/> Terminer l'alerte</span>

            }
          </small>
    },
  ];
  const relationColum =   {
    title: 'Parent',
    dataIndex: 'id',
    width: "20%",
    render: (id, record) => {
      let relation = commentRelationResolver(record)
      return (
        <small>
          <span>{record[relation.link].name}</span>
          <br/>
          <span>({relation.name})</span>
        </small>
      )
    }
  }

  const handleClick = (item) => {
    let relation = commentRelationResolver(item);
    if (relation && !id) {
      window.location.href = `${relation.link}/${relation.id}`
    }
  }

  // if(creationMode)
  //   return <CommentForm modal item_id="new" entity_id={id} endpoint={endpoint} onClose={handleCreationFormClose} onChange={handleCreationFormChange}/>;

  return (
    <React.Fragment>
      {
        creationMode ?
          <Card
            size="small"
            title={<React.Fragment>
              <b> {"Post a comment"}</b>
            </React.Fragment>}
          >
            <CommentForm item_id="new" entity_id={id} endpoint={endpoint} onClose={handleCreationFormClose}
                         onChange={handleCreationFormChange}/>
          </Card>
          :
          <ApiCollectionLatest
            title={(currentPageData) => <React.Fragment>
              {
                id && endpoint ? <Button size="small" onClick={() => {
                  setCreationMode(true)
                }}>+</Button> : null
              }
              <b> {cardTitle ? cardTitle : "Comments"}</b>
              {/*<Link style={{float:"right"}} to={"/"+endpointResolver(endpoint)+"/"+id+"/comments"}>See all</Link>*/}
            </React.Fragment>}
            fetch_endpoint={id && endpoint ? `${endpoint}/${id}/comments` : "comments"}
            delete_endpoint="comments"
            columns={id ? columns : [...columns, relationColum]}
            pageSize={5}
            onClick={(item) => handleClick(item)}
            contentVersion={contentVersion}
            customSearch={id ? {} : customSearch}
            {...rest}
          />
      }
    </React.Fragment>
  );
};

export default CommentDash;

import React from "react";
import {Breadcrumb, Card, Col, Row} from "antd";
import {Link} from "react-router-dom";
import ProductDash from "../components/Product/ProductDash";
import TagDash from "../components/Tag/TagDash";
import ContactRoleDash from "../components/ContactRole/ContactRoleDash";

export default function Settings() {

  let cardTitle = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Link to="/">Home</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Link to="/settings/">Settings</Link>
      </Breadcrumb.Item>
    </Breadcrumb>
  );

  return (
    <div>
      <Card title={cardTitle}>
        <Row type="flex" gutter={16}>
          <Col span={12}>
            <TagDash type={0} />
          </Col>
          <Col span={12}>
            <ProductDash />
          </Col>
          <Col span={12}>
            <TagDash type={1} />
          </Col>
          <Col span={12}>
            <ContactRoleDash type={1} />
          </Col>
        </Row>
      </Card>
    </div>
  );
}

import React, { useEffect, useRef, useState } from "react";
import { Input, Button, message, Row, Col, Card, Switch, Tooltip } from "antd";
import { Editor } from "@tinymce/tinymce-react";
import { useApiContext } from "../../../providers/ApiProvider";
import { useHistory, useParams } from "react-router-dom";
import {
  faArrowLeft,
  faQuestionCircle,
} from "@fortawesome/pro-solid-svg-icons";
import FaIcon from "../../FaIcon";
import CategoriesTreeSelect from "./CategoriesTreeSelect";
import LngSelect from "./LngSelect";
import { resolveProperty } from "../helpers";
import ApiCollectionTags from "../../ApiCollectionTags";
import FormSelect from "../../Form/FormSelect";

export default function EditArticle({ article, setArticle, setLoading }) {
  const history = useHistory();

  const [apiState, apiDispatch] = useApiContext();
  const { apiUpdateEntity, apiPostRequest, apiCreateEntity } = apiDispatch;
  const [internalArticle, setInternalArticle] = useState({});
  const [content, setContent] = useState({});
  const [videos, setVideos] = useState({});
  const [lng, setLng] = useState("fr");
  let tinyCallback = () => {};
  const editorRef = useRef(null);
  const params = useParams();

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.setContent(content[lng] || "");
    }
  }, [lng]);

  useEffect(() => {
    if (article) {
      setContent(article?.content || { fr: "" });
      setVideos(article?.video || { fr: "" });
      setInternalArticle({ ...article });
    }
  }, [article]);

  const [hapidamClient, setHapidamClient] = useState(null);
  const [isHapidamListener, setIsHapidamListener] = useState(false);

  useEffect(() => {
    if (!hapidamClient) {
      setHapidamClient(new Hapidam());
    } else {
      if (typeof localStorage.getItem("hapidamKey") === "string") {
        localStorage.removeItem("hapidamKey");
      }

      var hapidamKey = JSON.parse(localStorage.getItem("hapidamKey"));
      let now = new Date().getTime();
      let isHapidamKeyWorking = hapidamKey?.time
        ? hapidamKey.time + 3600000 > now
        : false;

      if (hapidamKey?.key && isHapidamKeyWorking) {
        hapidamClient.setToken(hapidamKey.key);
      } else {
        fetchHapidamKey();
      }
    }
  }, [hapidamClient]);

  async function fetchHapidamKey() {
    let response = await apiPostRequest("hapidam-connect", {});
    if (response.success) {
      localStorage.setItem(
        "hapidamKey",
        JSON.stringify({
          time: new Date().getTime(),
          key: response.publicKey,
        })
      );
      hapidamClient.setToken(response.publicKey);
    }
  }

  const handleHapidam = () => {
    if (!isHapidamListener) {
      hapidamClient.on("select", handleResponse);
      setIsHapidamListener(true);
    }
    hapidamClient.open();
  };

  const handleResponse = (response) => {
    hapidamClient.off("select", handleResponse);
    setIsHapidamListener(false);
    hapidamClient.close();

    if (response.display) {
    }
    tinyCallback(response.display.native.md);

    tinyCallback = () => {};
  };

  const handleSave = async () => {
    let promotionDate = internalArticle?.promotionDate;
    if (typeof promotionDate === "boolean") {
      promotionDate = promotionDate ? new Date() : null;
    }

    setLoading(true);
    let newData = {
      ...internalArticle,
      content: content,
      video: videos,
      isActive: internalArticle.isActive,
      product: "/api/support_products/" + params.product.split("-").shift(),
      promotionDate: promotionDate,
    };
    // console.log(newData)
    if (article.id === "new") {
      const response = await apiCreateEntity("articles", newData);
      // console.log('response', response)
      if (response && response["@type"] === "hydra:Error") {
        message.error(response["hydra:description"]);
      }
      if (response && response.id) {
        setArticle({ ...response });
      }
    } else {
      await apiUpdateEntity("articles", article.id, newData);
    }
    setLoading(false);
  };

  const handleLngChange = (setter, value) => {
    setter((prev) => {
      return { ...prev, [lng]: value };
    });
  };

  const handleChange = (property, value) => {
    setInternalArticle((prev) => {
      return { ...prev, [property]: value };
    });
  };
  const handleIsActive = (checked) => {
    let activeLngs = [...internalArticle.activeLngs];

    if (checked) {
      activeLngs = [...internalArticle.activeLngs, lng];
    } else {
      activeLngs = activeLngs.filter((item) => item !== lng);
    }

    setInternalArticle((prev) => {
      return { ...prev, activeLngs: activeLngs };
    });
  };

  return (
    <div>
      <Row gutter={16}>
        <Col span={18}>
          <Card
            title={
              <div className="flex between">
                <div>
                  <Button className="mr-1" onClick={history.goBack}>
                    <FaIcon icon={faArrowLeft} />
                  </Button>
                  <span>
                    {article?.id !== "new"
                      ? `Editer un article`
                      : "Créer votre article"}
                  </span>
                </div>
                <div>
                  <LngSelect lng={lng} onChange={setLng} />
                </div>
              </div>
            }
          >
            <div className="mb-1">
              <p>Titre de l'article</p>
              <Input
                value={internalArticle[resolveProperty("title", lng)]}
                onChange={(e) =>
                  handleChange(resolveProperty("title", lng), e.target.value)
                }
              />
            </div>

            <div className="mb-1">
              <p>Description de l'article</p>
              <Input.TextArea
                rows={4}
                value={internalArticle[resolveProperty("description", lng)]}
                onChange={(e) =>
                  handleChange(
                    resolveProperty("description", lng),
                    e.target.value
                  )
                }
              />
            </div>

            <p>Contenu de l'article</p>
            <Editor
              onInit={(evt, editor) => (editorRef.current = editor)}
              apiKey="8aun4gp32xsaajfcsiwh0v25ijdy2dbyruqtxb0c6snaqld4"
              init={{
                file_picker_callback: function (callback, value, meta) {
                  tinyCallback = callback;
                  handleHapidam();
                },
                height: 350,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | bold italic forecolor backcolor image | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | help",
              }}
              value={content[lng]}
              onEditorChange={(value) => handleLngChange(setContent, value)}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card
            title={
              <div className="flex between">
                <span>Publication</span>
                <Button key="save" onClick={handleSave}>
                  Enregistrer
                </Button>
              </div>
            }
          >
            <div className="flex between mb-1">
              <span>En ligne :</span>
              <Switch
                checked={internalArticle?.activeLngs?.includes(lng)}
                onChange={handleIsActive}
              />
            </div>
            <div className="flex between mb-1">
              <span>Promouvoir :</span>
              <Switch
                onChange={(val) => handleChange("promotionDate", val)}
                checked={!!internalArticle?.promotionDate}
              />
            </div>
            <div className="mb-1">
              <CategoriesTreeSelect
                value={internalArticle?.category?.["@id"]}
                onChange={(val) => handleChange("category", val)}
              />
            </div>
            <div className="mb-1">
              <p>Vidéo :</p>
              <Input
                value={videos[lng]}
                onChange={(e) => handleLngChange(setVideos, e.target.value)}
              />
            </div>
            {!!internalArticle?.id && (
              <div className="mb-1">
                <p>Auteur :</p>
                <FormSelect
                  style={{ width: "100%" }}
                  name="author"
                  options_endpoint={"users"}
                  value={internalArticle?.author}
                  option_formater={(item) => {
                    return { value: item["@id"], label: item.username };
                  }}
                  onChange={(val) => handleChange("author", val.author)}
                />
              </div>
            )}
            {article?.id !== "new" && (
              <div className="mb-1">
                <p>
                  <Tooltip title="Les actions sur les tags sont enregistrées automatiquement">
                    <FaIcon icon={faQuestionCircle} />
                  </Tooltip>
                  &nbsp; Tags :
                </p>
                <ApiCollectionTags
                  fetch_endpoint={`articles/${article.id}/support_tags`}
                  tag_enpoint="support_tags"
                  customSearch={{
                    lng: lng,
                    product: params.product.split("-").shift(),
                  }}
                  baseData={{
                    lng: lng,
                    product:
                      "/api/support_products/" +
                      params.product.split("-").shift(),
                  }}
                  delete_endpoint="support_tags"
                  relation="articles"
                  item={article}
                />
              </div>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
}

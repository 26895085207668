import React, {useState} from 'react';
import {Button, Collapse, message, Modal, Progress, Upload} from 'antd';
import Papa from 'papaparse';

import {useApiContext} from '../../providers/ApiProvider.js';

let handleLimit = 100;

const CsvImport = ({modal, onClose, visible}) => {

  onClose = onClose || function () {
  };

  const [loading, setLoading] = useState(false);

  const [apiState, apiDispatch] = useApiContext();
  const {apiGooglePlaceAutocomplete, apiGooglePlaceDetail, apiCreateEntity} = apiDispatch;
  const [totalLines, setTotalLines] = useState(0)
  const [done, setDone] = useState(0)
  const [succeded, setSucceded] = useState(0)
  const [failed, setFailed] = useState([])

  const handleFile = (file) => {
    console.log(file)
    Papa.parse(file, {
      complete: function (results) {
        console.log("Finished:", results);
        handleFileContent(results.data)
      }
    })
  }

  const handleFileContent = async (fileContent) => {
    console.log(fileContent);

    if (!fileContent.length) {
      message.error('Il semblerait que ce fichier ne contienne aucune ligne')
      setLoading(false);
    }
    setTotalLines(fileContent.length)

    //Limite le nombre de lignes traitées en même temps pour éviter la surcharge de requete pour les fichiers volumineux
    let rounds = Math.ceil(fileContent.length / handleLimit)
    for (let i = 0; i < rounds; i++ ) {
      let lines = fileContent.slice((i*handleLimit), (handleLimit * (i+1)))
      console.log(lines)
      await handleLines(lines)
    }

    setLoading(false)
  }

  const handleLines = async (linesArr) => {
    await Promise.all(
      linesArr.map(async line => {
        const result = await handleLine(line)
        handleLineResult(result)
      })
    )
  }

  const handleLine = async (line) => {
    let [name, city, website] = line
    name = name.trim()
    if (name.length === 0 || name === 'name') {
      console.log({success: false, name: null})
      return {success: false, name: null}
    }
    let url = parseUrl(website);

    let place = {name: name};
    if (url) {
      //recherche gplace
      place = await findPlace(name, url.toLowerCase(), city.toLowerCase())

      if (Object.keys(place).length === 1) {
        place.city = city.length > 0 ? city : '';
        place.website = website
      }
      return await createPlace(place)

    }
    return {success: false, name: name, error: 'Website non valide'}


  }

  const handleLineResult = (result) => {
    setDone(previousState => previousState + 1)
    if (result.success) {
      setSucceded(previousState => previousState + 1)
    } else {
      setFailed(previousState => [...previousState, result])
    }

  }

  async function findPlace(name, url, city) {
    let place = {name: name};
    const googleResponse = await apiGooglePlaceAutocomplete(name)
    if (googleResponse?.status === 'OK' && googleResponse.predictions?.length > 0) {

      let possibleMatch = googleResponse.predictions.filter(prediction => prediction.types.length > 0 && prediction.types?.includes("lodging"))

      for (let i = 0; i < possibleMatch.length; i++) {

        if (city && !possibleMatch[i].description.toLowerCase().includes(city)) {
          continue;
        }
        let googlePlace = await apiGooglePlaceDetail(possibleMatch[i].place_id)

        if (googlePlace?.status === 'OK' && googlePlace.result.website?.includes(url)) {
          console.log('its a match ', googlePlace)
          //create account with place
          place = handleGPlaceResult(googlePlace.result, possibleMatch[i].place_id)
          break;
        }
      }
    }
    return place;
  }

  const handleGPlaceResult = (result, gID) => {
    return {
      gPlaceId: gID,
      name: result.name ? result.name : '',
      gps: result.gps ? result.gps : null,
      phone: result.phone ? result.phone : '',
      address: result.address ? result.address : null,
      city: result.city ? result.city : null,
      zip: result.zip ? result.zip : null,
      country: result.country ? result.country : null,
      countryCode: result.countryCode ? result.countryCode : null,
      openingHours: result.openingHours ? result.openingHours : null,
      rating: result.rating ? result.rating : null,
      userRatingsTotal: result.userRatingsTotal ? result.userRatingsTotal : null,
      website: result.website ? result.website : null,
      types: result.types ? result.types : null,
      lat: result.gps ? result.gps[0] : null,
      lng: result.gps ? result.gps[1] : null,
    }
  }

  const createPlace = async (item) => {

    const create_response = await apiCreateEntity('mmc_places', item)
    if (create_response['@type'] === "hydra:Error") {
      // let errorMsg = create_response['hydra:description']
      // message.error(create_response['hydra:description'] + ' // ' + item.name)
      return {success: false, name: item.name, error: create_response['hydra:description']}
    } else {
      return {success: true, name: item.name}
    }
  }

  const parseUrl = (url) => {
    if (url.length > 0) {
      url = url.replace('"', '').replace(/^(https?:\/\/)?(www\.)?/, '')
      let match = url.match(/^.*?\.[a-z]{2,3}/);
      if (match) {
        return match[0];
      }
    }
    return false
  }

  const beforeUpload = async (file) => {
    // console.log(file)
    // readFile(file)
    const mimeTypeCheck = file.type === 'application/vnd.ms-excel';
    let reg = new RegExp(/\.csv$/);
    const extensionCheck = reg.test(file.name)
    if (!mimeTypeCheck && !extensionCheck) {
      message.error('You can only upload CSV file!');
    }

    if (mimeTypeCheck && extensionCheck) {
      setLoading(true);
      handleFile(file)
    }
    return false;
  }

  const resetStats = () => {
    setDone(0)
    setFailed([])
    setSucceded(0)
    setTotalLines(0)
  }

  const resultDisplay = (
    <div>
      <p>Nombre de ligne dans le csv : {totalLines}</p>
      <p>Nombre d'hotel crée : {succeded}</p>
      {
        failed.length ?
          <Collapse>
            <Collapse.Panel key={'failed'} header={'Erreur : ' + failed.length}>
              <div style={{maxHeigth: '150px', overflow: 'auto'}}>
                <ul>
                  {
                    failed.map((hotel, index) => <li
                      key={'item_' + index}>{hotel.name ? hotel.name : 'ligne sans contenu'} : {hotel.error}</li>)
                  }
                </ul>
              </div>
            </Collapse.Panel>
          </Collapse>
          : null
      }

    </div>
  )
  const uploadContent = (
    <>
      {
        !loading ?
          <Upload
            name="file"
            showUploadList={false}
            beforeUpload={beforeUpload}
          >
            <Button onClick={resetStats} style={{marginBottom: '1em'}} type="primary" loading={loading}>Importer un
              fichier</Button>
          </Upload>
          :
          <>
            <Progress type="circle" percent={Math.round(done * 100 / totalLines)} status="active"/>
            <div style={{display: 'inline-block', marginLeft: '1em'}}>
              <p>Import en Cours</p>
              <p>{done} / {totalLines} terminés</p>
            </div>
          </>
      }
      {!loading && done ?
        resultDisplay : null
      }
    </>
  );


  if (modal) {
    return <Modal
      title="Importer un fichier csv"
      visible={visible}
      onCancel={onClose}
      footer={null}
    >
      {uploadContent}
    </Modal>
  } else {
    return uploadContent;
  }

}

export default CsvImport;

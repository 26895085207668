import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Result, Button} from 'antd'

const ErrorPlaceHolder = ({code, titre, message, page, action}) => {

  const [status, setStatus] = useState("404");
  const [title, setTitle] = useState("404");
  const [subtitle, setSubtitle] = useState('La page demandée n\'existe pas');
  const [redirection, setRedirection] = useState(null);
  useEffect(() => {
    if (code) {
      setStatus(code);
      setTitle(code);
    }
    if (titre)
      setTitle(titre);
    if (message)
      setSubtitle(message);
    if (page)
      setRedirection(page);


  }, []);
  return (
    <Result
      status={status}
      title={title}
      subTitle={subtitle}
      extra={redirection !== null ? <Link key="title" to={redirection}><Button type="primary">Retour</Button></Link> :
        <Button type="primary" onClick={action}>Retouner a l'accueil</Button>}
    />
  );


};

export default ErrorPlaceHolder;

import React, { useEffect, useState } from 'react';
import { Modal, List, message, Spin } from 'antd';
import { useApiContext } from '../../../providers/ApiProvider';
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import FaIcon from '../../FaIcon';

export default function OrderModal({ endpoint, category, visible, handleClose }) {


  const [apiState, apiDispatch] = useApiContext();
  const { apiFetchCollection, apiPostRequest } = apiDispatch;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch()
  }, [category])

  const fetch = async (params) => {
    setLoading(true)
    let hydra_response = await apiFetchCollection(endpoint, { search: { 'category.id': category, 'order[ranking]': 'asc' } });

    if (hydra_response) {
      setError(false);
      setData(hydra_response['hydra:member'])

    } else {
      setError(true);
    }
    setLoading(false);
  }

  const updateIndex = (item, direction) => {
    const oldIndex = data.indexOf(item)
    if ((oldIndex === 0 && direction === 'up') || (oldIndex === data.length - 1) && direction === 'down') return
    
    setData(prev => {
      const newIndex = direction === 'up' ? oldIndex - 1 : oldIndex + 1
      let newOrder = [...prev]
      newOrder.splice(oldIndex, 1)
      newOrder.splice(newIndex, 0, item)
      return newOrder
    })
  }

  const saveOrder = async () => {
    let newOrder = data.map((item, index) => {
      return { id: item.id, ranking: index }
    })
    let params = {
      entity: 'article',
      data: newOrder
    }
    let response = await apiPostRequest('support-update-ranking', params)

    if (response.success) {
      message.success('Ordre mis à jour avec succes')
      handleClose(true)
    } else {
      message.error('Il y a eu une erreur')
    }
  }


  return (
    <Modal visible={visible} title="Modifier l'ordre" onOk={saveOrder} okText="Enregistrer" cancelText='Fermer' onCancel={handleClose}>
      <Spin spinning={loading}>
        <List
          size="small"
          dataSource={data}
          renderItem={(item, index) =>
            <List.Item>
              <div>
                <FaIcon icon={faCaretDown} onClick={() => updateIndex(item, 'down')} style={{ marginRight: '.5em', opacity: index !== data.length - 1 ? 1 : 0 }} />
                <FaIcon icon={faCaretUp} onClick={() => updateIndex(item, 'up')} style={{ marginRight: '.5em', opacity: index > 0 ? 1 : 0 }} />
                <span>{item.titleFr}</span>
              </div>
            </List.Item>
          }
        />
      </Spin>
    </Modal>
  );
}

// Called in ContractDrawer
import React, { useState, useEffect } from 'react';
import { Modal, Form, Button } from 'antd'

import FaIcon from '../../FaIcon.js';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner'

import { useApiContext } from '../../../providers/ApiProvider.js';
import FormInput from '../../Form/FormInput.js'
import { resolveProperty, slugify } from '../helpers';
import LngSelect from './LngSelect';
import CategoriesTreeSelect from './CategoriesTreeSelect';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

const CategoryForm = ({ item, item_id, onClose, onChange, modal, productIri }) => {

    item_id = item ? item.id : item_id;
    onClose = onClose || function () { };
    onChange = onChange || function () { };
    const [apiState, apiDispatch] = useApiContext();
    const { apiFetchEntity, apiUpdateEntity, apiCreateEntity, apiPostRequest } = apiDispatch;

    const [_itemId, setItemId] = useState(item_id)
    const [_item, setItem] = useState(item ? { ...item } : null)
    const [image, setImage] = useState(item ? item?.image : null)
    const [loading, setLoading] = useState(true)
    const [saving, setSaving] = useState(false)
    const [hapidamClient, setHapidamClient] = useState(null);
    const [isHapidamListener, setIsHapidamListener] = useState(false)
    const [lng, setLng] = useState('fr')

    useEffect(() => {
        // fetch at startup and whenever needUpdate is changed
        if (_itemId && !_item)
            fetchItem();
        else
            setLoading(false);
    }, [])


    useEffect(() => {

        if (!hapidamClient) {
            setHapidamClient(new Hapidam())
        } else {
            if (typeof localStorage.getItem('hapidamKey') === 'string') {
                localStorage.removeItem('hapidamKey');
            }

            var hapidamKey = JSON.parse(localStorage.getItem('hapidamKey'));
            let now = new Date().getTime()
            let isHapidamKeyWorking = hapidamKey?.time ? hapidamKey.time + 3600000 > now : false

            if (hapidamKey?.key && isHapidamKeyWorking) {
                hapidamClient.setToken(hapidamKey.key)
                setLoading(false);
            } else {
                fetchHapidamKey()
            }

        }
    }, [hapidamClient])

    async function fetchHapidamKey() {
        let response = await apiPostRequest('hapidam-connect', {})
        if (response.success) {
            localStorage.setItem('hapidamKey', JSON.stringify({
                time: new Date().getTime(),
                key: response.publicKey
            }))
            hapidamClient.setToken(response.publicKey)
        }

        setLoading(false);
    }

    const handleHapidam = () => {
        if (!isHapidamListener) {
            hapidamClient.on('select', handleResponse);
            setIsHapidamListener(true)
        }
        hapidamClient.open();
    }

    const handleResponse = (response) => {
        hapidamClient.off('select', handleResponse);
        setIsHapidamListener(false)
        hapidamClient.close();

        if (response.display) {
            handlePropertyChange({ image: response.display.native.sm });
            setImage(response.display.native.sm)
        }
    }
console.log('productIri', productIri)
    async function fetchItem() {
        setLoading(true);
        if (_itemId === 'new') {
            setItem({product : productIri})
        }
        else {
            const response = await apiFetchEntity('article_categories', _itemId);
            setItem({ ...response })
            setImage(response?.image)
        }
        setLoading(false);
        return;
    }

    async function handlePropertyChange(chunk) { // {property:value}
        setItem(Object.assign(_item, chunk));
    }

    const handleCancel = () => {
        onClose();
    }

    const handleSave = async () => {
        setSaving(true);
        console.log(_item)
        _item.name = typeof _item.name === "string" ? _item.name : '';

        if (_itemId === 'new') {
            console.log('item', _item)
            const response = await apiCreateEntity('article_categories', _item);
            if (response && response.id) {
                setItemId({ ...response.id })
                setItem({ ...response })
            }
        }
        else {
            let response = await apiUpdateEntity('article_categories', _itemId, _item);
            console.log('r', response)
        }
        onChange(Object.assign({}, _item));
        setSaving(false);
        onClose();

    }
    console.log(_item)
    const formContent = (
        !_item || loading || saving
            ? <React.Fragment><FaIcon icon={faSpinner} spin /> traitement en cours...</React.Fragment>
            : <Form layout="vertical">
                <Form.Item>
                    <LngSelect lng={lng} onChange={setLng} />
                </Form.Item>
                <Form.Item label="Image">
                    <div className={`upload-image ${_item.image && 'has-image'}`} onClick={handleHapidam}>
                        <div className='selected-image' style={{ backgroundImage: _item?.image ? `url('${_item.image}')` : '' }}></div>
                        <div className='content'>
                            <FaIcon icon={faPlus} />
                            <div className="ant-upload-text">Upload</div>
                        </div>
                    </div>
                </Form.Item>

                <Form.Item label="Nom">
                    <FormInput placeholder="" name={resolveProperty('name', lng)} value={_item[resolveProperty('name', lng)]} onChange={handlePropertyChange} contentVersion={lng} />
                </Form.Item>

                <Form.Item >
                    <CategoriesTreeSelect value={_item?.parent?.['@id']} onChange={val => handlePropertyChange({ parent: val })} />
                </Form.Item>

                <Form.Item>
                    <Button key="cancel" onClick={handleCancel}>
                        Close
                    </Button>
                    <Button style={{ marginLeft: 8 }} key="saveAndClose" type="primary" onClick={handleSave}>
                        Save
                    </Button>
                </Form.Item>

            </Form>
    );

    if (modal) {
        return <Modal
            title={_item && _item.name ? _item.nameFr : "Ajouter une catégorie"}
            visible={true}
            onCancel={handleCancel}
            footer={null}
        >
            {formContent}
        </Modal>
    }
    else {
        return formContent;
    }
};

export default CategoryForm;

import React, {useState, useEffect} from 'react';
import {Row, Col, Avatar, Card, Collapse, Skeleton, Modal, Tag, Form, Button, AutoComplete, Input} from 'antd'

import FaIcon from '../FaIcon.js';
import {faSpinner} from '@fortawesome/pro-solid-svg-icons/faSpinner'

import {useApiContext} from '../../providers/ApiProvider.js';

import FormInput from '../Form/FormInput.js'
import {endpointResolver} from '../Contact/_helpers.js'
import FormSwitch from "../Form/FormSwitch";
import moment from "moment";
import FormSelect from "../Form/FormSelect";

const CommentForm = ({item, item_id, entity_id, endpoint, onClose, onChange, modal, ...rest}) => {

  entity_id = entity_id || false;
  item_id = item ? item.id : item_id;
  onClose = onClose || function () {
  };
  onChange = onChange || function () {
  };

  const [apiState, apiDispatch] = useApiContext();
  const {apiFetchEntity, apiUpdateEntity, apiCreateEntity} = apiDispatch;

  const [_itemId, setItemId] = useState(item_id)
  const [_item, setItem] = useState(item ? {...item} : null)
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [relation, setRelation] = useState(endpointResolver(endpoint))

  useEffect(() => {
    // fetch at startup and whenever needUpdate is changed
    if (_itemId && !_item)
      fetchItem();
    else
      setLoading(false);
  }, [])

  async function fetchItem() {
    setLoading(true);
    if (_itemId === 'new') {
      if (entity_id)
        setItem({
          [relation]: {
            '@id': '/api/' + endpoint + '/' + entity_id,
            'id': entity_id
          }
        })
      else
        setItem({})
    } else {
      const response = await apiFetchEntity('mmc_contacts', _itemId);
      setItem({...response})
    }
    setLoading(false);
    return;
  }

  async function handlePropertyChange(chunk) { // {property:value}
    console.log(chunk);
    setItem(Object.assign(_item, chunk));
  }

  const handleCancel = () => {
    onClose();
  }

  const handleSave = async () => {
    setSaving(true);
    console.log(_item)
    if (_itemId === 'new') {
      _item.text = typeof _item.text === "string" ? _item.text : '';
      _item.alertDate = _item.alert ? moment(new Date()).format('') : null
      _item.targetUsers = typeof _item.targetUsers === "object" && _item.targetUsers.length > 0 ? _item.targetUsers : [];

      console.log(_item)
      const response = await apiCreateEntity('comments', _item);
      if (response && response.id) {
        setItemId({...response.id})
        setItem({...response})
      }
    } else {
      await apiUpdateEntity('comments', _itemId, _item);
    }
    setSaving(false);
    onChange(Object.assign({}, _item));
  }

  const handleSaveAndClose = async () => {
    await handleSave();
    onClose();
  }


  const formContent = (
    !_item || loading || saving
      ? <React.Fragment><FaIcon icon={faSpinner} spin/> traitement en cours...</React.Fragment>
      : <Form layout="vertical">
        <Form.Item label="Créer une alerte ?">
          <FormSwitch size="small" name="alert" value={false} onChange={handlePropertyChange}/>
        </Form.Item>
        <Form.Item>
          <FormInput autoFocus placeholder="Type your comment here" textarea={true} rows={8} name="text"
                     value={_item.text} onChange={handlePropertyChange}/>
        </Form.Item>
        <Form.Item label="Personnes">
          <FormSelect allowClear
                      name="targetUsers"
                      value={_item.targetUsers}
                      option_formater={(item) => {
                        return {value: item['@id'], label: item.username};
                      }}
                      onChange={handlePropertyChange}
                      mode="multiple"
                      options_endpoint={"users"}
                      search_property="name"/>
        </Form.Item>

        <Form.Item>
          <Button key="cancel" onClick={handleCancel}>
            Annuler
          </Button>
          {/*<Button style={{ marginLeft: 8 }} key="save" type="primary" onClick={handleSave}>*/}
          {/*  Poster*/}
          {/*</Button>*/}
          <Button style={{marginLeft: 8}} key="saveAndClose" type="primary" onClick={handleSaveAndClose}>
            Poster
          </Button>
        </Form.Item>

      </Form>
  );

  if (modal) {
    return <Modal
      title={_itemId !== "new" ? _item.firstname + ' ' + _item.lastname : "Créer un nouveau contact"}
      visible={true}
      onCancel={handleCancel}
      footer={null}
    >
      {formContent}
    </Modal>
  } else {
    return formContent;
  }
};

export default CommentForm;

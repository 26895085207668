import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom'
import { Breadcrumb, Card } from 'antd'

import FaIcon from '../components/FaIcon.js';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner'
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle'

import { useApiContext } from '../providers/ApiProvider.js';

import ContractList from '../components/Contract/ContractList.js';

const Place = (props) => {

  const [apiState, apiDispatch] = useApiContext();
  const { apiFetchEntity } = apiDispatch;

  const [place, setPlace ] = useState(null)
  const [loading, setLoading ] = useState(true)

  const params = useParams();

  useEffect(() => {
    // fetch at startup and whenever needUpdate is changed
    fetchData();
  },[])

  async function fetchData() {
    const account_response = await apiFetchEntity('mmc_places',params.place_id);
    setPlace(account_response)
    setLoading(false);
  }

  if(loading)
    return <FaIcon icon={faSpinner} spin />

  if(place['@id'])
  {
    let cardTitle = (
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/clusters/">Groupes</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={"/place/"+place.id}>{place.name}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={"/place/"+place.id+"/contracts"}>Contracts</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
    );

    return (
      <div>
        <Card title={cardTitle}>
          <ContractList scroll_x={1000} place_id={place.id} />
        </Card>
      </div>
    );
  }
  return <FaIcon icon={faExclamationTriangle} />
};

export default Place;

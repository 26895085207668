import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, Row, Col } from 'antd'

import FaIcon from '../FaIcon.js';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner'

import { useApiContext } from '../../providers/ApiProvider.js';

import FormCountry from '../Form/FormCountry.js'
import FormInput from '../Form/FormInput.js'
import FormSelect from '../Form/FormSelect.js'
import AvatarUploader from '../Form/AvatarUploader.js'

const AccountForm = ({ item, item_id, cluster_id, onClose, onChange, modal, baseAccount = {}, ...rest }) => {

  cluster_id = cluster_id || false;
  item_id = item ? item.id : item_id;
  onClose = onClose || function () { };
  onChange = onChange || function () { };

  const [apiState, apiDispatch] = useApiContext();
  const { apiFetchEntity, apiUpdateEntity, apiCreateEntity } = apiDispatch;

  const [_itemId, setItemId] = useState(item_id)
  const [_item, setItem] = useState(item ? { ...item } : null)
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    // fetch at startup and whenever needUpdate is changed
    if (_itemId && !_item)
      fetchItem();
    else
      setLoading(false);
  }, [])

  async function fetchItem() {
    setLoading(true);
    if (_itemId === 'new') {

      let cluster = cluster_id ? {
        '@id': '/api/mmc_clusters/' + cluster_id,
        'id': cluster_id
      } : null;

      let base = {
        ...baseAccount,
        cluster: cluster,
        lat: baseAccount?.lat || null,
        lng: baseAccount?.lng || null
      }
      console.log(base)
      setItem({ ...base })

    }
    else {
      const response = await apiFetchEntity('mmc_accounts', _itemId);
      setItem({ ...response })
    }
    setLoading(false);
    return;
  }

  async function handlePropertyChange(chunk) { // {property:value}
    //console.log(chunk);
    setItem(Object.assign(_item, chunk));
  }

  async function handleLogoUpdate(media_object) {
    await apiUpdateEntity('mmc_accounts', _itemId, { logo: media_object });
    const newItem = Object.assign(_item, { logo: media_object });
    setItem(newItem);
    onChange(Object.assign({}, newItem));
  }

  const handleCancel = () => {
    onClose();
  }

  const handleSave = async () => {
    setSaving(true);
    if (_itemId === 'new') {
      const response = await apiCreateEntity('mmc_accounts', _item);
      if (response && response.id) {
        setItemId({ ...response.id })
        setItem({ ...response })
      }
    }
    else {
      await apiUpdateEntity('mmc_accounts', _itemId, _item);
    }
    setSaving(false);
    onChange(Object.assign({}, _item));
  }

  const handleSaveAndClose = async () => {
    await handleSave();
    onClose();
  }

  const formContent = (
    !_item || loading || saving
      ? <React.Fragment><FaIcon icon={faSpinner} spin /> traitement en cours...</React.Fragment>
      : <Form layout="vertical">
        <Form.Item label="Logo">
          <AvatarUploader onUpload={handleLogoUpdate} imageUrl={_item.logo ? _item.logo.contentUrl : null} />
        </Form.Item>
        {
          cluster_id ? null :
            <Form.Item label="Cluster">
              <FormSelect
                name="cluster"
                value={_item.cluster ? _item.cluster.id : null}
                onChange={handlePropertyChange}
                options_endpoint="mmc_clusters"
                search_property="name"
                option_formater={(item) => { return { value: item.id, label: item.name }; }}
              />
            </Form.Item>
        }
        <Form.Item label="Name">
          <FormInput placeholder="Account name" name="name" value={_item.name} onChange={handlePropertyChange} />
        </Form.Item>
        <Form.Item label="Description">
          <FormInput textarea={true} rows={8} placeholder="Account description" name="description" value={_item.description} onChange={handlePropertyChange} />
        </Form.Item>
        <Row gutter={16}>
          <Col span={16}>
            <Form.Item label="Address">
              <FormInput placeholder="" name="address" value={_item.address} onChange={handlePropertyChange} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Zipcode">
              <FormInput placeholder="" name="zip" value={_item.zip} onChange={handlePropertyChange} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="City">
              <FormInput placeholder="" name="city" value={_item.city} onChange={handlePropertyChange} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Country">
              <FormCountry placeholder="" name="countryCode" fullname="country" value={_item.countryCode}
                onChange={handlePropertyChange} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button key="cancel" onClick={handleCancel}>
            Close
          </Button>
          <Button style={{ marginLeft: 8 }} key="save" type="primary" onClick={handleSave}>
            Save
          </Button>
          <Button style={{ marginLeft: 8 }} key="saveAndClose" type="primary" onClick={handleSaveAndClose}>
            Save & Close
          </Button>
        </Form.Item>

      </Form>
  );

  if (modal) {
    return <Modal
      title={_item && _item.name ? _item.name : "..."}
      visible={true}
      onCancel={handleCancel}
      footer={null}
    >
      {formContent}
    </Modal>
  }
  else {
    return formContent;
  }
};

export default AccountForm;

import { Button, Card } from 'antd'
import React from 'react'
import FormSelect from '../Form/FormSelect'

export default function NoContractCondition({ contracts, setContracts }) {

  const handlePropertyChange = (value) => {
    console.log(value.noContracts)
    setContracts(value.noContracts)
  }

  if (!contracts) return null

  return (
    <div style={{margin : "1em 0"}}>
    <h4>Sans des contrats: </h4>
      {
        contracts &&
          <div>
            <FormSelect
              name="noContracts"
              value={contracts}
              style={{ width: "50%" }}
              allowClear
              onChange={handlePropertyChange}
              options_endpoint={'products'}
              option_formater={(item) => {
                return { value: item['id'], label: `${item.name} ( ${item.tag.name} )` };
              }}
              mode="multiple"
              search_property="name"
            //   contentVersion={contentVersion}
            />
          </div>
      }
    </div>
  )
}

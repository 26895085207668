import React from 'react';
import { Link, useLocation } from 'react-router-dom'
import { Menu } from 'antd';

import FaIcon from './FaIcon.js';
import { faHome } from '@fortawesome/pro-solid-svg-icons/faHome'
import { faUserLock } from '@fortawesome/pro-solid-svg-icons/faUserLock'
import { faSignOutAlt } from '@fortawesome/pro-solid-svg-icons/faSignOutAlt'
import { faCog } from "@fortawesome/pro-solid-svg-icons/faCog";
import {faUsers} from "@fortawesome/pro-solid-svg-icons/faUsers";
import {faBuilding} from "@fortawesome/pro-solid-svg-icons/faBuilding";
import { useAuthContext } from '../providers/AuthProvider.js';
import {faProductHunt} from "@fortawesome/free-brands-svg-icons";
import {faBook, faComment, faMailBulk, faMapMarkerAlt, faNotEqual} from "@fortawesome/pro-solid-svg-icons";
import {faHotel} from "@fortawesome/pro-solid-svg-icons/faHotel";
import {faAddressCard} from "@fortawesome/pro-solid-svg-icons/faAddressCard";

const NavPrivate = () => {

  const [authState, authDispatch] = useAuthContext();
  const { auth } = authState;

  let location = useLocation()

  return (
      <Menu
        mode="inline"
        selectedKeys={[location.pathname]}
        >
        <Menu.Item key="/">
          <FaIcon icon={faHome} />
          <span>Home</span>
          <Link to="/"></Link>
        </Menu.Item>
        <Menu.Item key="/clusters">
          <FaIcon icon={faUsers} />
          <span>Groupes</span>
          <Link to="/clusters"></Link>
        </Menu.Item>
        <Menu.Item key="/accounts">
          <FaIcon icon={faBuilding} />
          <span>Sociétés</span>
          <Link to="/accounts"></Link>
        </Menu.Item>
        <Menu.Item key="/places">
          <FaIcon icon={faHotel} />
          <span>Hotels</span>
          <Link to="/places"></Link>
        </Menu.Item>
        <Menu.Item key="/logs">
          <FaIcon icon={faUserLock} />
          <span>Logs</span>
          <Link to="/logs"></Link>
        </Menu.Item>
        <Menu.Item key="/contacts">
          <FaIcon icon={faAddressCard} />
          <span>Contacts</span>
          <Link to="/contacts"></Link>
        </Menu.Item>
        <Menu.Item key="/comments">
          <FaIcon icon={faComment} />
          <span>Commentaires</span>
          <Link to="/comments"></Link>
        </Menu.Item>
        <Menu.Item key="/segments">
          <FaIcon icon={faMailBulk} />
          <span>Segments</span>
          <Link to="/segments"></Link>
        </Menu.Item>
        <Menu.Item key="/documentation">
          <FaIcon icon={faBook} />
          <span>Documentation</span>
          <Link to="/documentation"></Link>
        </Menu.Item>
        <Menu.Item key="/produits">
          <FaIcon icon={faProductHunt} />
          <span>Produits</span>
          <Link to="/products"></Link>
        </Menu.Item>
        <Menu.Item key="/geoloc">
          <FaIcon icon={faMapMarkerAlt} />
          <span>Géolocaliser</span>
          <Link to="/geoloc"></Link>
        </Menu.Item>
        <Menu.Item key="/compare">
          <FaIcon icon={faNotEqual} />
          <span>Dédoubler</span>
          <Link to="/compare"></Link>
        </Menu.Item>
        <Menu.Item key="/users">
          <FaIcon icon={faUsers} />
          <span>Utilisateurs</span>
          <Link to="/users"></Link>
        </Menu.Item>
        <Menu.Item key="/settings">
          <FaIcon icon={faCog} />
          <span>Settings</span>
          <Link to="/settings"></Link>
        </Menu.Item>
        <Menu.Item key="/logout">
          <FaIcon icon={faSignOutAlt} />
          <span>Logout</span>
          <Link to="/logout"></Link>
        </Menu.Item>
      </Menu>
  );
};

export default NavPrivate;

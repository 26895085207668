import React from 'react';
import {Breadcrumb} from "antd";
import {Link, useParams} from "react-router-dom";
import {getLanguage} from "../helpers";

const SupportBreadcrumb = ({breadcrumb}) => {

  const params = useParams();
  const lng = getLanguage(params.lng)

  return (
    breadcrumb.length > 1 &&
    <div className="breadcrumb-container">
      <Breadcrumb>

        {breadcrumb.map((item, index) => (
          <Breadcrumb.Item key={index}>
            {index === breadcrumb.length - 1 ? item.name :
              <Link to={`/${lng}/support/${params.product}${index > 0 ? `/category/${item.id}` : ''}`}>{item.name}</Link>}
          </Breadcrumb.Item>
        ))}

      </Breadcrumb>
    </div>
  );
};

export default SupportBreadcrumb;

import React, { useState } from 'react';
import { Button } from 'antd';

import ApiCollectionTable from '../ApiCollectionTable.js';

import FaIcon from '../FaIcon.js';

import ContactForm from './ContactForm.js';
import ContactDrawer from './ContactDrawer.js';
import { faBuilding, faHotel, faUsers } from '@fortawesome/pro-solid-svg-icons';


const ContactList = ({scroll_x, id, endpoint, ...rest}) => {

  const [selectedItem, setSelectedItem ] = useState(null)
  const [creationMode, setCreationMode ] = useState(false)
  const [total, setTotal] = useState(0);

  const [contentVersion, setContentVersion ] = useState(0)

  const handleCreationFormClose = () => {
    setCreationMode(false);
  }
  const handleCreationFormChange = () => {
  }

  const handleEditFormClose = () => {
    setSelectedItem(null);
  }
  const handleEditFormChange = () => {
    setContentVersion((new Date()).getTime());
  }

  const columns = [
    {
      title: 'Prénom',
      dataIndex: 'firstname',
      sorter: true,
      render: (firstname,record) => firstname,
    },
    {
      title: 'Nom',
      dataIndex: 'lastname',
      sorter: true,
      render: (lastname,record) => lastname,
    },
    {
      title: 'Téléphone',
      dataIndex: 'phone',
      render: (phone,record) => phone,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: true,
      render: (email,record) => email,
    },
    {
      title: 'Structure',
      dataIndex: 'account',
      render: (account,record) =>  <React.Fragment>
      {!!account && <React.Fragment><FaIcon icon={faBuilding}/> {account.name}<br/></React.Fragment>}
      {!!record.cluster && <React.Fragment><FaIcon icon={faUsers}/> {record.cluster.name}<br/></React.Fragment>}
      {!!record.place && <React.Fragment><FaIcon icon={faHotel}/> {record.place.name}<br/></React.Fragment>}
    </React.Fragment>,
    },
    {
      title: 'Rôle',
      dataIndex: 'contactRole',
      render: (contactRole,record) =>
        <span>{contactRole?.name} {record.description}</span>,
    },
  ];

  if(creationMode)
    return <ContactForm modal item_id="new" entity_id={id} endpoint={endpoint} onClose={handleCreationFormClose} onChange={handleCreationFormChange}/>;


  return (
    <React.Fragment>
      <ApiCollectionTable
        title={(currentPageData) => <React.Fragment><b>{total} Contacts</b> <Button style={{float:"right"}} type="primary" size="small" onClick={()=>{setCreationMode(true)}}>+</Button></React.Fragment>}
        fetch_endpoint={id && endpoint ? `${endpoint}/${id}/contacts`:"mmc_contacts"}
        delete_endpoint="mmc_contacts"
        columns={columns}
        scroll_x={scroll_x}
        onClick={(item) => setSelectedItem({...item}) }
        contentVersion={contentVersion}
        setTotal={setTotal}
        {...rest}
      />
      {selectedItem ? <ContactDrawer item={selectedItem} onClose={handleEditFormClose} onChange={handleEditFormChange} /> : null}
    </React.Fragment>
  );
};

export default ContactList;

import React, { useState } from 'react';
import { Button } from 'antd';


import ApiCollectionTable from '../ApiCollectionTable.js';

import ProductForm from "./ProductForm";
import ProductDrawer from "./ProductDrawer";

import {externalFormatter} from "./_helpers";

const ProductList = ({scroll_x, ...rest}) => {

  const [selectedItem, setSelectedItem ] = useState(null)
  const [creationMode, setCreationMode ] = useState(false)

  const [contentVersion, setContentVersion ] = useState(0)

  const handleCreationFormClose = () => {
    setCreationMode(false);
  }
  const handleCreationFormChange = () => {
  }

  const handleEditFormClose = () => {
    setSelectedItem(null);
  }
  const handleEditFormChange = () => {
    setContentVersion((new Date()).getTime());
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      search: true,
      sorter: true,
      render: (name,record) => <span> {name} </span>,
    },
    {
      title: 'Catégorie',
      dataIndex: 'tag.name',
      search: true,
      sorter: true,
      render: (tag,record) => record.tag.name,
    },
    {
      title: 'External',
      dataIndex: 'external',
      search: true,
      sorter: true,
      render: (external,record) => externalFormatter(external, 'display'),
    },
    {
      title: 'Endpoint',
      dataIndex: 'endpoint',
      sorter: true,
      render: (endpoint,record) => <span> {endpoint} </span>,
    },
    {
      title: 'Clé api',
      dataIndex: 'apiKey',
      sorter: true,
      render: (apiKey,record) => <span> {apiKey} </span>,
    },
    {
      title: 'Detection',
      dataIndex: 'detections',
      sorter: true,
      render: (detections,record) => <span>{detections.map(item => item+', ')}</span>,
    },
  ];

  if(creationMode)
    return <ProductForm modal item_id="new" onClose={handleCreationFormClose} onChange={handleCreationFormChange}/>;

  return (
    <React.Fragment>
      <ApiCollectionTable
        title={(currentPageData) => <React.Fragment><b>Product list</b> <Button style={{float:"right"}} type="primary" size="small" onClick={()=>{setCreationMode(true)}}>+</Button></React.Fragment>}
        fetch_endpoint="products"
        delete_endpoint="products"
        columns={columns}
        scroll_x={scroll_x}
        onClick={(item) => setSelectedItem({...item})}
        contentVersion={contentVersion}
        {...rest}
      />
      {selectedItem ? <ProductDrawer item={selectedItem} onClose={handleEditFormClose} onChange={handleEditFormChange} /> : null}
    </React.Fragment>
  );
};

export default ProductList;

import React, { useEffect, useState } from "react";
import { Breadcrumb, Card, Spin } from "antd";
import { Link, useParams } from "react-router-dom";
import SegmentForm from "../components/Segments/SegmentForm";
import { useApiContext } from '../providers/ApiProvider.js';

const Segment = () => {

    const [apiState, apiDispatch] = useApiContext();
    const { apiFetchEntity } = apiDispatch;
    const [segment, setSegment] = useState({})
    const [loading, setLoading] = useState(false)

    const params = useParams();
    useEffect(() => {
        // fetch at startup and whenever needUpdate is changed
        if (params.id !== "new") {
            fetchData();
        }
    }, [])


    async function fetchData() {
        setLoading(true);
        console.log('fetching data')
        const segment_response = await apiFetchEntity('segments', params.id);
        if (segment_response['@type'] === "hydra:Error") {
            setRedirection(true)
        }
        setSegment(segment_response)
        setLoading(false);
    }

    let cardTitle = (
        <Breadcrumb>
            <Breadcrumb.Item>
                <Link to="/">Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                <Link to="/segments/">Segments</Link>
            </Breadcrumb.Item>
        </Breadcrumb>
    );

    return (
        <Card title={cardTitle}>
            <Spin spinning={loading}>
                <SegmentForm segment={segment} setSegment={setSegment} setLoading={setLoading} />
            </Spin>
        </Card>
    );
}

export default Segment

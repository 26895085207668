import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import { Tag, Button } from 'antd';

import moment from 'moment';

import ApiCollectionTable from '../ApiCollectionTable.js';
import {externalFormatter} from "../Product/_helpers";

const LogList = ({scroll_x, place_id, customFilters, ...rest}) => {

  const [contentVersion, setContentVersion ] = useState(0)
  const [total, setTotal] =useState(0);

  const handleCreationFormClose = () => {
    setCreationMode(false);
  }
  const handleCreationFormChange = () => {
  }

  const handleEditFormClose = () => {
    setSelectedItem(null);
  }
  const handleEditFormChange = () => {
    setContentVersion((new Date()).getTime());
  }

  const columns = [
    {
      title: 'Hotel',
      dataIndex: 'place.name',
      sorter: true,
      render: (name,record) => <Link to={`/place/${record.place.id}`}>
        {name}
      </Link>,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      sorter: true,
      render: (action,record) => action,
    },
    {
      title: 'Product',
      dataIndex: 'contract.product.name',
      sorter: true,
      render: (name,record) => name,
    },
    {
      title: 'External',
      dataIndex: 'contract.product.external',
      sorter: true,
      render: (external,record) => <>{externalFormatter(external, 'display')}</>,
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      sorter: true,
      render: (createdAt,record) => <>{moment(createdAt).format('HH:mm DD-MM-YYYY')}</>,
    },
  ];

  return (
    <React.Fragment>
      <ApiCollectionTable
        title={(currentPageData) => <React.Fragment><b>{total} log</b> </React.Fragment>}
        fetch_endpoint={place_id ? `mmc_places/${place_id}/logs`:"logs"}
        delete_endpoint="logs"
        columns={columns}
        scroll_x={scroll_x}
        // onClick={(item) => window.location.href = '/account/'+item.account.id }
        contentVersion={contentVersion}
        customFilters={customFilters}
        setTotal={setTotal}
        allowSelections={true}
        {...rest}
      />
    </React.Fragment>
  );
};

export default LogList;

import React, { useState } from 'react';
import { Input } from 'antd'

import FaIcon from '../FaIcon.js';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons/faExclamationCircle'
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck'

const FormGps = ({onChange, name, value, ...rest}) => {

  const originalValue = value && value.length==2 ? [parseFloat(value[0]),parseFloat(value[1])] : [0,0];

  const [internalValue, setInternalValue ] = useState(originalValue)
  const [saveNeeded0, setSaveNeeded0 ] = useState(false)
  const [saveNeeded1, setSaveNeeded1 ] = useState(false)

  const handleInputChange = (k,event) => {
    let chunk = {};
    let newValue = [internalValue[0],internalValue[1]];
    newValue[k] = parseFloat(event.target.value)
    chunk[name] = newValue;
    onChange(chunk);

    setInternalValue(newValue);
    setSaveNeeded0(newValue[0]!==originalValue[0]);
    setSaveNeeded1(newValue[1]!==originalValue[1]);
  }

  //console.log(originalValue,internalValue)

  const suffix0 = internalValue[0]===0 || isNaN(internalValue[0]) ? <FaIcon icon={faExclamationCircle} style={{color:"#ff9800"}} /> : (saveNeeded0 ? <FaIcon icon={faExclamationCircle} style={{color:"#dd5246"}} /> : <FaIcon icon={faCheck} />);
  const suffix1 = internalValue[1]===0 || isNaN(internalValue[1]) ? <FaIcon icon={faExclamationCircle} style={{color:"#ff9800"}} /> : (saveNeeded1 ? <FaIcon icon={faExclamationCircle} style={{color:"#dd5246"}} /> : <FaIcon icon={faCheck} />);

  return (
    <Input.Group className="formInput" style={{ display: 'flex', flexDirection:'row' }}>
      <Input suffix={suffix0} style={{flex:1}} {...rest} defaultValue={internalValue[0]} onChange={handleInputChange.bind(null,0)} onPressEnter={handleInputChange.bind(null,0)}/>
      <Input suffix={suffix1} style={{flex:1}} {...rest} defaultValue={internalValue[1]} onChange={handleInputChange.bind(null,1)} onPressEnter={handleInputChange.bind(null,1)}/>
    </Input.Group>
  );
};

export default FormGps;

import React from 'react';
import { Select } from 'antd';

export default function LngSelect({ lng, onChange }) {
    return (
        <Select value={lng} onChange={onChange} style={{ width: 100 }}>
            <Select.Option value='fr'>Francais</Select.Option>
            <Select.Option value='en'>Anglais</Select.Option>
        </Select>
    );
}

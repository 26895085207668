import React, { useEffect, useState } from 'react';
import { Card, message } from 'antd';
import { useApiContext } from '../../../providers/ApiProvider';
import FaIcon from '../../FaIcon';
import { faBan, faPlus, faSort } from '@fortawesome/pro-solid-svg-icons';
import CategoryForm from './CategoryForm';
import CategoryItem from './CategoryItem';
import ModalRankingCategory from './ModalRankingCategory';

export default function CategoryMenu({ product, contentVersion, setSelected, selected }) {

    const [apiState, apiDispatch] = useApiContext();
    const { apiFetchCollection, apiDeleteEntity } = apiDispatch;
    const [categories, setCategories] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [needUpdate, setNeedUpdate] = useState(0)
    const [editing, setEditing] = useState(null)
    const [indexing, setIndexing] = useState(null)

    useEffect(() => {
        // fetch at startup and whenever needUpdate is changed
        let params = {
            search: { "exists[parent]": false, 'order[ranking]': 'asc', 'pagination': false}
        };
        fetch(params);
    }, [needUpdate, contentVersion])

    const fetch = async (params) => {
        setLoading(true)
        let hydra_response = await apiFetchCollection(`support_products/${product.id}/categories`, params);
        if (hydra_response["@type"] !== "hydra:Error") {
            setError(false);
            setCategories(hydra_response['hydra:member'])
        }
        else {
            setError(true);
        }
        setLoading(false);
    }

    const handleClose = () => {
        setEditing(null)
        setNeedUpdate((new Date()).getTime())
    }

    const handleCloseOrder = (refresh) => {
        setIndexing(false)
        if (refresh === true) {
            setNeedUpdate((new Date()).getTime())
        }
    }


    const handleDelete = async (id) => {
        setLoading(true)
        let hydra_response = await apiDeleteEntity(`article_categories`, id);
        if (hydra_response["@type"] == "hydra:Error") {
            setError(true);
            message.error('Il y a eu une erreur')
        }
        setNeedUpdate((new Date()).getTime())
        setLoading(false);
    }

    return (
        <>
            <Card loading={loading}
                title={
                    <div className='flex between'>
                        <span>Catégories</span>
                        <div>
                            {
                                selected &&
                                <span style={{ marginRight: '.5em' }}> <FaIcon icon={faBan} onClick={() => setSelected(null)} /></span>
                            }
                            <span style={{ marginRight: '.5em' }}> <FaIcon icon={faSort} onClick={() => setIndexing(true)} /></span>
                            <span ><FaIcon icon={faPlus} onClick={() => setEditing('new')} /></span>
                        </div>
                    </ div>
                }
                bodyStyle={{ padding: '1em 0' }}>
                {
                    categories.map(item =>
                        <CategoryItem item={item} level={0} setSelected={setSelected} selected={selected} setEditing={setEditing} key={item.id} handleDelete={handleDelete} />
                    )
                }
            </Card>
            {
                !!editing &&
                <CategoryForm item_id={editing} modal onClose={handleClose} productIri={product['@id']} />
            }
            <ModalRankingCategory handleClose={handleCloseOrder} visible={indexing} />
        </>
    );
}

import React  from 'react';
import { Link } from 'react-router-dom'
import { Breadcrumb, Card } from 'antd'

import UserList from "../components/User/UserList";

const Users = () => {

  let cardTitle = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Link to="/">Home</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Link to="/users/">Users</Link>
      </Breadcrumb.Item>
    </Breadcrumb>
  );


  return (
    <>
      <Card title={cardTitle}>
        <UserList  item_id="new"/>
      </Card>
    </>
  );
};

export default Users;

import React from 'react';
import { Layout } from 'antd';

import Header from './Header.js';
import Sidebar from './Sidebar.js';

const AppLayout = ({ children, ...rest }) => {

  //console.log(rest.layout)

  return (
    <Layout className="root-layout" style={{ minHeight: '100vh' }}>
      <Sidebar />
      <Layout style={{padding:'1em'}}>
        {children}
      </Layout>
    </Layout>
  );
};

export default AppLayout;

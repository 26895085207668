import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import { Tag, Button } from 'antd';

import moment from 'moment';

import ApiCollectionTable from '../ApiCollectionTable.js';

import ContractForm from './ContractForm.js';
import ContractDrawer from './ContractDrawer.js';

import { activesTagResolver } from './_helpers.js'
import _products, { productResolver, types, externals } from './_products.js'

const ContractList = ({scroll_x, place_id, ...rest}) => {

  const [selectedItem, setSelectedItem ] = useState(null)
  const [creationMode, setCreationMode ] = useState(false)

  const [contentVersion, setContentVersion ] = useState(0)

  const handleCreationFormClose = () => {
    setCreationMode(false);
  }
  const handleCreationFormChange = () => {
  }

  const handleEditFormClose = () => {
    setSelectedItem(null);
  }
  const handleEditFormChange = () => {
    setContentVersion((new Date()).getTime());
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      search: true,
      sorter: true,
      render: (name,record) => <span>
        {activesTagResolver(record,{compact:true})} {name}
        <br />
        <small>{moment(record.subscribedAt, "YYYY-MM-DD").format('DD/MM/YYYY')} - {moment(record.expireAt, "YYYY-MM-DD").format('DD/MM/YYYY')}</small>
      </span>,
    },
    {
      title: 'Domain name',
      dataIndex: 'domainName',
      search: true,
      sorter: true,
      render: (domainName,record) => domainName,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      search: types,
      sorter: true,
      render: (type,record) => productResolver(record).type.label,
    },
    {
      title: 'Connected engine',
      dataIndex: 'connectedEngine', // filtre multiple sur 3 property
      search: {type:'cascader',options:_products},
      sorter: true,
      render: (connectedEngine,record) => productResolver(record).connectedEngine.label,
    },
    {
      title: 'External',
      dataIndex: 'external',
      search: externals,
      sorter: true,
      render: (external,record) => productResolver(record).external.label,
    },
  ];

  if(creationMode)
    return <ContractForm modal item_id="new" place_id={place_id} onClose={handleCreationFormClose} onChange={handleCreationFormChange}/>;

  return (
    <React.Fragment>
      <ApiCollectionTable
        title={(currentPageData) => <React.Fragment><b>Contrats</b> <Button style={{float:"right"}} type="primary" size="small" onClick={()=>{setCreationMode(true)}}>+</Button></React.Fragment>}
        fetch_endpoint={place_id ? `mmc_places/${place_id}/contracts`:"mmc_contracts"}
        delete_endpoint="mmc_contracts"
        columns={columns}
        scroll_x={scroll_x}
        onClick={(item) => setSelectedItem({...item}) }
        contentVersion={contentVersion}
        {...rest}
        />
        {selectedItem ? <ContractDrawer item={selectedItem} onClose={handleEditFormClose} onChange={handleEditFormChange} /> : null}
    </React.Fragment>
  );
};

export default ContractList;

import React from 'react';
import {Link, useParams} from "react-router-dom";
import FaIcon from "../../FaIcon";
import {faQuestionCircle} from "@fortawesome/pro-light-svg-icons/faQuestionCircle";
import {getLanguage, slugify, sortArticles, translate} from "../helpers";

const Categories = ({categories}) => {

  const params = useParams();
  const lng = getLanguage(params.lng)

  const windowUrl = window.location.search;
  const urlParams = new URLSearchParams(windowUrl);
  const isDebug = urlParams.get('debug') === 'true';

  return (
    <div className="categories-container">
      {categories?.sort(sortArticles).map(category => (
        !!(category.articles.some(article => article.activeLngs.includes(lng)) || category.children.length || isDebug) &&
        <div key={category.id} className="category">
          <Link to={`/${lng}/support/${category.product.id}-${slugify(category.product.name)}/category/${category.id}`}>
            <div className="category-content">
              <div className="img-block">
                {category.image ? <img src={category.image}/>
                  : <FaIcon icon={faQuestionCircle}/>}
              </div>
              <div className="text-block">
                <h2>{translate(category, 'name', lng)}</h2>
                <div>
                  <span>{!!category.children?.filter(child => child.children.length || child.articles.some(article => article.activeLngs.includes(lng))).length && `${category.children.filter(child => child.children.length || child.articles.some(article => article.activeLngs.includes(lng))).length} catégorie(s) dans cette collection, `}</span>
                  <span>{category.articles.filter(article => article.activeLngs.includes(lng)).length} article(s) dans cette collection</span>
                </div>
              </div>
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default Categories;

import React, {useState} from 'react';
import {Link} from 'react-router-dom'
import {Button} from 'antd';

import ApiCollectionLatest from '../ApiCollectionLatest.js';
import moment from 'moment'

const LogDash = ({place_id, ...rest}) => {

  const [contentVersion, setContentVersion] = useState(0)


  const columns = [
    {
      title: '',
      dataIndex: 'place.name',
      render: (name, record) => <React.Fragment>
        {name}
      </React.Fragment>
    },
    {
      title: '',
      dataIndex: 'action',
      render: (action, record) => <React.Fragment>
        {action}
      </React.Fragment>
    },
    {
      title: 'Contract',
      dataIndex: 'contract.product.name',
      render: (name,record) => name,
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      render: (createdAt,record) => moment(createdAt).format("DD-MM-YY"),
    },
  ];


  return (
    <React.Fragment>
      <ApiCollectionLatest
        title={(currentPageData) => <React.Fragment>
          <b> Logs</b>
          <Link style={{float: "right"}} to={place_id ? `/place/${place_id}/logs` : "/logs/"}>See all</Link>
        </React.Fragment>}
        fetch_endpoint={place_id ? `mmc_places/${place_id}/logs`:"logs"}
        delete_endpoint="logs"
        columns={columns}
        pageSize={5}
        onClick={(item) => window.location.href = `/place/${item.place.id}`}
        contentVersion={contentVersion}
        customSearch={place_id ? {} : {'contract.product.external': 0, 'action':'missing'}}
        {...rest}
      />
    </React.Fragment>
  );
};

export default LogDash;

export function getLanguage(lng) {
  const acceptedLanguages = ['fr', 'en']
  return acceptedLanguages.includes(lng) ? lng : 'fr';
}

export function translate(object, key, lng) {
  if (object[key + lng.capitalize()])
    return object[key + lng.capitalize()];

  return object[key + 'Fr'];
}

Object.defineProperty(String.prototype, 'capitalize', {
  value: function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false
});

export function resolveProperty(property, lng) {
  return property + lng.capitalize()
}

export function slugify(str) {
  return str.toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');
}

export function sortArticles(a, b) {
  return (b.ranking != null) - (a.ranking != null) || a.ranking - b.ranking;
}

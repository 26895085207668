import React, {useEffect, useState} from "react";
import {useApiContext} from "../providers/ApiProvider";
import {Link, Redirect, useParams} from "react-router-dom";
import FaIcon from "../components/FaIcon";
import {faSpinner} from "@fortawesome/pro-solid-svg-icons/faSpinner";
import {Breadcrumb, Button, Card, message} from "antd";
import {faExclamationTriangle} from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import Catalogue from "../components/Estimate/Catalogue";
import Options from "../components/Estimate/Options";
import Recap from "../components/Estimate/Recap";
import AfterAction from "../components/Estimate/AfterAction";

export default function Estimate() {

  const [apiState, apiDispatch] = useApiContext();
  const {apiFetchEntity, apiPostRequest, apiFetchCollection} = apiDispatch;
  const params = useParams();

  const [redirection, setRedirection] = useState(false);
  const [account, setAccount] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingCreate, setLoadingCreate] = useState(false);

  const [products, setProducts] = useState([]);
  const [data, setData] = useState({});

  const [docId, setDocId] = useState(null);
  const [docLink, setDocLink] = useState(null);
  const [emails, setEmails] = useState([]);

  const [contactProposition, setContactProposition] = useState([]);

  useEffect(() => {
    fetch();
  }, [])

  async function fetch() {
    const account_response = await apiFetchEntity('mmc_accounts', params.account_id);
    if (account_response['@type'] === "hydra:Error" || !account_response.sellsyId)
      setRedirection(true);

    setAccount(account_response);
    setData({thirdid: account_response.sellsyId})
    setLoading(false);
  }

  async function create() {
    if (!products.length)
      return message.error("Selectionner un produit");

    setLoadingCreate(true);

    data.row = [...products];
    data.row.push({row_type: "sum"});

    const response = await apiPostRequest('sellsy/create-estimate', data)

    setDocId(response.response.response.doc_id);

    const docResponse = await apiFetchCollection(`sellsy/get-estimate/${response.response.response.doc_id}`, {noExtension: true})

    if (response.success) {
      message.success("Document créé")
      setDocLink(docResponse.link)
    }

    setLoadingCreate(false);
  }

  function reset() {
    setProducts([]);
    setData({thirdid: account.sellsyId});
    setDocId(null);
    setDocLink(null);
  }

  if (loading)
    return <FaIcon icon={faSpinner} spin/>

  if (redirection)
    return <Redirect to={{
      pathname: "/erreur",
      search: "?utm=your+face",
      state: {status: '404', subtitle: 'Cette société n\'existe pas', page: './accounts'}
    }}/>

  if (account['@id']) {
    const cardTitle = (
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/accounts/">Société</Link>
        </Breadcrumb.Item>
        {
          account.cluster
            ? <Breadcrumb.Item>
              <Link to={`/cluster/${account.cluster.id}`}>{account.cluster.name}</Link>
            </Breadcrumb.Item>
            : null
        }
        <Breadcrumb.Item>
          <Link to={`/account/${account.id}`}>{account.name}</Link>
        </Breadcrumb.Item>

        <Breadcrumb.Item>
          Devis
        </Breadcrumb.Item>
      </Breadcrumb>
    )

    return (
      <div>
        <Card title={cardTitle}>
          <h1 style={{fontSize: 24}}>Faire un Devis</h1>

          <div style={{display: "flex", justifyContent: "space-between", flexWrap: "wrap"}}>
            <Catalogue products={products} setProducts={setProducts}/>
            <Options
              data={data}
              setData={setData}
              sellsyId={account.sellsyId}
              setContactProposition={setContactProposition}
            />
          </div>

          <Recap products={products} setProducts={setProducts}/>

          <div
            style={{marginTop: 50, display: "flex", justifyContent: "space-between"}}
          >
            <Button
              type="primary"
              onClick={create}
              loading={loadingCreate}
            >
              Creer le devis
            </Button>

            <Button
              type="danger"
              style={{marginLeft: 50}}
              onClick={reset}
            >
              Reset
            </Button>
          </div>

          {docId && docLink ?
            <AfterAction
              docLink={docLink}
              docId={docId}
              contactProposition={contactProposition}
              setContactProposition={setContactProposition}
              emails={emails}
              setEmails={setEmails}
            /> : null}
        </Card>
      </div>
    )
  }

  return <FaIcon icon={faExclamationTriangle}/>
}

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Breadcrumb, Card,  } from 'antd'

import PlaceFilters from '../components/Place/PlaceFilters.js';
import PlaceList from '../components/Place/PlaceList.js';
import {checkLocalStorage, saveFilterInStorage} from "../components/_helpers";

const Places = () => {

  const [customSearch, setCustomSearch] = useState(checkLocalStorage('placeFilter', {productSelection: 'ET', withoutTags: 'avec', since: 'less', contractDate:'start'}))
  const [page, setPage] = useState(checkLocalStorage('placePage', 1))

  useEffect(() => {
    if (customSearch) {
      saveFilterInStorage('placeFilter', customSearch)
    }
  }, [customSearch])

  useEffect(()=> {
    saveFilterInStorage('placePage', page)
  }, [page])


  let cardTitle = (
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/places/">Hotels</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
    );


  return (
    <>
      <Card title={cardTitle}>
        <PlaceFilters customSearch={customSearch} setCustomSearch={setCustomSearch} setPage={setPage}/>
        <PlaceList scroll_x={1200} customSearch={customSearch} currentPage={page} setCurrentPage={setPage}/>
      </Card>
    </>
  );
};

export default Places;

import { Modal, Form, AutoComplete, Input, Button, message } from "antd";
import React, { useState } from "react";

import { useApiContext } from '../../providers/ApiProvider.js';
import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch'
import FaIcon from '../FaIcon.js';
import { faSpinner } from "@fortawesome/pro-solid-svg-icons/faSpinner";


export default function ModalLinkApp({ item, onCancel }) {

    const [selected, setSelected] = useState()
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState()
    const [apiState, apiDispatch] = useApiContext();
    const { apiFetchCollection, apiPostRequest } = apiDispatch;
    const [appSearchPropositions, setAppSearchPropositions] = useState([])
    const [appSearchTimeout, setAppSearchTimeout] = useState(null)

    const handleChange = async (q) => {
        setLoading(true)
        setSelected(null)
        setValue(q)
        setAppSearchPropositions([])
        clearTimeout(appSearchTimeout);
        setAppSearchTimeout(setTimeout(async () => {
            const response = await apiFetchCollection('search-app-accounts', { noExtension: true, search: { query: q, contractId: item.id } })
            console.log(response)
            if (response && response.status == "success") {
                const predictions = response.data.map(function (v, i) {
                    return { value: v.id || v.uuid, text: v.name };
                })
                //console.log(predictions);
                setAppSearchPropositions(predictions)
            }
            setLoading(false)
        }, 500));

    }

    const handleValidation = async (action) => {
        setLoading(true)
        let response;
        if (action === 'link') {
            let data = { walletId: item.place.id }
            response = await apiPostRequest('update-app-account', { data: data, contractId: item.id, id: selected.value })
        } else {
            console.log('value', value.trim())
            response = await apiPostRequest('create-app-account', { contractId: item.id, name: value.trim() })
        }

        if (response.status === 'success') {
            message.success(`Compte ${action === 'link' ? 'lié' : 'crée'} avec success`)
            setLoading(false)
            return onCancel()
        } else {
            message.error('Il y a eu une erreur')
        }
        setLoading(false)
    }

    const handleSelect = (id) => {
        const selection = appSearchPropositions.find(item => item.value == id)
        console.log(selection)
        if (selection) setSelected(selection)
    }
console.log(selected)
    return (
        <Modal visible onCancel={onCancel}
            footer={[
                <Button key="back" onClick={onCancel}>
                    Retour
                </Button>
            ]}>
            <h3>Rechercher sur la plateforme / Nom du compte crée</h3>
            <Form.Item hasFeedback={false}>
                <AutoComplete
                    onSelect={handleSelect}
                    onSearch={handleChange}
                    dataSource={appSearchPropositions}
                    dropdownMatchSelectWidth={false}
                >
                    <Input suffix={loading ? <FaIcon icon={faSpinner} spin /> : <FaIcon icon={faSearch} />} />
                </AutoComplete>
            </Form.Item>
            {
                selected &&
                <div>
                    <p>Compte sélectionné : {selected.text}</p>
                    <Button onClick={() => handleValidation('link')} disabled={loading} type="primary">
                        Lier
                    </Button>
                </div>
            }

            {
                !selected && value &&
                <div>
                    <p>Créer le compte avec le nom : {value}</p>
                    <Button onClick={() => handleValidation('create')} disabled={loading}>Créer</Button>
                </div>
            }
        </Modal>
    )

}
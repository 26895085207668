import React, {useContext} from 'react';
import {useImmer} from 'use-immer';
import {useAuthContext} from '../providers/AuthProvider.js';
import axios from "axios";
import hydratools from '../utils/hydratools.js';

const api_endpoint = process.env.API_ENTRYPOINT + '/api';

// ---------------------------------------------------
// Default contextual state values
// ---------------------------------------------------
const defaultState = {};

// ---------------------------------------------------
// Context provider declaration
// ---------------------------------------------------
const StateContext = React.createContext();
const DispatchContext = React.createContext();

const ApiProvider = ({children}) => {
  const [state, dispatch] = useImmer({...defaultState});
  // alternatively without Immer:  const [state, dispatch] = useState({});

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
};

// ---------------------------------------------------
// Context usage function declaration
// ---------------------------------------------------
function useStateContext() {
  const state = useContext(StateContext);

  if (state === undefined) {
    throw new Error("Ut oh, where is my state?");
  }

  return state;
};

function useDispatchContext() {

  const [authState, authDispatch] = useAuthContext();
  const {auth} = authState;
  const {authLogout} = authDispatch;
  const authToken = auth ? auth.token : 'none';

  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);

  if (state === undefined) {
    throw new Error("Ut oh, where is my state?");
  }

  if (dispatch === undefined) {
    throw new Error("Ut oh, where is my dispatch?");
  }

  function responseAuthControl(api_response) {
    //console.log(api_response);
    if (api_response.error && api_response.error === "AuthenticationFailure") {
      authLogout();
      return false;
    }
    return true;
  }

  async function apiGooglePlaceDetail(id) {
    try {
      let response = await axios({
        method: 'get',
        url: process.env.API_ENTRYPOINT + '/api/googlePlaceDetail?place_id=' + id,
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + authToken
        },
      });
      return response.data
    } catch (e) {
      return;
    }
  }

  async function apiGooglePlaceAutocomplete(q) {
    try {
      let response = await axios({
        method: 'get',
        url: process.env.API_ENTRYPOINT + '/api/googlePlaceAutocomplete?q=' + q,
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + authToken
        },
      });
      return response.data
    } catch (e) {
      return;
    }
  }

  async function apiFetchEntity(endpoint, id, params = {}) {
    let headers = {'Accept': 'application/ld+json'}
    if (!params.anonymously) headers.Authorization = 'Bearer ' + authToken;

    try {
      let response = await axios({
        method: 'get',
        url: process.env.API_ENTRYPOINT + '/api/' + endpoint + '/' + id + '.jsonld',
        headers: headers,
      });
      return response.data
    } catch (e) {
      responseAuthControl(e.response.data)
      return e.response.data;
    }
  }

  async function apiUpdateEntity(endpoint, id, data) {
    let _data = hydratools.prePatchEntity(data);
    try {
      let response = await axios({
        method: 'patch',
        data: _data,
        url: process.env.API_ENTRYPOINT + '/api/' + endpoint + '/' + id + '.jsonld',
        headers: {
          'Accept': 'application/ld+json',
          'Content-Type': 'application/merge-patch+json',
          'Authorization': 'Bearer ' + authToken
        },
      });
      return response.data
    } catch (e) {
      responseAuthControl(e.response.data)
      return e.response.data;
    }
  }

  async function apiCreateEntity(endpoint, data) {
    let _data = hydratools.prePatchEntity(data);
    try {
      let response = await axios({
        method: 'post',
        data: _data,
        url: process.env.API_ENTRYPOINT + '/api/' + endpoint + '.jsonld',
        headers: {
          'Accept': 'application/ld+json',
          'Content-Type': 'application/ld+json',
          'Authorization': 'Bearer ' + authToken
        },
      });
      return response.data
    } catch (e) {
      responseAuthControl(e.response.data)
      return e.response.data;
    }
  }

  async function apiDeleteEntity(endpoint, id) {
    try {
      let response = await axios({
        method: 'delete',
        url: process.env.API_ENTRYPOINT + '/api/' + endpoint + '/' + id + '.jsonld',
        headers: {
          'Accept': 'application/ld+json',
          'Content-Type': 'application/merge-patch+json',
          'Authorization': 'Bearer ' + authToken
        },
      });
      return response.data
    } catch (e) {
      responseAuthControl(e.response.data)
      return e.response.data;
    }
  }

  function build_query(obj, num_prefix, temp_key) {
    var output_string = []
    Object.keys(obj).forEach(function (val) {
      var key = val;
      num_prefix && !isNaN(key) ? key = num_prefix + key : ''
      var key = encodeURIComponent(key.replace(/[!'()*]/g, escape));
      temp_key ? key = temp_key + '[' + key + ']' : ''
      if (typeof obj[val] === 'object') {
        var query = build_query(obj[val], null, key)
        output_string.push(query)
      } else {
        var value = encodeURIComponent(('' + obj[val]).replace(/[!'()*]/g, escape));
        output_string.push(key + '=' + value)
      }
    })
    return output_string.join('&')
  }

  async function apiFetchCollection(endpoint, params) {
    let jsonLd = params.noExtension ? '' : '.jsonld'
    let itemsPerPage = params.itemsPerPage || 10;
    let page = params.page || 1;
    let order = params.sortField ? '&order[' + params.sortField + ']=' + params.sortOrder : '';
    let search = params.search || {};
    let headers = {'Accept': 'application/ld+json'}
    if (!params.anonymously) headers.Authorization = 'Bearer ' + authToken;

    try {
      let response = await axios({
        method: 'get',
        url: process.env.API_ENTRYPOINT + '/api/' + endpoint + jsonLd + '?itemsPerPage=' + itemsPerPage + '&page=' + page + order + '&' + build_query(search),
        headers: headers,
      });
      return response.data
    } catch (e) {
      responseAuthControl(e.response.data)
      return e.response.data;
    }
  }

  async function apiPostRequest(endpoint, params) {

    try {
      let response = await axios({
        method: 'post',
        url: process.env.API_ENTRYPOINT + '/api/' + endpoint,
        data: params,
        headers: {
          'Accept': 'application/ld+json',
          'Authorization': 'Bearer ' + authToken
        },
      });
      return response.data
    } catch (e) {
      responseAuthControl(e.response.data)
      return e.response.data;
    }
  }

  // media objects
  async function apiPostMediaObject(file) {
    let formData = new FormData();
    formData.append('file', file);
    try {
      let response = await axios({
        method: 'post',
        data: formData,
        url: process.env.API_ENTRYPOINT + '/api/media_objects',
        headers: {
          'Accept': 'application/ld+json',
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + authToken
        },
      });
      return response.data
    } catch (e) {
      responseAuthControl(e.response.data)
      return e.response.data;
    }
  }

  async function apiDeleteMediaObject(id_or_entity) {
    let id = typeof id_or_entity === 'object' ? id_or_entity.id : id_or_entity;
    return await apiDeleteEntity('media_objects', id)
  }

  //Scrapper get

  async function testApiScrap(endpoint, id) {
    try {
      let response = await axios({
        method: 'get',
        url: process.env.API_ENTRYPOINT + '/api/' + endpoint + '/' + id + '/scrap',
        headers: {
          'Accept': 'application/ld+json',
          'Authorization': 'Bearer ' + authToken
        },
      });
      return response.data
    } catch (e) {
      responseAuthControl(e.response.data)
      return e.response.data;
    }
  }


  return {
    apiPostRequest,
    apiGooglePlaceAutocomplete,
    apiGooglePlaceDetail,
    apiFetchEntity,
    apiUpdateEntity,
    apiCreateEntity,
    apiFetchCollection,
    apiPostMediaObject,
    apiDeleteMediaObject,
    apiDeleteEntity,
    testApiScrap
  };
};

const useApiContext = () => {
  return [useStateContext(), useDispatchContext()]
}

// ---------------------------------------------------
// final export (addapt useContext and Provider name)
// ---------------------------------------------------
export {useApiContext, ApiProvider, StateContext, DispatchContext};

import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom'

import {Button, Typography, Popover, Tag, Avatar} from 'antd';

import FaIcon from '../FaIcon.js';

import ApiCollectionTable from '../ApiCollectionTable.js';
import {faUser} from "@fortawesome/pro-solid-svg-icons/faUser";
import {faBell, faBellSlash, faClock} from "@fortawesome/pro-light-svg-icons";
import moment from "moment";
import AutoCompleteInput from "../Form/AutoCompleteInput";
import {useApiContext} from "../../providers/ApiProvider";
import {commentRelationResolver} from "./_helpers";

const CommentList = ({scroll_x, ...rest}) => {

  const [saving, setSaving] = useState(false)
  const [apiState, apiDispatch] = useApiContext();
  const {apiFetchCollection, apiUpdateEntity, apiCreateEntity} = apiDispatch;
  const [needUpdate, setNeedUpdate] = useState(0)
  const [inputValue, setInputValue] = useState('')

  const handleSave = async (item, comment) => {

    setSaving(true);
    console.log(comment)
    // await UpdateComment()

    setSaving(false);
    // setNeedUpdate((new Date()).getTime())
  }

  const handleChange = async (item, comment) => {
    console.log('user', item)
    console.log('comment', comment)
    let targetUsers = [...comment.targetUsers, item['@id']]
    await updateComment(comment, {targetUsers})
    setInputValue('')
    setNeedUpdate((new Date()).getTime())
  }

  const updateComment = async (comment, params) => {
    console.log(params);
    await apiUpdateEntity('comments', comment.id, params)
  }

  const removeTargetUser = async (user, comment) => {
    console.log(comment)
    let targets = []
    comment.targetUsers.map(target => {
      if (target['@id'] !== user['@id']) {
        targets.push(target['@id'])
      }
    })
    console.log('targets', targets)
    await updateComment(comment, {targetUsers: targets})
    console.log('done')
    setNeedUpdate((new Date()).getTime())

  }

  const updateAlert = async (item, property) => {
    let data = {
      [property]: moment(new Date()).format('')
    }
    const update_response = await apiUpdateEntity('comments', item.id, data)
    console.log(update_response);
    setNeedUpdate((new Date()).getTime())

  }

  const columns = [
    {
      title: 'Message',
      dataIndex: 'text',
      render: (text, record) => <Typography.Paragraph
        ellipsis={{rows: 3}}
        style={{
          whiteSpace: "pre-wrap",
          overflowWrap: "break-word",
          overflowX: "hidden"
        }}>
        {text}
      </Typography.Paragraph>
    },
    {
      title: 'Personnes',
      dataIndex: 'targetUsers',
      render: (targetUsers, record) => <Popover
        destroyTooltipOnHide={true}
        content={
          <>
            <AutoCompleteInput options_endpoint={"users"}
                               search_property="username"
                               option_formater={(item) => {
                                 return {value: item['@id'], label: item.username};
                               }}
                               handleSave={(item) => handleSave(item, record)}
                               onChange={(item) => handleChange(item, record)}
                               placeholder={'Rechercher un utilisateur'}
                               enterButton={false}
                               resetInput={needUpdate}
            />
          </>
        }
        title={
          targetUsers.length > 0 ?
            <>
              {
                targetUsers.map(user =>
                  <Tag key={'tag_' + user['@id']} closable
                       onClose={() => removeTargetUser(user, record)}>{user.username}</Tag>
                )
              }
            </>
            : ''
        }
        trigger="click"
      >
          <Avatar style={{
            backgroundColor: '#87d068',
          }}
                  size="small">+</Avatar>
          <>
            {targetUsers.length > 0 ?
              targetUsers.map(user =>
                <Avatar size="small" key={'target_' + user['@id']}>{user.username[0].toUpperCase()}</Avatar>
              )
              : ''
            }
          </>
      </Popover>
    },
    {
      title: 'Auteur',
      dataIndex: 'createdAt',
      render: (createdAt, record) => <small>
        <FaIcon icon={faUser}/> {record.author.username}
        <br/>
        <FaIcon icon={faClock}/> {moment(createdAt).format("HH:mm DD-MM-YY")}
      </small>
    },
    {
      title: "Alerte",
      dataIndex: 'alertDate',
      render: (alertDate, record) =>
        !alertDate ?
          <small>
          <span style={{cursor: 'pointer', color: 'blue'}} onClick={(e) => {
            e.stopPropagation();
            updateAlert(record, 'alertDate')
          }}>
          <FaIcon icon={faBell}/> Créer une alerte
          </span>
          </small>
          :
          <small>
          <span style={{color: !record.alertEndDate ? 'red' : ''}}>
          <FaIcon icon={faBell}/> {moment(alertDate).format("HH:mm DD-MM-YY")}
          </span>
            <br/>
            {
              record.alertEndDate ?
                <>
                  <FaIcon icon={faBellSlash}/> {moment(record.alertEndDate).format("HH:mm DD-MM-YY")}
                </>
                : <span style={{cursor: 'pointer', color: 'green'}} onClick={(e) => {
                  e.stopPropagation();
                  updateAlert(record, 'alertEndDate')
                }}><FaIcon icon={faBellSlash}/> Terminer l'alerte</span>

            }
          </small>
    },
    {
      title: 'Entité',
      dataIndex: 'id',
      render: (id, record) => {
        let relation = commentRelationResolver(record)
        return (
          <small>
            <Link to={relation.link+'/'+record[relation.link].id} >{record[relation.link].name}</Link>
            <br/>
            <span>({relation.name})</span>
          </small>
        )
      }
    }
  ];


  return (
    <ApiCollectionTable
      title={(currentPageData) => <React.Fragment><b>Commentaires</b></React.Fragment>}
      fetch_endpoint="comments"
      columns={columns}
      scroll_x={scroll_x}
      contentVersion={needUpdate}
      {...rest}
    />
  );
};

export default CommentList;

import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Cascader } from 'antd'

import FaIcon from '../FaIcon.js';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons/faExclamationCircle'
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons/faExclamationTriangle'
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck'

import Countdown from '../../components/Countdown.js';

const FormCascader = ({options, onChange, name, value, showSearch, displayRender, ...rest}) => {

  const originalValue = value;
  const [internalOptions, setInternalOptions ] = useState(options || [])
  const [internalValue, setInternalValue ] = useState(originalValue)

  useEffect(() => {
  },[])


  const handleInputChange = (value) => {
    let chunk = {};
    let newValue = value;
    chunk[name] = newValue
    onChange(chunk);
    setInternalValue(newValue);
  }

  function searchFilter(inputValue, path) {
    return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
  }

  return <Cascader
    name={name}
    value={internalValue}
    onChange={handleInputChange}
    placeholder={showSearch ? "Search":null}
    options={internalOptions}
    showSearch={showSearch ? {filter:searchFilter}:false}
    displayRender={displayRender}
    {...rest}
  />
};

export default FormCascader;

import React, { useEffect, useState } from "react";
import { Tabs, Spin, List, Checkbox, Button } from "antd";
import { forceHttpsUrl } from "../_helpers";
import FaIcon from "../FaIcon";
import {faTrash} from '@fortawesome/pro-regular-svg-icons/faTrash'


const { TabPane } = Tabs

export default function PlaceData({ place, currentKey, setCurrentKey, switchValues, target, handleDelete }) {

    const [loader, setLoader] = useState(true)
    const [selectedContracts, setSelectedContracts] = useState([])
    const [selectedContacts, setSelectedContacts] = useState([])

    useEffect(() => {
        setSelectedContracts([])
        setSelectedContacts([])

    }, [place])

    const handleContractChange = (e, id) => {
        if (e.target.checked) {
            setSelectedContracts([...selectedContracts, id])
        } else {
            setSelectedContracts([...selectedContracts.filter(item => item !== id)])
        }
    }

    const handleContactChange = (e, id) => {
        if (e.target.checked) {
            setSelectedContacts([...selectedContacts, id])
        } else {
            setSelectedContacts([...selectedContacts.filter(item => item !== id)])
        }
    }

    const operations =<FaIcon icon={faTrash} style={{cursor: 'pointer', color: 'red'}} onClick={() => handleDelete(place.id)}/>;

    return (
        <>
            <h2>{place.name} {operations}</h2>
            <Tabs activeKey={currentKey} onChange={setCurrentKey}>
                <TabPane tab="Général" key="general">
                    {place.address}, {place.city}, {place.country}
                    <p>Géolocalisé : {place.gPlaceId}</p>
                    <p>Société : {place?.account?.name}</p>

                    {
                        place.website &&
                        <div>
                            <a href={place.website} target="_blank">Site : {place.website}</a>
                            <Spin spinning={loader}>
                                <iframe onLoad={() => setLoader(false)} frameBorder='0' src={forceHttpsUrl(place.website)} style={{ width: '100%', height: '400px' }}></iframe>
                            </Spin>
                        </div>
                    }
                </TabPane>
                <TabPane tab={`${place.contracts.length} Contrats`} key="contracts">
                    <List
                        itemLayout="horizontal"
                        dataSource={place.contracts}
                        renderItem={item => (
                            <List.Item>
                                <Checkbox onChange={(e) => handleContractChange(e, item.id)}>
                                    {item.product.name} {item.subscribedAt} : {item.endedAt}
                                </Checkbox>
                            </List.Item>
                        )}
                    />
                    {
                        !!selectedContracts.length &&
                        <Button onClick={() => switchValues(selectedContracts, 'mmc_contracts', target)}>Transférer</Button>
                    }
                </TabPane>
                <TabPane tab={`${place.contacts.length} Contacts`} key="contacts">
                    <List
                        itemLayout="horizontal"
                        dataSource={place.contacts}
                        renderItem={item => (
                            <List.Item>
                                <Checkbox onChange={(e) => handleContactChange(e, item.id)}>
                                    {item.firstname} {item.lastname} : {item.email}
                                </Checkbox>
                            </List.Item>
                        )}
                    />
                    {
                        !!selectedContacts.length &&
                        <Button onClick={() => switchValues(selectedContacts, 'mmc_contacts', target)}>Transférer</Button>
                    }
                </TabPane>
            </Tabs>
        </>
    )
}

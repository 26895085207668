import React, { useState, useEffect } from 'react';
import { Card, Button, Tag } from 'antd';

import { useApiContext } from '../providers/ApiProvider.js';
import TagAutoComplete from './Tag/TagAutoComplete.js';

const ApiCollectionTags = ({ title, fetch_endpoint, delete_endpoint, tag_enpoint, pageSize, columns, EntityForm, customSearch, baseData, item, relation, ...rest }) => {

  const [apiState, apiDispatch] = useApiContext();
  const { apiFetchCollection, apiUpdateEntity } = apiDispatch;

  const [inputVisible, setInputVisible] = useState('')
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  // internal trigger to force natural data fetch based on state change
  const [needUpdate, setNeedUpdate] = useState(0)

  useEffect(() => {
    // fetch at startup and whenever needUpdate is changed
    let params = {
      search: { ...customSearch }
    };
    fetch(params);
    setInputVisible(false)
  }, [needUpdate, customSearch])

  const fetch = async (params) => {
    setLoading(true)
    let hydra_response = await apiFetchCollection(fetch_endpoint, params);
    if (hydra_response['hydra:member']) {
      // console.log(hydra_response)
      setError(false);
      setData(hydra_response['hydra:member'])
    }
    else {
      setError(true);
    }
    setLoading(false);
  }

  const handleDelete = (tag) => {
    // console.log(tag)
    if (!!delete_endpoint && item && relation) {
      let data = {
        [relation]: tag[relation].filter(relation => relation != item['@id'])
      }
      updateTag(tag, data)
    }
  }

  const updateTag = async (tag, data) => {
    const update_response = await apiUpdateEntity(delete_endpoint, tag.id, data)
    // console.log(update_response)
  }

  return (
    <React.Fragment>
      {
        error
          ? <Card type="inner" title={typeof title === 'function' ? title() : title}><Button type="danger" onClick={fetch}>Error while loading. Retry ?</Button></Card>
          :
          <>
            {!inputVisible ?
              <Button title="Ajouter un tag" style={{ marginRight: '1em' }} type="primary" size="small" onClick={() => setInputVisible(true)}>+ Ajouter un tag</Button>
              :
              <TagAutoComplete
                endpoint={tag_enpoint} size="small" item={item} relation={relation}
                customSearch={customSearch} 
                setNeedUpdate={setNeedUpdate} 
                baseData={baseData}
                search_property='name' />
            }
            <div style={{ marginTop: '.5em' }}>
              {
                data.length > 0 ?
                  data.map(tag =>
                    <Tag
                      closable={!!delete_endpoint}
                      onClose={() => handleDelete(tag)}
                      key={tag.id}
                    >
                      {tag.name}
                    </Tag>
                  )
                  : '  Aucun tag  '
              }
            </div>
          </>
      }
    </React.Fragment>
  );
};

export default ApiCollectionTags;

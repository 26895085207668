import React from "react";
import {InputNumber, Table} from "antd";

export default function Recap({products, setProducts}) {

  function handleQuantityChange(key, value, id) {
    const index = products.findIndex(product => product["row_linkedid"] === id)

    products[index][key] = value;
    setProducts([...products]);
  }

  const columns = [
    {
      title: 'Produits',
      dataIndex: 'row_name',
    },
    {
      title: 'Quantité',
      dataIndex: 'row_qt',
      render: (row_qt, record) => <InputNumber min={1} value={row_qt}
                                               onChange={value => handleQuantityChange("row_qt", value, record.row_linkedid)}/>
    },
    {
      title: 'Abonnement',
      dataIndex: 'row_unit',
    },
    {
      title: 'Prix unitaire HT',
      dataIndex: 'row_unitAmount',
      render: (row_unitAmount, record) => <InputNumber min={1} value={row_unitAmount}
                                                       onChange={value => handleQuantityChange("row_unitAmount", value, record.row_linkedid)}/>
    },
  ];

  console.log(products)

  return (
    <div style={{marginBottom: 50}}>
      <h2>Récapitulatif</h2>

      <Table
        dataSource={products}
        columns={columns}
        rowKey={record => record.row_linkedid}
        pagination={false}
      />

      <div style={{display: "flex", justifyContent: "flex-end", padding: "30px 30px 10px 30px"}}>
        <div style={{fontSize: 20, fontWeight: "bold"}}> Total HT {products.reduce((a, b) => a + (Number(b.row_unitAmount) * Number(b.row_qt)), 0)} €</div>
      </div>
      <div style={{display: "flex", justifyContent: "flex-end", padding: "10px 30px 30px 30px"}}>
        <div style={{fontSize: 20, fontWeight: "bold"}}> Total TTC {products.reduce((a, b) => a + (Number(b.row_unitAmount) * Number(b.row_qt)), 0) * 1.2} €</div>
      </div>
    </div>
  )
}

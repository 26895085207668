import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, message } from "antd";
import { Link } from 'react-router-dom'

import { useApiContext } from '../../providers/ApiProvider.js';
import FaIcon from '../FaIcon.js';
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash'
import { faPhoneRotary } from '@fortawesome/pro-solid-svg-icons/faPhoneRotary'
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope'
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';

import ModalCompare from './ModalCompare.js';
import { faMapMarkerCheck } from '@fortawesome/pro-solid-svg-icons';
import { cleanUrl } from '../_helpers.js';

export default function DisplayPlaces({ customSearch }) {

    const [apiState, apiDispatch] = useApiContext();
    const { apiFetchCollection, apiDeleteEntity } = apiDispatch;

    const [data, setData] = useState([])
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 30,
        position: 'both',
        simple: true,
    })

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    // internal trigger to force natural data fetch based on state change
    const [needUpdate, setNeedUpdate] = useState(0)
    const [showModalCompare, setShowModalCompare] = useState(false)

    const onSelectChange = selectedRowKeys => {
        // console.log('selectedRowKeys changed: ', selectedRowKeys);
        if (selectedRowKeys.length > 2) {
            selectedRowKeys = selectedRowKeys.slice(-2)
        }
        setSelectedRowKeys(selectedRowKeys);
    };

    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const closeModal = () => {
        setNeedUpdate((new Date()).getTime())
        setSelectedRowKeys([])
        setShowModalCompare(false)
    }



    useEffect(() => {
        // fetch at startup and whenever needUpdate is changed
        let params = {
            itemsPerPage: pagination.pageSize,
            page: pagination.current,
            search: { ...customSearch }
        };

        fetch(params);
        // setSelectedRowKeys([])

    }, [needUpdate, customSearch])

    const handleTableChange = (table_pagination, table_sorter) => {
        // update current pagination
        const pager = { ...table_pagination };
        pager.current = table_pagination.current;
        setCurrentPage(table_pagination.current)
        setPagination(pager)
        // update current sorter
        setSorter({ ...table_sorter })

        // trigger a data refresh
        setNeedUpdate((new Date()).getTime());
    };


    const columns = [
        {
            title: 'Hotel name',
            dataIndex: 'name',
            width: 250,
            render: (name, record) => <Link to={`/place/${record.id}`}>{ record.gPlaceId && <FaIcon icon={faMapMarkerCheck} />} {name}<br /><small>({record.address}, {record.city})</small>
            </Link>,
        },
        {
            title: 'Groupe / Société',
            dataIndex: 'account',
            width: 200,
            render: (account, record) => <React.Fragment>
                {
                    account && account.cluster ?
                        <React.Fragment>Groupe : {account.cluster.name}<br /></React.Fragment> : null
                }
                {account ?
                    <React.Fragment>Société : {account.name} </React.Fragment> : null

                }
            </React.Fragment>,
        },
        {
            title: 'Contracts',
            dataIndex: 'contracts',
            width: 100,
            render: (contracts, record) => contracts.length ? <div style={{ width: '100%', textAlign: 'center' }}>{contracts.length}<br /></div> : null,
        },
        {
            title: 'Contact',
            dataIndex: 'phone',
            width: 250,
            render: (phone, record) => <React.Fragment>
                {!!record.phone && <React.Fragment><FaIcon icon={faPhoneRotary} /> {phone}<br /></React.Fragment>}
                {!!record.email && <React.Fragment><FaIcon icon={faEnvelope} /> {record.email}<br /></React.Fragment>}
                {!!record.contacts.length && <React.Fragment><FaIcon icon={faUser} /> {record.contacts.length}<br /></React.Fragment>}
                <React.Fragment>Site: <a href={cleanUrl(record.website)} target="_blank">{record.website}</a> </React.Fragment>
            </React.Fragment>,
        },
        {
            title: '',
            width: 50,
            dataIndex: 'id',
            render: (id, record) => <a onClick={() => showDeleteConfirm(() => deleteElement(id))}><FaIcon icon={faTrash} /></a>,
        }
    ];

    const showDeleteConfirm = (callback) => {
        Modal.confirm({
          title: 'Confirmer la suppression?',
          icon: <FaIcon icon={faExclamationTriangle}/>,
          okText: 'Oui',
          okType: 'danger',
          cancelText: 'Non',
          onOk() {
            callback('OK');
          },
          onCancel() {
            // console.log('Cancel');
          },
        });
      }

    const deleteElement = async (id) => {
        let response = await apiDeleteEntity('mmc_places', id);
        if (response['@type'] === "hydra:Error") {
            message.error('Impossible de suprimmer cet élément')
            return
        }
        message.success('Elément supprimé avec succes')

        setNeedUpdate((new Date()).getTime());
    }

    const fetch = async (params) => {
        setLoading(true)
        let hydra_response = await apiFetchCollection('mmc_places', params);

        let pager = { ...pagination };
        if (hydra_response) {
            setError(false);
            pager.total = hydra_response['hydra:totalItems'];
            if (pager.total > pager.itemsPerPage) pager.position
            setPagination(pager)
            setData(hydra_response['hydra:member'])
            // if no result and not in first result page => go to the last available one
            if (hydra_response['hydra:member'].length === 0 && pager.current > 0) {
                pager.current = Math.floor(pager.total / pager.pageSize);
                setPagination(pager);
                setNeedUpdate((new Date()).getTime());
            }
        } else {
            setError(true);
        }
        setLoading(false);
    }

    return (
        <React.Fragment >
            {
                selectedRowKeys.length === 2 &&
                <Button type="primary" onClick={() => setShowModalCompare(true)} style={{margin: '1em 0'}}>Comparer ces éléments</Button>
            }
            <Table
                rowSelection={rowSelection}
                title={''}
                bordered={true}
                className="ApiCollectionTable"
                columns={columns}
                rowKey={record => record['id']}
                dataSource={data}
                pagination={pagination.total <= pagination.pageSize ? false : pagination}
                loading={loading}
                onChange={handleTableChange}
                style={{margin: '1em 0'}}
            />
            {
                showModalCompare &&
                <ModalCompare ids={selectedRowKeys} onCancel={closeModal} />
            }
        </React.Fragment>
    )

}
import React, {useEffect, useState} from 'react';
import {useApiContext} from "../providers/ApiProvider";
import FaIcon from '../components/FaIcon.js';
import {faQuestionCircle} from '@fortawesome/pro-light-svg-icons/faQuestionCircle'
import {Link, useParams} from "react-router-dom";
import {Spin, Typography} from "antd";
import SupportBreadcrumb from "../components/Support/Frontend/SupportBreadcrumb";
import Categories from "../components/Support/Frontend/Categories";
import {getLanguage, slugify, sortArticles, translate} from "../components/Support/helpers";
import Search from "../components/Support/Frontend/Search";

const SupportCategory = () => {

  const [apiState, apiDispatch] = useApiContext();
  const {apiFetchEntity} = apiDispatch;
  const params = useParams();
  const lng = getLanguage(params.lng)

  const [category, setCategory] = useState({});
  const [breadcrumb, setBreadcrumb] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch();
  }, [params.id])

  async function fetch() {
    setLoading(true);
    const id = params.id;
    const response = await apiFetchEntity('article_categories', id, {anonymously: true, 'pagination': false});
    setBreadcrumb(buildBreadcrumb(response).reverse());
    setCategory(response);
    setLoading(false);
  }

  function buildBreadcrumb(_category) {
    let _breadcrumb = [];

    _breadcrumb.push({id: _category.id, name: translate(_category, 'name', lng)});

    if (_category.parent)
      _breadcrumb = _breadcrumb.concat(buildBreadcrumb(_category.parent))
    else
      _breadcrumb = _breadcrumb.concat({id: _category.product.id, name: _category.product.name})

    return _breadcrumb;
  }

  return (
    <div>
      <Search/>

      <Spin spinning={loading}>

        <SupportBreadcrumb breadcrumb={breadcrumb}/>

        {!!category.articles?.length &&
          <div className="articles-container">

            <div className="header-articles">
              <div className="img-block">
                {category.image ? <img src={category.image}/>
                  : <FaIcon icon={faQuestionCircle}/>}
              </div>
              <div className="text-block">
                <h1>{translate(category, 'name', lng)}</h1>
                <div>{category.articles?.filter(article => article.activeLngs.includes(lng)).length} articles dans cette
                  collection
                </div>
              </div>
            </div>

            <div className="articles">
              {category.articles?.filter(article => article.activeLngs.includes(lng)).sort(sortArticles).map(article => (
                <div key={article.id} className="article">
                  <Link
                    to={`/${lng}/support/${category.product.id}-${slugify(category.product.name)}/article/${article.id}`}>
                    <h2>{translate(article, 'title', lng)}</h2>
                    {translate(article, 'description', lng) ?
                      <p dangerouslySetInnerHTML={{__html: translate(article, 'description', lng)}}/>
                      :
                      <Typography.Paragraph ellipsis={{rows: 2}}>
                        <div dangerouslySetInnerHTML={{__html: article.content.fr}}/>
                      </Typography.Paragraph>}
                  </Link>
                </div>
              ))}
            </div>

          </div>}


        <Categories categories={category.children}/>

      </Spin>
    </div>
  );
};

export default SupportCategory;

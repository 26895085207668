import React, { useEffect, useState } from "react";
import { Breadcrumb, Card, Spin } from "antd";
import { Link, useParams, useHistory } from "react-router-dom";
import { useApiContext } from '../providers/ApiProvider.js';
import EditArticle from "../components/Support/Admin/EditArticle";
import { useAuthContext } from "../providers/AuthProvider.js";

const Article = () => {

    const history = useHistory()

    const [apiState, apiDispatch] = useApiContext();
    const { apiFetchEntity } = apiDispatch;
    const [authState, authDispatch] = useAuthContext();
    const {auth} = authState;
    const [article, setArticle] = useState({})
    const [loading, setLoading] = useState(false)
    const [redirection, setRedirection] = useState(false);

    const params = useParams();
    useEffect(() => {
        // fetch at startup and whenever needUpdate is changed
        if (params.id !== "new") {
            fetchData();
        } else {
            setArticle({id:'new', activeLngs: [], author: '/api/users/'+auth.id})
        }
    }, [])


    async function fetchData() {
        setLoading(true);
        console.log('fetching data')
        const response = await apiFetchEntity('articles', params.id);
        if (response['@type'] === "hydra:Error") {
            setRedirection(true)
        }
        setArticle(response)
        setLoading(false);
    }

    let cardTitle = (
        <Breadcrumb>
            <Breadcrumb.Item>
                <Link to="/">Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                <Link to="/documentation/">Documentation</Link>
            </Breadcrumb.Item>
        </Breadcrumb>
    );

    if (redirection) {
        return history.goBack('/documentation')
    }

    return (
        <Card title={cardTitle}>
            <Spin spinning={loading}>
                <EditArticle article={article} setArticle={setArticle} setLoading={setLoading} />
            </Spin>
        </Card>
    );
}

export default Article

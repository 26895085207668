import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import { Button } from 'antd';

import ApiCollectionLatest from '../ApiCollectionLatest.js';

import FaIcon from '../FaIcon.js';
import { faPhoneRotary } from '@fortawesome/pro-solid-svg-icons/faPhoneRotary'
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope'

import ContactForm from './ContactForm.js'
import ContactDrawer from './ContactDrawer.js';
import {endpointResolver} from './_helpers.js'
import {faMobile} from "@fortawesome/pro-solid-svg-icons/faMobile";


const ContactDash = ({id, endpoint, cardTitle, ...rest}) => {

  const [selectedItem, setSelectedItem ] = useState(null)
  const [creationMode, setCreationMode ] = useState(false)

  const [contentVersion, setContentVersion ] = useState(0)

  const handleCreationFormClose = () => {
    setCreationMode(false);
  }
  const handleCreationFormChange = () => {
  }

  const handleEditFormClose = () => {
    setSelectedItem(null);
  }
  const handleEditFormChange = () => {
    setContentVersion((new Date()).getTime());
  }

  const columns = [
    {
      title: '',
      dataIndex: 'firstname',
      search: true,
      sorter: true,
      render: (firstname,record) => <React.Fragment>
        {firstname + ' ' + record.lastname} <br />
        {
          record.contactRole ?
            <small>({record.contactRole.name})</small>
            : null
        }
      </React.Fragment>
    },
    {
      title: '',
      dataIndex: 'email',
      search: true,
      sorter: true,
      render: (email,record) => <small>
        <FaIcon icon={faEnvelope} /> {record.email}
        <br />
        <FaIcon icon={faPhoneRotary} /> {record.phone}
        <br />
        <FaIcon icon={faMobile} /> {record.mobilePhone}
      </small>
    },
  ];

  if(creationMode)
    return <ContactForm modal item_id="new" entity_id={id} endpoint={endpoint} onClose={handleCreationFormClose} onChange={handleCreationFormChange}/>;

  return (
    <React.Fragment>
      <ApiCollectionLatest
        title={(currentPageData) => <React.Fragment>
          {
            id && endpoint ? <Button size="small" onClick={()=>{setCreationMode(true)}}>+</Button> : null
          }
          <b> {cardTitle ? cardTitle : "Contacts"}</b>
          <Link style={{float:"right"}} to={"/"+endpointResolver(endpoint)+"/"+id+"/contacts"}>See all</Link>
        </React.Fragment>}
        fetch_endpoint={id && endpoint ? `${endpoint}/${id}/contacts`:"mmc_contacts"}
        delete_endpoint="mmc_contacts"
        columns={columns}
        pageSize={5}
        onClick={(item) => setSelectedItem({...item}) }
        contentVersion={contentVersion}
        {...rest}
      />
      {selectedItem ? <ContactDrawer item={selectedItem} onClose={handleEditFormClose} onChange={handleEditFormChange} /> : null}
    </React.Fragment>
  );
};

export default ContactDash;

import React from 'react';
import { Tag, Typography } from 'antd';

import moment from 'moment';

import FaIcon from '../FaIcon.js';
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle'
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons/faAngleRight'


import { red, green, orange } from '@ant-design/colors';

export const actives = [
  {value:false, label:"Disabled", color:red.primary, icon:faTimes},
  {value:true,  label:"Active", color:green.primary, icon:faCheck},
]

export const subscriptionResume = (item) => {
  let earn = null;
  if(item.periodicity>0 && item.amount>0)
  {
    const start = moment(item.subscribedAt, "YYYY-MM-DD");
    const end = item.endedAt ? moment(item.endedAt, "YYYY-MM-DD") : moment();
    const months = Math.abs(end.diff(start, 'months'));
    earn = Math.floor(months / item.periodicity) * item.amount;
  }
  const subscriptionIsActive = !moment(item.endedAt, "YYYY-MM-DD").isBefore()
  return <span style={{fontWeight:subscriptionIsActive ? 'bold':'normal', color:subscriptionIsActive ? green.primary:orange.primary}}>
    <span>{moment(item.subscribedAt, "YYYY-MM-DD").format('DD/MM/YYYY')}</span>
    {item.endedAt ? <span> <FaIcon icon={faAngleRight} /> {moment(item.endedAt, "YYYY-MM-DD").format('DD/MM/YYYY')}</span> : null}
  </span>
}

export const benefitsResume = (item) => {
  let earn = null;
  if(item.periodicity>0 && item.amount>0)
  {
    const start = moment(item.subscribedAt, "YYYY-MM-DD");
    const end = item.endedAt ? moment(item.endedAt, "YYYY-MM-DD") : moment();
    const months = Math.abs(end.diff(start, 'months'));
    earn = Math.floor(months / item.periodicity) * item.amount;
  }
  return earn!==null ? <span>{earn+'€'}</span> : null;
}

export const activesResolver = (item) => {
  //console.log(item.endedAt, moment(item.endedAt, "YYYY-MM-DD"), moment(item.endedAt, "YYYY-MM-DD").isBefore());
  let value = !moment(item.endedAt, "YYYY-MM-DD").isBefore();
  for(let i=0; i < actives.length; i++)
  {
    if (actives[i].value===value)
      return actives[i];
  }
  return {value:null, label:"???", color:"red"};
}

export const activesTagResolver = (item, options = {}) => {
  let v = activesResolver(item)

  if(options.compact)
    return <FaIcon style={{color:v.color, marginRight:"4px"}} icon={v.icon} />

  return <Tag color={v.color}><FaIcon icon={v.icon} /> {item.product.name}</Tag>
}

export default { actives, activesResolver, activesTagResolver }

import React, {useEffect, useState} from 'react';
import {Button, Card, Cascader, Checkbox, Input, message, Table, Modal} from 'antd';

import FaIcon from './FaIcon.js';
import {faTrash} from '@fortawesome/pro-regular-svg-icons/faTrash'
import {faSearch} from '@fortawesome/pro-regular-svg-icons/faSearch'

import {useApiContext} from '../providers/ApiProvider.js';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';

const ApiCollectionTable = ({title, contentVersion, onClick, scroll_x, fetch_endpoint, delete_endpoint,
                              pageSize, columns, EntityForm, customSearch, setTotal,
                              allowSelections, currentPage, setCurrentPage, ...rest}) => {
  setCurrentPage = setCurrentPage || function() {};
  const clickable = !!onClick
  onClick = onClick || function () {};
  setTotal = setTotal || function () {};

  scroll_x = scroll_x || 0;

  const [apiState, apiDispatch] = useApiContext();
  const {apiFetchCollection, apiDeleteEntity} = apiDispatch;

  const [internalColumns, setInternalColumns] = useState(null)
  const [data, setData] = useState([])
  const [search, setSearch] = useState({})
  const [pagination, setPagination] = useState({
    current: parseInt(currentPage) || 1,
    pageSize: parseInt(pageSize) || 30,
    position: 'both',
    simple: true,
  })

  const [sorter, setSorter] = useState({
    field: null,
    order: 'ascend',
  })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  // internal trigger to force natural data fetch based on state change
  const [needUpdate, setNeedUpdate] = useState(0)

  useEffect(() => {
    // fetch at startup and whenever needUpdate is changed
    let params = {
      itemsPerPage: pagination.pageSize,
      page: pagination.current,
      search: {...search, ...customSearch}
    };
    if (sorter.field && sorter.order) {
      params.sortField = sorter.field;
      params.sortOrder = sorter.order == "descend" ? 'DESC' : 'ASC';
    }
    fetch(params);
    setSelectedRowKeys([])

  }, [needUpdate, contentVersion, customSearch])

  const handleTableChange = (table_pagination, table_filters, table_sorter) => {
    // update current pagination
    const pager = {...table_pagination};
    pager.current = table_pagination.current;
    setCurrentPage(table_pagination.current)
    setPagination(pager)
    // update current sorter
    setSorter({...table_sorter})

    // trigger a data refresh
    setNeedUpdate((new Date()).getTime());
  };

  const fetch = async (params) => {
    setLoading(true)
    let hydra_response = await apiFetchCollection(fetch_endpoint, params);

    let pager = {...pagination};
    if (hydra_response) {
      setError(false);
      pager.total = hydra_response['hydra:totalItems'];
      if (pager.total > pager.itemsPerPage) pager.position
      setPagination(pager)
      setData(hydra_response['hydra:member'])
      setTotal(hydra_response['hydra:totalItems'])
      // if no result and not in first result page => go to the last available one
      if (hydra_response['hydra:member'].length === 0 && pager.current > 0) {
        pager.current = Math.floor(pager.total / pager.pageSize);
        setPagination(pager);
        setNeedUpdate((new Date()).getTime());
      }
    } else {
      setError(true);
    }
    setLoading(false);
  }

  const handleSearchChange = (value, confirm, dataIndex, type) => {
    typeof confirm === 'function' ? confirm() : null;
    if (type === "cascader" && value.length)
      value = value[value.length - 1];

    search[dataIndex] = value;
    setSearch(search);
    pagination.current = 0
    setPagination(pagination)
    setNeedUpdate((new Date()).getTime())
  }

  const handleSearchReset = (clearFilters, dataIndex) => {
    clearFilters();
    delete search[dataIndex];
    setSearch(search);
    pagination.current = 0
    setPagination(pagination)
    setNeedUpdate((new Date()).getTime())
  };

  if (internalColumns === null) {
    var _columns = [];
    for (let i = 0; i < columns.length; i++) {
      _columns.push({...columns[i]});
      if (columns[i].search === true) {
        let dataIndex = columns[i].dataIndex;
        _columns[i].filterDropdown = ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => {
          return <div style={{padding: 8}}>
            <Input
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearchChange(selectedKeys[0] || '', confirm, dataIndex, 'text')}
              style={{width: 188, marginBottom: 8, display: 'block'}}
            />
            <Button onClick={() => handleSearchReset(clearFilters, dataIndex)} size="small" style={{width: 90}}>
              <FaIcon icon={faTrash}/>
              Reset
            </Button>
            <Button
              type="primary"
              onClick={() => handleSearchChange(selectedKeys[0] || '', confirm, dataIndex, 'text')}
              size="small"
              style={{width: 90, marginLeft: 8}}
            >
              <FaIcon icon={faSearch}/>
              Search
            </Button>

          </div>
        };
        _columns[i].filterIcon = filtered => (
          <FaIcon icon={faSearch} style={{color: filtered ? '#1890ff' : undefined}}/>
        );
      } else if (columns[i].search && columns[i].search.type === "cascader") {
        let dataIndex = columns[i].dataIndex;
        _columns[i].filterDropdown = ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => {
          return <div style={{padding: 8}}>
            <Cascader
              style={{width: 400, marginRight: 8}}
              defaultValue={[]}
              placeholder={"Search"}
              options={columns[i].search.options}
              showSearch={{
                filter: (inputValue, path) => {
                  return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
                }
              }}
              changeOnSelect
              onChange={(checkedValues) => handleSearchChange(checkedValues, confirm, dataIndex, 'cascader')}
            />
            <Button onClick={() => handleSearchReset(clearFilters, dataIndex)}>
              <FaIcon icon={faTrash}/>
              Reset
            </Button>
          </div>
        };
      } else if (columns[i].search && columns[i].search.length > 0) {
        let dataIndex = columns[i].dataIndex;
        _columns[i].filterDropdown = ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => {
          return <div style={{padding: 8}}>
            <Checkbox.Group
              style={{marginBottom: 8}}
              options={columns[i].search.map(function (o) {
                o.value = o.value === null ? '' : o.value;
                return o;
              })}
              defaultValue={[]}
              onChange={(checkedValues) => handleSearchChange(checkedValues, confirm, dataIndex, 'checkbox')}
            />
            <Button onClick={() => handleSearchReset(clearFilters, dataIndex)}>
              <FaIcon icon={faTrash}/>
              Reset
            </Button>
          </div>
        };
      }
    }
    

    if (delete_endpoint) {
      _columns.push({
        title: '',
        width: 50,
        dataIndex: 'id',
        render: (id, record) => <a onClick={(e) => showDeleteConfirm(e, () => deleteElement(id))}><FaIcon icon={faTrash}/></a>,
      });
    }

    setInternalColumns(_columns);
  }

  const deleteElement = async (id) => {
    let response = await apiDeleteEntity(delete_endpoint, id);
    if (response['@type'] ===  "hydra:Error") {
      message.error('Impossible de supprimer cet élément')
      return
    }
    message.success('Élément supprimé avec succès')

    setNeedUpdate((new Date()).getTime());
  }

  const onSelectChange = selectedRowKeys => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    setSelectedRowKeys(selectedRowKeys);
  };

  const deleteSelection = async () => {
    setLoading(true)
    await Promise.all(
        selectedRowKeys.map( async id =>
        await apiDeleteEntity(delete_endpoint, id )
      )
    )
    setNeedUpdate((new Date().getTime()))
    setLoading(false)
  }

  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const showDeleteConfirm = (e, callback) => {
    e.stopPropagation()
    Modal.confirm({
      title: 'Confirmer la suppression?',
      icon: <FaIcon icon={faExclamationTriangle}/>,
      okText: 'Oui',
      okType: 'danger',
      cancelText: 'Non',
      onOk() {
        callback('OK');
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  return (
    <React.Fragment>
      {
        selectedRowKeys.length > 0 ?
          <div style={{marginBottom: '1em'}}>
            <Button type="primary" onClick={(e) => showDeleteConfirm(e, deleteSelection)}>Delete Selection</Button> Selected {selectedRowKeys.length} items
          </div>
          : ''
      }
      {
        error ?
          <Card type="inner" title={typeof title === 'function' ? title() : title}>
            <Button type="danger" onClick={fetch}>
              Error while loading. Retry ?
            </Button>
          </Card>
          : <Table
            rowSelection={allowSelections ? rowSelection : null}
            rowClassName={clickable ? 'clickable-row' : ''}
            title={title || false}
            bordered={true}
            className="ApiCollectionTable"
            columns={internalColumns}
            rowKey={record => record['id']}
            dataSource={data}
            pagination={pagination.total <= pagination.pageSize ? false : pagination}
            loading={loading}
            onChange={handleTableChange}
            scroll={{x: scroll_x}}
            onRow={(record, rowIndex) => {
              return {
                onClick: event => {
                  onClick(record);
                }, // click row
              };
            }}
            {...rest}
          />
      }
    </React.Fragment>
  );
};

export default ApiCollectionTable;

import React, { useState } from 'react'

import { useApiContext } from '../../providers/ApiProvider.js';
import FormSelect from '../Form/FormSelect.js'
import FormInput from '../Form/FormInput.js'

import { Button, Col, Form, Row } from 'antd'

export default function AccountFilters({ customSearch, setCustomSearch }) {

    const [apiState, apiDispatch] = useApiContext();
    const [_item, setItem] = useState(customSearch)
    const [contentVersion, setContentVersion] = useState(0)

    async function handlePropertyChange(chunk) { // {property:value}
        console.log('ck', chunk)
        setItem(Object.assign(_item, chunk));
        handleFilter()

    }


    const handleFilter = () => {
        console.log('filter', _item)
        let customFilters = {}

        if (typeof _item.author === 'string')
            customFilters.author = _item.author;

        customFilters.name = typeof _item.name === 'string' ? _item.name : '';

        console.log('custo', customFilters)
        setCustomSearch(customFilters);

    }


    const resetFilters = () => {
        setItem({})
        setCustomSearch({})
        setContentVersion((new Date()).getTime());
    }

    return (
        <div style={{ marginBottom: '1em' }}>
            <Row type="flex" gutter={[16, 16]}>
                <Col span={6}>
                    <Form.Item label="Nom : " hasFeedback={false}>
                        <FormInput
                            name="name"
                            value={_item.name}
                            style={{ width: "300px" }}
                            onChange={handlePropertyChange}
                            contentVersion={contentVersion}
                            allowClear
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row type="flex" gutter={[16, 16]}>
                <Col span={24}>
                    <Button style={{ marginRight: '1em' }} onClick={resetFilters}>Reset</Button>
                </Col>
            </Row>

        </div>
    )
}

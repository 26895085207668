import React, { useState } from 'react';
import { useParams } from 'react-router-dom'
import { Button } from 'antd';

import ApiCollectionTable from '../ApiCollectionTable.js';

import TagForm from "./TagForm";
import { typeFormatter } from "./_helpers";

const TagList = ({ scroll_x, ...rest }) => {

  const [selectedItem, setSelectedItem] = useState(null)
  const [creationMode, setCreationMode] = useState(false)

  const [contentVersion, setContentVersion] = useState(0)
  const params = useParams();

  const handleCreationFormClose = () => {
    setCreationMode(false);
  }
  const handleCreationFormChange = () => {
  }

  const handleEditFormClose = () => {
    setSelectedItem(null);
  }
  const handleEditFormChange = () => {
    setContentVersion((new Date()).getTime());
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      search: true,
      sorter: true,
      render: (name, record) => <span>
        {name}
      </span>,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      search: true,
      sorter: true,
      render: (type, record) => typeFormatter(type, 'display'),
    },
  ];

  if (creationMode)
    return <TagForm modal item_id="new" onClose={handleCreationFormClose} onChange={handleCreationFormChange} type={params.type ? params.type : null} />;

  return (
    <React.Fragment>
      <ApiCollectionTable
        title={(currentPageData) => <React.Fragment><b>{params.type ? typeFormatter(params.type, 'display') : "Tag List"}</b> <Button style={{ float: "right" }} type="primary" size="small" onClick={() => { setCreationMode(true) }}>+</Button></React.Fragment>}
        fetch_endpoint="tags"
        delete_endpoint="tags"
        columns={columns}
        scroll_x={scroll_x}
        onClick={(item) => setSelectedItem({ ...item })}
        contentVersion={contentVersion}
        customSearch={params.type ? { type: params.type } : {}}
        allowSelections={true}
        {...rest}
      />
    </React.Fragment>
  );
};

export default TagList;

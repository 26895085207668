import React, { useState, useEffect } from 'react';
import { Input } from 'antd'

import FaIcon from '../FaIcon.js';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons/faExclamationCircle'
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck'


const FormInput = ({textarea, onChange, name, value, contentVersion, ...rest}) => {

  const originalValue = ''+(value || '')
  const [internalValue, setInternalValue ] = useState(value || '')
  const [saveNeeded, setSaveNeeded ] = useState(false)

  useEffect(() => {
    if (contentVersion) {
      setInternalValue(value || '')
      setSaveNeeded(false)
    }
  }, [contentVersion])

  const handleInputChange = (event) => {
    let chunk = {};
    let newValue = event.target.value;
    chunk[name] = newValue.trim()
    onChange(chunk);

    setInternalValue(newValue);
    setSaveNeeded(newValue!==originalValue);
  }

  const suffix = internalValue==='' ? <FaIcon icon={faExclamationCircle} style={{color:"#ff9800"}} /> : (saveNeeded ? <FaIcon icon={faExclamationCircle} style={{color:"#dd5246"}} /> : <FaIcon icon={faCheck} />);

  if(textarea)
    return <Input.TextArea autoSize={true} suffix={suffix} style={{flex:1}} {...rest} value={internalValue} onChange={handleInputChange} onPressEnter={handleInputChange}/>;
  else
    return <Input suffix={suffix} style={{flex:1}} {...rest} value={internalValue} onChange={handleInputChange} onPressEnter={handleInputChange}/>;
};

export default FormInput;

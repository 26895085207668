import React, { useState } from 'react'

import FormSelect from '../Form/FormSelect.js'
import FormInput from '../Form/FormInput.js'

import { Button, Checkbox, Col, Form, Row } from 'antd'
import { distanceValues, itemResolver } from './_helpers'
import FormCheckbox from '../Form/FormCheckbox.js'

export default function PlaceFilters({ customSearch, setCustomSearch, setPage }) {

  const [_item, setItem] = useState(itemResolver(customSearch))
  const [contentVersion, setContentVersion] = useState(0)
  const [displayEndContract, setDisplayEndContract] = useState(_item.withProducts ? _item.withProducts.length === 1 : false)


  async function handlePropertyChange(chunk) { // {property:value}
    console.log(chunk)
    setItem(Object.assign(_item, chunk));
    if (chunk.hasOwnProperty('withProducts')) {
      setDisplayEndContract(false)
      if (chunk.withProducts.length === 1) setDisplayEndContract(true)
    }
    if (chunk.hasOwnProperty('name') || chunk.hasOwnProperty('archived') || chunk.hasOwnProperty('stars')) {
      handleFilter()
    }
  }

  console.log(_item)


  const handleFilter = () => {

    let customFilters = {}
    customFilters.name = typeof _item.name === 'string' ? _item.name : '';
    customFilters.website = typeof _item.website === 'string' ? _item.website : '';
    customFilters.withoutProducts = _item.withoutProducts && _item.withoutProducts.length >= 1 ? _item.withoutProducts.join(',') : '';
    customFilters.withProducts = _item.withProducts && _item.withProducts.length >= 1 ? _item.withProducts.join(',') : '';
    customFilters.productSelection = typeof _item.productSelection === 'string' ? _item.productSelection : 'ET';
    customFilters.contractStatus = typeof _item.contractStatus === 'string' ? _item.contractStatus : '';
    customFilters.city = typeof _item.city === 'object' ? (_item.city.city === null ? 'empty' : _item.city.city) : (typeof _item.city === 'string' ? _item.city : '');
    customFilters.lat = typeof _item.city === 'object' && _item.city.lat !== null ? _item.city.lat : '';
    customFilters.lng = typeof _item.city === 'object' && _item.city.lng !== null ? _item.city.lng : '';
    customFilters.country = typeof _item.country === 'object' ? (_item.country.country === null ? 'empty' : _item.country.country) : (typeof _item.country === 'string' ? _item.country : '');
    customFilters.distance = typeof _item.distance === 'string' ? _item.distance : '';
    customFilters.tags = _item.tags && _item.tags.length >= 1 ? _item.tags.join(',') : '';
    customFilters.withoutTags = typeof _item.withoutTags === 'string' ? _item.withoutTags : 'avec';


    if (_item.withProducts && _item.withProducts.length === 1) {
      customFilters.since = typeof _item.since === 'string' ? _item.since : 'less';
      customFilters.contractDate = typeof _item.contractDate === 'string' ? _item.contractDate : 'start';
      customFilters.months = parseInt(_item.months) || 0;
    }
    if (_item.gplaceId) {
      customFilters.gplaceId = _item.gplaceId
    }

    if (_item.archived) {
      customFilters.archived = _item.archived;
    }
    if (_item.withoutContacts)
      customFilters.withoutContacts = _item.withoutContacts ? _item.withoutContacts : false;
console.log(_item.stars)
    if (_item.stars) {
      customFilters.stars = _item.stars.join(',');
    }

    console.log(customFilters)
    setPage(1)
    setCustomSearch(customFilters);
  }


  const resetFilters = () => {
    setItem({ productSelection: 'ET', since: 'less', contractDate: 'start', withoutTags: 'avec' })
    setCustomSearch({ productSelection: 'ET', since: 'less', contractDate: 'start' })
    setPage(1)
    setContentVersion((new Date()).getTime());
    setDisplayEndContract(false)
  }

  return (
    <div style={{ marginBottom: '1em' }}>
      <h3>Filters</h3>
      <Row type="flex" gutter={[16, 16]}>
        <Col span={8}>
          <Form.Item label="Nom : " hasFeedback={false}>
            <FormInput
              name="name"
              value={_item.name}
              style={{ width: "300px" }}
              onChange={handlePropertyChange}
              contentVersion={contentVersion}
            />
          </Form.Item>
          <Form.Item>
            <FormCheckbox name="archived" value={_item.archived} onChange={handlePropertyChange}
              contentVersion={contentVersion}>
              Inclure les hotels archivés
            </FormCheckbox>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Ville : ">
            <FormSelect
              name="city"
              value={_item.city}
              style={{ width: "300px" }}
              allowClear
              onChange={handlePropertyChange}
              options_endpoint={'mmc_places/values'}
              option_formater={(item) => {
                return {
                  value: item.city === null ? 'empty' : item.city,
                  label: `${item.city === null ? 'Non renseigné' : item.city}  (${item.number})`
                };
              }}
              search_property="search"
              customSearch={{ name: 'city' }}
              contentVersion={contentVersion}
            />

            <FormSelect
              allowClear
              name="distance"
              value={_item.distance}
              placeHolder="Rayon"
              style={{ width: 100 }}
              onChange={handlePropertyChange}
              options={distanceValues}
              contentVersion={contentVersion}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Pays : ">
            <FormSelect
              name="country"
              value={_item.country}
              style={{ width: "300px" }}
              allowClear
              onChange={handlePropertyChange}
              options_endpoint={'mmc_places/values'}
              option_formater={(item) => {
                return {
                  value: item.country === null ? 'empty' : item.country,
                  label: `${item.country === null ? 'Non renseigné' : item.country}  (${item.number})`
                };
              }}
              search_property="search"
              customSearch={{ name: 'country' }}
              contentVersion={contentVersion}
            />
          </Form.Item>

          <Form.Item name="stars">
            <Checkbox.Group
              value={typeof _item.stars === 'string' ? [_item.stars] : _item.stars}
              onChange={values => handlePropertyChange({ stars: values })}
            >
              <Checkbox value={2}>2 étoiles</Checkbox>
              <Checkbox value={3}>3 étoiles</Checkbox>
              <Checkbox value={4}>4 étoiles</Checkbox>
              <Checkbox value={5}>5 étoiles</Checkbox>
            </Checkbox.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row type="flex" gutter={[16, 16]}>
        <Col span={8}>
          <Form.Item label="Avec les contrats : ">
            <FormSelect
              name="productSelection"
              value={_item.productSelection}
              onChange={handlePropertyChange}
              options={[{ value: 'ET', label: 'ET' }, { value: 'OU', label: 'OU' }]}
              style={{ marginRight: '1em' }}
            />
            <FormSelect
              name="contractStatus"
              placeHolder="Statut"
              style={{ width: 100 }}
              value={_item.contractStatus}
              allowClear
              onChange={handlePropertyChange}
              options={[{ value: 'true', label: 'Actif' }, { value: 'false', label: 'Inactif' }]}
              contentVersion={contentVersion}
            />
          </Form.Item>
          <FormSelect
            name="withProducts"
            value={_item.withProducts}
            style={{ width: "300px" }}
            allowClear
            onChange={handlePropertyChange}
            options_endpoint={'products'}
            option_formater={(item) => {
              return { value: item['id'], label: `${item.name} ( ${item.tag.name} )` };
            }}
            mode="multiple"
            search_property="name"
            contentVersion={contentVersion}
          />
          {
            displayEndContract ?
              <Form.Item label="Contrat qui a (démarré/fini) depuis (plus/moins) de : ">
                <FormSelect
                  name="contractDate"
                  value={_item.contractDate}
                  placeHolder="Qui a"
                  onChange={handlePropertyChange}
                  options={[{ value: 'start', label: 'Démarré' }, { value: 'end', label: 'Fini' }]}
                  style={{ marginRight: "1em", width: 100 }}
                />
                <FormSelect
                  name="since"
                  value={_item.since}
                  placeHolder="Depuis"
                  onChange={handlePropertyChange}
                  options={[{ value: 'less', label: 'Moins de' }, { value: 'more', label: 'Plus de' }]}
                  style={{ marginRight: "1em", width: 100 }}
                />
                <FormInput
                  name="months"
                  value={_item.months}
                  style={{ width: "100px" }}
                  onChange={handlePropertyChange}
                  contentVersion={contentVersion}
                />
              </Form.Item>
              : null
          }
        </Col>
        <Col span={8}>
          <Form.Item label="Sans les contrats : ">
            <FormSelect
              name="withoutProducts"
              value={_item.withoutProducts}
              style={{ width: "300px" }}
              allowClear
              onChange={handlePropertyChange}
              options_endpoint={'products'}
              option_formater={(item) => {
                return { value: item['id'], label: `${item.name} ( ${item.tag.name} )` };
              }}
              mode="multiple"
              search_property="name"
              contentVersion={contentVersion}
            />
          </Form.Item>
          <Form.Item label="Website : " hasFeedback={false}>
            <FormInput
              name="website"
              value={_item.website}
              style={{ width: "300px" }}
              onChange={handlePropertyChange}
              contentVersion={contentVersion}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Tags : ">
          <FormSelect
              name="withoutTags"
              placeHolder="Avec"
              value={_item.withoutTags}
              onChange={handlePropertyChange}
              options={[{ value: 'avec', label: 'Avec' }, { value: 'sans', label: 'Sans' }]}
              style={{ marginRight: '1em', width: 100 }}
            />
            <FormSelect
              name="tags"
              value={_item.tags}
              style={{ width: "300px" }}
              mode="multiple"
              allowClear
              onChange={handlePropertyChange}
              options_endpoint={'tags'}
              option_formater={(item) => {
                return { value: item.id, label: `${item.name}` };
              }}
              search_property="name"
              customSearch={{ type: 1 }}
              contentVersion={contentVersion}
            />
          </Form.Item>
          <Form.Item label="Géolocalisé">
            <FormSelect
              name="gplaceId"
              allowClear
              placeHolder="Avec google place ?"
              value={_item.gplaceId}
              onChange={handlePropertyChange}
              options={[{ value: 'true', label: 'Oui' }, { value: 'false', label: 'Non' }]}
              style={{ marginRight: '1em', width: "300px" }}
              contentVersion={contentVersion}
            />
          </Form.Item>
          <Form.Item>
            <FormCheckbox name="withoutContacts" value={_item.withoutContacts} onChange={handlePropertyChange}
              contentVersion={contentVersion}>
              Afficher les hôtels qui n'ont pas de contacts
            </FormCheckbox>
          </Form.Item>
        </Col>
      </Row>
      <Row type="flex" gutter={[16, 16]}>
        <Col span={24}>
          <Button style={{ marginRight: '1em' }} onClick={resetFilters}>Reset</Button>
          <Button type="primary" onClick={handleFilter}>Filter</Button>
        </Col>
      </Row>

    </div>
  )
}

import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useAuthContext } from '../providers/AuthProvider.js';

import Layout from './Layout.js';

const PrivateRoute = ({component: Component, layout, ...rest}) => {

  const [authState, authDispatch] = useAuthContext();
  const { auth } = authState;
  const { authIsLogged } = authDispatch;

  const renderer = (layout,props) => {
    return authIsLogged()
    ? <Layout layout={layout} private={true} {...props}><Component {...props} /></Layout>
    : <Redirect to="/login" />
  }

  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route {...rest} render={renderer.bind(null,layout)} />
  );
};

export default PrivateRoute;

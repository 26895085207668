import React, {useEffect, useState} from 'react'
import {Card, Breadcrumb, Pagination, Button} from 'antd';
import {Link} from 'react-router-dom';

import {useApiContext} from '../providers/ApiProvider.js';
import DisplayPlaces from '../components/Compare/DisplayPlaces.js';
import {Reload} from "../components/Compare/Reload";


export function Compare() {

  const [fullList, setFullList] = useState([])
  const [filetime, setFileTime] = useState("")
  const [displayList, setDisplayList] = useState([])
  const [apiState, apiDispatch] = useApiContext();
  const {apiFetchCollection} = apiDispatch;
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0
  })

  useEffect(() => {
    fetchDoubleFile()
  }, [])

  useEffect(() => {
    if (fullList.length) {
      let end = pagination.current * 5
      setDisplayList(fullList.slice(end - 5, end))
    }

  }, [pagination, fullList])

  const fetchDoubleFile = async () => {
    const place_response = await apiFetchCollection('placeNameChecking', {noExtension: true});
    if (place_response['@type'] === "hydra:Error") {
      console.log('error')
    }

    hydrateVars(place_response)
  }

  function hydrateVars(place_response) {
    setPagination({
      current: 1,
      total: place_response.data.length
    })
    setFullList(place_response.data)
    setFileTime(place_response.filetime)
  }

  let cardTitle = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Link to="/">Home</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        Comparaison
      </Breadcrumb.Item>
    </Breadcrumb>
  );

  const onChange = page => {
    console.log(page);
    setPagination({...pagination, current: page})
  };

  return (
    <Card title={cardTitle}>
      <div style={{display: "flex", justifyContent: "space-between"}}>
        <Pagination current={pagination.current} onChange={onChange} total={pagination.total} pageSize={5}/>
        <Reload filetime={filetime} total={pagination.total} hydrateVars={hydrateVars}/>
      </div>
      {
        displayList.map((item, index) =>
          <DisplayPlaces key={item[0]} customSearch={{ids: item.join(',')}}/>
        )
      }
      <Pagination current={pagination.current} onChange={onChange} total={pagination.total} pageSize={5}/>
    </Card>
  );
};

import React, { useEffect, useState } from 'react'
import { Card, Row, Col, List, Input, Form, Button, Spin, message, Rate, InputNumber, Modal } from 'antd'
import { cleanUrl } from '../_helpers.js';
import FaIcon from '../FaIcon.js';
import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch'
import FormInput from '../Form/FormInput.js'
import FormCountry from '../Form/FormCountry.js'
import FormGps from '../Form/FormGps.js'
import { useApiContext } from '../../providers/ApiProvider.js';
import { faExternalLink, faTrash, faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { faStar } from "@fortawesome/pro-solid-svg-icons/faStar";

import { Link } from 'react-router-dom';

export default function PlaceGeoloc({ place, removePlace, setLoading }) {

    const [apiState, apiDispatch] = useApiContext();
    const { apiGooglePlaceAutocomplete, apiGooglePlaceDetail, apiUpdateEntity, apiDeleteEntity } = apiDispatch;
    const [placeRefreshing, setPlaceRefreshing] = useState(false)
    const [gPlcaceLoadiong, setGPlaceLoading] = useState(false)
    const [placeSearchPropositions, setPlaceSearchPropositions] = useState([])
    const [placeSearchTimeout, setPlaceSearchTimeout] = useState(null)
    const [selectedGPlace, setSelectedGPlace] = useState(null)

    useEffect(() => {
        setSelectedGPlace(null)
        handlePlaceSearch(place.name)
    }, [place])

    function handlePlaceSearch(q) {
        // console.log(q)
        setPlaceSearchPropositions([])
        clearTimeout(placeSearchTimeout);
        setPlaceSearchTimeout(setTimeout(async () => {
            setGPlaceLoading(true)
            const response = await apiGooglePlaceAutocomplete(q);
            // console.log(response)
            if (response && response.status == "OK") {
                const predictions = response.predictions.map(function (v, i) {
                    return {
                        value: v.place_id,
                        text: v.description
                    };
                })
                //console.log(predictions);
                setPlaceSearchPropositions(predictions)
            }
            setGPlaceLoading(false)

        }, 500));
    }

    async function handlePlaceIdChange(id) {
        if (id !== selectedGPlace?.id) {
            setPlaceRefreshing(true)
            setSelectedGPlace(null)
            let place = await apiGooglePlaceDetail(id);
            // console.log(place)
            if (place && place.status === "OK") {
                let result = place.result
                result.gPlaceId = id
                result.stars = null
                result.rooms = null
                setSelectedGPlace(result)
            }

            setPlaceRefreshing(false)

        }
    }

    async function handlePropertyChange(chunk) { // {property}
        // console.log(chunk);
        setSelectedGPlace({ ...selectedGPlace, ...chunk });
    }


    async function update() {
        console.log(selectedGPlace)
        let newValues = {}
        newValues.name = typeof selectedGPlace.name === "string" ? selectedGPlace.name : '';
        newValues.phone = typeof selectedGPlace.phone === "string" ? selectedGPlace.phone : '';
        newValues.address = typeof selectedGPlace.address === "string" ? selectedGPlace.address : '';
        newValues.city = typeof selectedGPlace.city === "string" ? selectedGPlace.city : '';
        newValues.zip = typeof selectedGPlace.zip === "string" ? selectedGPlace.zip : '';
        newValues.country = typeof selectedGPlace.country === "string" ? selectedGPlace.country : '';
        newValues.countryCode = typeof selectedGPlace.countryCode === "string" ? selectedGPlace.countryCode : '';
        newValues.website = typeof selectedGPlace.website === "string" ? selectedGPlace.website : '';
        newValues.gps = selectedGPlace.gps ? selectedGPlace.gps : null;
        newValues.openingHours = selectedGPlace.openingHours ? selectedGPlace.openingHours : null;
        newValues.rating = selectedGPlace.rating ? selectedGPlace.rating : null;
        newValues.userRatingsTotal = selectedGPlace.userRatingsTotal ? selectedGPlace.userRatingsTotal : null;
        newValues.types = selectedGPlace.types ? selectedGPlace.types : null;
        newValues.lat = selectedGPlace.gps ? selectedGPlace.gps[0] : null;
        newValues.lng = selectedGPlace.gps ? selectedGPlace.gps[1] : null;
        newValues.gPlaceId = selectedGPlace.gPlaceId ? selectedGPlace.gPlaceId : null;

        if (typeof selectedGPlace.email === "string") newValues.email =  selectedGPlace.email
        if (typeof selectedGPlace.rooms === "number") newValues.rooms =  selectedGPlace.rooms
        if (typeof selectedGPlace.stars === "number") newValues.stars =  selectedGPlace.stars

        // console.log(newValues)
        setLoading(true)
        const response = await apiUpdateEntity('mmc_places', place.id, newValues)
        if (response?.id) {
            message.success('Mise à jour effectué')
            removePlace(place)
        }
        // console.log(response)
        setLoading(false)
    }

    const confirmDelete = () => {
        Modal.confirm({
            title: `Confirmer la suppression de ${place.name}?`,
            icon: <FaIcon icon={faExclamationTriangle} />,
            okText: 'Oui',
            okType: 'danger',
            cancelText: 'Non',
            onOk() {
                deleteElement();
            },
            onCancel() {
            },
        });
    }
    const deleteElement = async () => {
        setLoading(true)

        let response = await apiDeleteEntity('mmc_places', place.id);
        if (response['@type'] === "hydra:Error") {
            message.error('Impossible de suprimmer cet élément')
            return
        }
        message.success('Elément supprimé avec succes')
        removePlace(place)
        setLoading(false)

    }

    return (
        <div tab={place.name} key={place.id}>
            <Row gutter={[16, 16]}>
                <Col span={12} >
                    <Card title={place.name} extra={[
                        <Link key={'link'} to={`/place/${place.id}`} target="_blank" ><FaIcon icon={faExternalLink} /></Link>,
                        <FaIcon key={'delete'} style={{color: "#d9363e", marginLeft: '1em'}} icon={faTrash} onClick={() => confirmDelete()} />
                    ]} >
                        <p>Site: {place?.website && <a target="_blank" href={cleanUrl(place.website)}>{place.website}</a>}</p>
                        <p>Adresse: {place.address && `${place.address}, `}
                            {place.city && `${place.city}, `}
                            {place.zip && `${place.zip}, `}
                            {place.country && `${place.country}`}
                        </p>
                        <p>Téléphone: {place.phone && `${place.phone}`}</p>
                        <p>Société: {place?.account?.name}</p>
                    </Card>

                    <Card title="Recherche sur google place" >
                        <Input suffix={<FaIcon icon={faSearch} />} onChange={(e) => handlePlaceSearch(e.target.value)} defaultValue={place.name} />
                        <Spin spinning={gPlcaceLoadiong} >
                            {
                                !!placeSearchPropositions.length &&
                                <List
                                    bordered
                                    dataSource={placeSearchPropositions}
                                    renderItem={item => (
                                        <List.Item key={item.value} onClick={() => handlePlaceIdChange(item.value)} className={`pointer ${item.value === selectedGPlace?.gPlaceId ? 'selected' : ''}`}>
                                            {item.text}
                                        </List.Item>
                                    )}
                                />
                            }
                        </Spin>
                    </Card>
                </Col>
                <Col span={12} >
                    <Spin spinning={placeRefreshing} >
                        {
                            selectedGPlace &&
                            <Card title={`Google place : ${selectedGPlace.name}`} >
                                <Form layout="vertical">
                                    <Form.Item>
                                        <FormInput placeholder="" name="gPlaceId" value={selectedGPlace.gPlaceId} onChange={handlePlaceIdChange} />
                                    </Form.Item>

                                    <Form.Item label="Name">
                                        <FormInput placeholder="" name="name" value={selectedGPlace.name} onChange={handlePropertyChange} />
                                    </Form.Item>
                                    <Form.Item label="Website" extra={selectedGPlace.website ? <a target="_blank" href={cleanUrl(selectedGPlace.website)}> <FaIcon icon={faExternalLink} /></a> : null}>
                                        <FormInput placeholder="" name="website" value={selectedGPlace.website} onChange={handlePropertyChange} />
                                    </Form.Item>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Form.Item label="Phone">
                                                <FormInput placeholder="" name="phone" value={selectedGPlace.phone} onChange={handlePropertyChange} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="Email">
                                                <FormInput placeholder="" name="email" value={selectedGPlace.email} onChange={handlePropertyChange} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col span={16}>
                                            <Form.Item label="Address">
                                                <FormInput placeholder="" name="address" value={selectedGPlace.address} onChange={handlePropertyChange} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item label="Zipcode">
                                                <FormInput placeholder="" name="zip" value={selectedGPlace.zip} onChange={handlePropertyChange} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Form.Item label="City">
                                                <FormInput placeholder="" name="city" value={selectedGPlace.city} onChange={handlePropertyChange} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="Country">
                                                <FormCountry placeholder="" name="countryCode" fullname="country" value={selectedGPlace.countryCode}
                                                    onChange={handlePropertyChange} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Form.Item label={<React.Fragment>GPS <small>[latitude, longitude]</small></React.Fragment>}>
                                        <FormGps placeholder="" name="gps" value={selectedGPlace.gps} onChange={handlePropertyChange} />
                                    </Form.Item>
                                    <Form.Item label="Nombre d'étoiles">
                                        <Rate value={selectedGPlace.stars} name="stars"
                                            character={<FaIcon icon={faStar} style={{ width: 10 }} />}
                                            style={{ fontSize: 15 }} onChange={(value) => handlePropertyChange({ stars: value > 0 ? value : null })} />
                                    </Form.Item>

                                    <Form.Item label="Nombre de chambres">
                                        <InputNumber name="rooms" min={0} onChange={(value) => handlePropertyChange({ rooms: value })} value={selectedGPlace.rooms} />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button key="cancel" onClick={() => setSelectedGPlace(null)}>
                                            Annuler
                                        </Button>
                                        <Button style={{ marginLeft: 8 }} key="save" type="primary" onClick={update}>
                                            Modifier
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Card>
                        }
                    </Spin>
                </Col>
            </Row>
        </div>
    )
}
import React, {useEffect, useState} from "react";
import {useApiContext} from "../../providers/ApiProvider";
import {useParams} from "react-router-dom";
import {Button, message, Select} from "antd";

export default function AfterAction({docLink, docId, contactProposition, setContactProposition, emails, setEmails}) {

  const [apiState, apiDispatch] = useApiContext();
  const {apiPostRequest, apiFetchCollection} = apiDispatch;
  const params = useParams();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetch();
  }, [])

  async function fetch() {
    console.log("in")
    const response = await apiFetchCollection("mmc_contacts", {
      noExtension: false,
      search: {"account.id": params.account_id, "exists[sellsyId]": "true"}
    })

    addInProposition(response["hydra:member"])
  }

  function addInProposition(contacts) {
    let adds = [];

    contacts.map(contact => {
      if (!contactProposition.some(pcprop => pcprop.email === contact.email)) {
        adds.push({
          id: contact.id,
          firstname: contact.firstname,
          lastname: contact.lastname,
          email: contact.email
        })
      }
    })

    setContactProposition(contactProposition => [...contactProposition, ...adds]);
  }

  function handleChange(value) {
    setEmails(value)
  }

  async function send() {
    if (!emails.length)
      message.error("Selectionnez une adresse mail")

    setLoading(true);

    const data = {
      docid: docId,
      emails: emails
    }

    const response = await apiPostRequest("sellsy/send-estimate", data)

    if (response.success)
      message.success("Email envoyé")
    else
      message.error("Erreur lors de l'envoi, verifier les informations")

    setLoading(false);
  }

  return (
    <div style={{marginTop: 30}}>
      <div>
        <a href={docLink} target="_blank">Télécharger le document</a>
      </div>

      <div>
        <h3>Ou</h3>
      </div>

      <div>
        <Select
          mode="tags"
          style={{width: 600}}
          placeholder="Entrez une ou plusieur(s) adresse(s)"
          onChange={handleChange}
          value={emails}
        >
          {contactProposition.map((contact, index) => (
            <Select.Option key={index} value={contact.email}>{contact.email}</Select.Option>
          ))}
        </Select>
        <Button
          type="primary"
          onClick={send}
          loading={loading}
        >
          Envoyer par email
        </Button>
      </div>
    </div>
  )
}

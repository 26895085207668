import React from 'react';
import {Link, Redirect} from 'react-router-dom'
import {Breadcrumb, Card, Col, Row} from 'antd'
import LogDash from "../components/Log/LogDash";
import CommentDash from "../components/Comment/CommentDash";
import {useAuthContext} from "../providers/AuthProvider";

const Home = (props) => {

  const [authState, authDispatch] = useAuthContext();
  const {auth} = authState;

  let cardTitle = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Link to="/">Home</Link>
      </Breadcrumb.Item>
    </Breadcrumb>
  );

  if (!auth)
    return <Redirect to={"/login"} />

  return (
    <Card title={cardTitle}>
      <Row type="flex" gutter={16}>
        <Col span={12}>
          <CommentDash cardTitle="Mes commentaires" customSearch={{'targetUsers.id': auth?.id}}/>
        </Col>
        <Col span={12}>
          <LogDash/>
        </Col>
        <Col span={12}>
          <CommentDash cardTitle="Alertes" customSearch={{'exists[alertDate]': true, 'exists[alertEndDate]': false}}/>
        </Col>
      </Row>
    </Card>
  );
};

export default Home;

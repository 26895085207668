import React, {useState, useEffect} from 'react';
import {Row, Col, message, Modal, Form, Button, AutoComplete, Input, Rate, InputNumber} from 'antd'

import FaIcon from '../FaIcon.js';
import {faSpinner} from '@fortawesome/pro-solid-svg-icons/faSpinner'
import {faSync} from '@fortawesome/pro-solid-svg-icons/faSync'
import {faSearch} from '@fortawesome/pro-solid-svg-icons/faSearch'

import {useApiContext} from '../../providers/ApiProvider.js';

import FormInput from '../Form/FormInput.js'
import FormSelect from '../Form/FormSelect.js'
import FormCountry from '../Form/FormCountry.js'
import FormGps from '../Form/FormGps.js'

import {faStar} from "@fortawesome/pro-solid-svg-icons/faStar";

const PlaceForm = ({item, item_id, account_id, onClose, onChange, modal, ...rest}) => {

  item_id = item ? item.id : item_id;
  onClose = onClose || function () {
  };
  onChange = onChange || function () {
  };

  const [apiState, apiDispatch] = useApiContext();
  const {
    apiFetchEntity,
    apiUpdateEntity,
    apiCreateEntity,
    apiGooglePlaceDetail,
    apiGooglePlaceAutocomplete
  } = apiDispatch;

  const [_itemId, setItemId] = useState(item_id)
  const [_item, setItem] = useState(item ? {...item} : null)
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [placeRefreshing, setPlaceRefreshing] = useState(false)
  const [placeSearchPropositions, setPlaceSearchPropositions] = useState([])

  const [placeSearchTimeout, setPlaceSearchTimeout] = useState(null)

  useEffect(() => {
    // fetch at startup and whenever needUpdate is changed
    if (_itemId && !_item)
      fetchItem();
    else
      setLoading(false);

    return () => {
      clearTimeout(placeSearchTimeout);
    }
  }, [])

  function handlePlaceSearch(q) {
    console.log(q)
    setPlaceSearchPropositions([])
    clearTimeout(placeSearchTimeout);
    setPlaceSearchTimeout(setTimeout(async () => {
      const response = await apiGooglePlaceAutocomplete(q);
      console.log(response)
      if (response && response.status == "OK") {
        const predictions = response.predictions.map(function (v, i) {
          return {
            value: v.place_id,
            text: v.description
          };
        })
        //console.log(predictions);
        setPlaceSearchPropositions(predictions)
      }
    }, 500));
  }

  async function fetchItem() {
    console.log('here')

    setLoading(true);
    if (_itemId === 'new') {
      if (account_id)
        setItem({
          account: {
            '@id': '/api/mmc_accounts/' + account_id,
            'id': account_id
          }
        })
      else
        setItem({})
    } else {
      console.log(_itemId)
      const response = await apiFetchEntity('mmc_places', _itemId);
      setItem({...response})
    }
    setLoading(false);
    return;
  }

  async function handlePropertyChange(chunk) { // {property:value}
    console.log(chunk);
    setItem(Object.assign(_item, chunk));
  }

  async function handlePlaceIdChange(chunk, force = false) { // {property:value}
    let placeChanged = chunk.gPlaceId !== _item.gPlaceId;
    if (force || placeChanged) {
      if (chunk.gPlaceId) {
        setPlaceRefreshing(true)
        let place = await apiGooglePlaceDetail(chunk.gPlaceId);
        if (place && place.status === "OK") {
          chunk.name = place.result.name ? place.result.name : '';
          chunk.gps = place.result.gps ? place.result.gps : null;
          chunk.phone = place.result.phone ? place.result.phone : '';
          chunk.address = place.result.address ? place.result.address : null;
          chunk.city = place.result.city ? place.result.city : null;
          chunk.zip = place.result.zip ? place.result.zip : null;
          chunk.country = place.result.country ? place.result.country : null;
          chunk.countryCode = place.result.countryCode ? place.result.countryCode : null;
          chunk.openingHours = place.result.openingHours ? place.result.openingHours : null;
          chunk.rating = place.result.rating ? place.result.rating : null;
          chunk.userRatingsTotal = place.result.userRatingsTotal ? place.result.userRatingsTotal : null;
          chunk.website = place.result.website ? place.result.website : null;
          chunk.types = place.result.types ? place.result.types : null;
          chunk.lat = place.result.gps ? place.result.gps[0] : null;
          chunk.lng = place.result.gps ? place.result.gps[1] : null;
        }
      } else {
        chunk.openingHours = null;
        chunk.rating = null;
        chunk.userRatingsTotal = null;
        chunk.types = null;
      }
      setItem(Object.assign(_item, chunk));
    }
    setPlaceRefreshing(false);
  }

  const handleCancel = () => {
    onClose();
  }

  const handleSave = async () => {
    setSaving(true);

    let newValues = {}
    newValues.name = typeof _item.name === "string" ? _item.name : '';
    newValues.phone = typeof _item.phone === "string" ? _item.phone : '';
    newValues.address = typeof _item.address === "string" ? _item.address : '';
    newValues.city = typeof _item.city === "string" ? _item.city : '';
    newValues.zip = typeof _item.zip === "string" ? _item.zip : '';
    newValues.country = typeof _item.country === "string" ? _item.country : '';
    newValues.countryCode = typeof _item.countryCode === "string" ? _item.countryCode : '';
    newValues.website = typeof _item.website === "string" ? _item.website : '';
    newValues.rooms = typeof _item.rooms === "number" ? _item.rooms : null;


    // return newValues  
    if (_itemId === 'new') {
      const response = await apiCreateEntity('mmc_places', {..._item, ...newValues});
      console.log('response', response)
      if (response && response['@type'] === 'hydra:Error') {
        console.log('error', response)
        message.error(response['hydra:description'])
      }
      if (response && response.id) {
        setItemId({...response.id})
        setItem({...response})
      }

    } else {
      newValues.account = typeof _item.account === 'object' ? _item.account : null
      await apiUpdateEntity('mmc_places', _itemId, {..._item, ...newValues});
    }
    setSaving(false);
    onChange(Object.assign({}, _item));
  }

  const handleSaveAndClose = async () => {
    await handleSave();
    onClose();
  }

  function handleStarsChange(stars) {
    _item.stars = stars > 0 ? stars : null
    setItem({..._item})
  }

  const formContent = (
    !_item || loading || saving || placeRefreshing
      ? <React.Fragment><FaIcon icon={faSpinner} spin/> traitement en cours...</React.Fragment>
      : <Form layout="vertical">

        <Form.Item
          style={{background: '#eee', padding: '0.5em'}}
          label={
            <Row gutter={16}>
              <Col span={8}>
                <strong>Place ID <a key="refresh"
                                    onClick={event => handlePlaceIdChange({gPlaceId: _item.gPlaceId}, true)}>{placeRefreshing ?
                  <FaIcon icon={faSpinner} spin/> : <FaIcon icon={faSync}/>}</a></strong>
              </Col>
              <Col span={16}>
                <AutoComplete
                  onSelect={(value) => handlePlaceIdChange({gPlaceId: value}, true)}
                  onSearch={handlePlaceSearch}
                  dataSource={placeSearchPropositions}
                  dropdownMatchSelectWidth={false}
                >
                  <Input suffix={<FaIcon icon={faSearch}/>}/>
                </AutoComplete>
              </Col>
            </Row>
          }
        >
          <FormInput placeholder="" name="gPlaceId" value={_item.gPlaceId} onChange={handlePlaceIdChange}/>
        </Form.Item>

        <Form.Item label="Name">
          <FormInput placeholder="" name="name" value={_item.name} onChange={handlePropertyChange}/>
        </Form.Item>
        <Form.Item label="Website">
          <FormInput placeholder="" name="website" value={_item.website} onChange={handlePropertyChange}/>
        </Form.Item>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Phone">
              <FormInput placeholder="" name="phone" value={_item.phone} onChange={handlePropertyChange}/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Email">
              <FormInput placeholder="" name="email" value={_item.email} onChange={handlePropertyChange}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={16}>
            <Form.Item label="Address">
              <FormInput placeholder="" name="address" value={_item.address} onChange={handlePropertyChange}/>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Zipcode">
              <FormInput placeholder="" name="zip" value={_item.zip} onChange={handlePropertyChange}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="City">
              <FormInput placeholder="" name="city" value={_item.city} onChange={handlePropertyChange}/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Country">
              <FormCountry placeholder="" name="countryCode" fullname="country" value={_item.countryCode}
                           onChange={handlePropertyChange}/>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label={<React.Fragment>GPS <small>[latitude, longitude]</small></React.Fragment>}>
          <FormGps placeholder="" name="gps" value={_item.gps} onChange={handlePropertyChange}/>
        </Form.Item>

        <Form.Item
          name="stars"
          label="Nombre d'étoiles"
        >
          <Rate value={_item.stars}
                character={<FaIcon icon={faStar} style={{width: 10}}/>}
                style={{fontSize: 15}} onChange={handleStarsChange}/>
        </Form.Item>

        <Form.Item
          name="rooms"
          label="Nombre de chambres"
        >
          <InputNumber min={0} onChange={(value) => handlePropertyChange({rooms: value})} value={_item.rooms}/>
        </Form.Item>

        {
          _itemId !== 'new' ?
            <Form.Item label="Société">
              <FormSelect
                name="account"
                value={_item.account}
                onChange={handlePropertyChange}
                options_endpoint={"mmc_accounts"}
                search_property="name"
                option_formater={(item) => {
                  return {value: item['@id'], label: item.name};
                }}
                allowClear
              />
            </Form.Item>
            : null
        }


        <Form.Item>
          <Button key="cancel" onClick={handleCancel}>
            Close
          </Button>
          <Button style={{marginLeft: 8}} key="save" type="primary" onClick={handleSave}>
            Save
          </Button>
          <Button style={{marginLeft: 8}} key="saveAndClose" type="primary" onClick={handleSaveAndClose}>
            Save & Close
          </Button>
        </Form.Item>

      </Form>
  );

  if (modal) {
    return <Modal
      title={_item && _item.name ? _item.name : "Création d'un nouvel hotel"}
      visible={true}
      onCancel={handleCancel}
      footer={null}
    >
      {formContent}
    </Modal>
  } else {
    return formContent;
  }
};

export default PlaceForm;

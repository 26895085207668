import React, {useState} from 'react';
import {Redirect} from 'react-router-dom'

import {useAuthContext} from '../providers/AuthProvider.js';
import {Button, Card, Checkbox, Form, Input, message, Spin} from "antd";

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};


const Login = ({form}) => {

  const {getFieldDecorator} = form;
  const [authState, authDispatch] = useAuthContext();
  const {auth} = authState;
  const {authLogin, authIsLogged} = authDispatch;

  const [processing, setProcessing] = useState(false);


  function doLogin(event) {
    event.preventDefault();

    form.validateFields(async (err, values) => {
      if (!err) {
        setProcessing(true);
        let response = await authLogin(values.email.trim(), values.password.trim());
        if (response?.error) {
          message.error('Connexion impossible')
          setProcessing(false)
        }
      }
    });
  }

  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
        padding: '0 84px',
        height: '100%',
        display: "flex",
        minHeight: '100vh'
      }}>

      {
        authIsLogged()
          ? <Redirect to="/"/>
          : (

            <Card className="card-style" style={{width: '38%', padding: '55px 2em 0', margin: 0, maxWidth: '550px'}}>
              <Spin spinning={processing}>

                <h2 style={{textAlign: 'center'}}>Connexion</h2>
                <Form
                  onSubmit={doLogin}
                  style={{
                    margin: '1em auto',
                    minWidth: "50%",
                    padding: "1em",
                  }}
                >
                  <Form.Item
                    label="Email"
                  >
                    {getFieldDecorator('email',
                      {
                        rules: [{required: true, message: 'L\'email est obligatoire', whitespace: true}],
                      },
                    )
                    (<Input/>)}
                  </Form.Item>
                  <Form.Item
                    label="Password"
                  >
                    {getFieldDecorator('password',
                      {
                        rules: [{required: true, message: 'Le mot de passe est obligatoire', whitespace: true}],
                      },
                    )
                    (<Input.Password/>)}
                  </Form.Item>

                  <Form.Item name="remember">
                    <Checkbox>Remember me</Checkbox>
                  </Form.Item>

                  <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit" loading={processing}>
                      Connexion
                    </Button>
                  </Form.Item>
                </Form>
              </Spin>

            </Card>
          )
      }
    </div>
  );
};

export default Form.create()(Login);

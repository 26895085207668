import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import { Tag, Button } from 'antd';

import moment from 'moment';

import ApiCollectionLatest from '../ApiCollectionLatest.js';

import ContractForm from './ContractForm.js'
import ContractDrawer from './ContractDrawer.js';

import { activesTagResolver, subscriptionResume, benefitsResume } from './_helpers.js'
import { productResolver } from './_products.js'

const ContractDash = ({place_id, ...rest}) => {

  const [selectedItem, setSelectedItem ] = useState(null)
  const [creationMode, setCreationMode ] = useState(false)

  const [contentVersion, setContentVersion ] = useState(0)

  const handleCreationFormClose = () => {
    setCreationMode(false);
  }
  const handleCreationFormChange = () => {
  }

  const handleEditFormClose = () => {
    setSelectedItem(null);
  }
  const handleEditFormChange = () => {
    setContentVersion((new Date()).getTime());
  }

  const columns = [
    {
      title: '',
      dataIndex: 'product',
      search: true,
      sorter: true,
      render: (product,record) => <React.Fragment>
        {product.name}
        <br />
        <small>{product.tag.name}</small>
      </React.Fragment>
    },
    {
      title: '',
      dataIndex: 'subscription',
      align:'right',
      search: true,
      sorter: true,
      render: (_null,record) => <small>
        {subscriptionResume(record)}
        <br/>{benefitsResume(record)}
      </small>
    },
  ];

  if(creationMode)
    return <ContractForm modal item_id="new" place_id={place_id} onClose={handleCreationFormClose} onChange={handleCreationFormChange}/>;

  return (
    <React.Fragment>
      <ApiCollectionLatest
        title={(currentPageData) => <React.Fragment>
          {
            place_id ? <Button size="small" onClick={()=>{setCreationMode(true)}}>+</Button> : null
          }

          <b> Contracts</b>
          <Link style={{float:"right"}} to={"/place/"+place_id+"/contracts"}>See all</Link>
        </React.Fragment>}
        fetch_endpoint={place_id ? `mmc_places/${place_id}/contracts`:"mmc_contracts"}
        delete_endpoint="mmc_contracts"
        columns={columns}
        pageSize={5}
        onClick={(item) => setSelectedItem({...item}) }
        contentVersion={contentVersion}
        {...rest}
        />
        {selectedItem ? <ContractDrawer item={selectedItem} onClose={handleEditFormClose} onChange={handleEditFormChange} /> : null}
    </React.Fragment>
  );
};

export default ContractDash;

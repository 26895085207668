import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Switch } from 'antd'

import FaIcon from '../FaIcon.js';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons/faExclamationCircle'
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons/faExclamationTriangle'
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck'

import { useApiContext } from '../../providers/ApiProvider.js';

import Countdown from '../../components/Countdown.js';

const FormSwitch = ({onChange, name, value, ...rest}) => {
  const originalValue = (value || false)
  const [internalValue, setInternalValue ] = useState(originalValue)
  const [saveNeeded, setSaveNeeded ] = useState(false)

  const handleInputChange = (value) => {
    let chunk = {};
    let newValue = value;
    chunk[name] = newValue
    onChange(chunk);

    setInternalValue(newValue);
    setSaveNeeded(newValue!==originalValue);
  }


    return <Switch style={{flex:1}} {...rest} defaultChecked={internalValue} onChange={handleInputChange} />;
 
};

export default FormSwitch;

import React, {useState} from 'react'

import {useApiContext} from '../../providers/ApiProvider.js';
import FormSelect from '../Form/FormSelect.js'
import FormInput from '../Form/FormInput.js'

import {Button, Col, Form, Row} from 'antd'

export default function CommentFilters({customSearch, setCustomSearch}) {

  const [apiState, apiDispatch] = useApiContext();
  const [_item, setItem] = useState(customSearch)
  const [contentVersion, setContentVersion] = useState(0)

  async function handlePropertyChange(chunk) { // {property:value}
    console.log('ck', chunk)
    setItem(Object.assign(_item, chunk));
    if (chunk.hasOwnProperty('relation_name')) {
      handleFilter()
    }
  }


  const handleFilter = () => {
    console.log('filter', _item)
    let customFilters = {}

    if (typeof _item.author === 'object')
      customFilters.author = _item.author.id;

    if (typeof _item.author === 'string')
      customFilters.author = _item.author;

    customFilters.isOnAlert = typeof _item.isOnAlert === 'string' ? _item.isOnAlert : '';
    customFilters.relation_name = typeof _item.relation_name === 'string' ? _item.relation_name : '';

    if (typeof _item['targetUsers.id'] === 'object')
      customFilters['targetUsers.id'] = _item['targetUsers.id'].id;


    console.log('custo', customFilters)
    setCustomSearch(customFilters);

  }


  const resetFilters = () => {
    setItem({})
    setCustomSearch({})
    setContentVersion((new Date()).getTime());
  }

  return (
    <div style={{marginBottom: '1em'}}>
      <h3>Filters</h3>
      <Row type="flex" gutter={[16, 16]}>
        <Col span={6}>
          <Form.Item label="Nom de l'entité : " hasFeedback={false}>
            <FormInput
              name="relation_name"
              value={_item.name}
              style={{width: "300px"}}
              onChange={handlePropertyChange}
              contentVersion={contentVersion}
              allowClear
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Auteur : ">
            <FormSelect
              name="author"
              value={_item.author}
              onChange={handlePropertyChange}
              style={{marginRight: '1em', width: "300px"}}
              contentVersion={contentVersion}
              options_endpoint={'users'}
              search_property="username"
              allowClear
              option_formater={(item) => {
                return {value: item['id'], label: `${item.username}`};
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Personne : ">
            <FormSelect
              name="targetUsers.id"
              value={_item['targetUsers.id']}
              onChange={handlePropertyChange}
              style={{marginRight: '1em', width: "300px"}}
              contentVersion={contentVersion}
              options_endpoint={'users'}
              search_property="username"
              allowClear
              option_formater={(item) => {
                return {value: item['id'], label: `${item.username}`};
              }}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="En alerte : ">
            <FormSelect
              name="isOnAlert"
              value={_item.isOnAlert}
              placeHolder="Alerte"
              onChange={handlePropertyChange}
              options={[{value: 'true', label: 'Oui'}, {value: 'false', label: 'Non'}]}
              style={{marginRight: '1em', width:'100px'}}
              allowClear
            />
          </Form.Item>
        </Col>
      </Row>
      <Row type="flex" gutter={[16, 16]}>
        <Col span={24}>
          <Button style={{marginRight: '1em'}} onClick={resetFilters}>Reset</Button>
          <Button type="primary" onClick={handleFilter}>Filter</Button>
        </Col>
      </Row>

    </div>
  )
}

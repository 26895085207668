import React, { useEffect, useState } from 'react';
import { Breadcrumb, Card, Col, Row, Spin } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { useApiContext } from '../providers/ApiProvider';
import CategoryMenu from '../components/Support/Admin/CategoryMenu';
import ArticleList from '../components/Support/Admin/ArticleList';

export default function DocProduit() {

    const [apiState, apiDispatch] = useApiContext();
    const { apiFetchEntity } = apiDispatch;
    const params = useParams();

    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(false);
    const [breadcrumb, setBreadcrumb] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);

    useEffect(() => {
        fetch();
    }, [])

    async function fetch() {
        setLoading(true);
        const response = await apiFetchEntity('support_products', params.product);
        setBreadcrumb(buildBreadcrumb(response))
        console.log(response)
        setProduct(response);
        setLoading(false);
    }

    const buildBreadcrumb = (item) => (
        <Breadcrumb>
            <Breadcrumb.Item>
                <Link to="/">Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                <Link to="/documentation/">Documentation</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                <Link to="/documentation/">{item.name}</Link>
            </Breadcrumb.Item>
        </Breadcrumb>
    );

    return (
        <Card title={breadcrumb}>
            <Spin spinning={loading}>
                {
                    product &&
                    <div>
                        <h2>{product.name}</h2>
                        <Row gutter={32}>
                            <Col span={6}>
                                <CategoryMenu product={product} setSelected={setSelectedCategory} selected={selectedCategory} />
                            </Col>
                            <Col span={18}>
                                <ArticleList product={product} category={selectedCategory} />
                            </Col>
                        </Row>
                    </div>
                }
            </Spin>

        </Card>
    )
}

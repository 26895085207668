import React from 'react'
import FormSelect from '../Form/FormSelect'
import { Card, Button } from 'antd'


export default function CountryCondition({ country, setCountry }) {

    const handlePropertyChange = (value) => {
        console.log(value)
        setCountry(prevState => { return { ...prevState, ...value.country } })
    }

    if (!country) return null

    return (
        <div style={{margin : "1em 0"}}>
            <h4>Dans un pays: </h4>
            {country &&
                <div>
                    <FormSelect
                        name="country"
                        value={country.country}
                        style={{ width: "200px" }}
                        allowClear
                        onChange={handlePropertyChange}
                        options_endpoint={'mmc_places/values'}
                        option_formater={(item) => {
                            return {
                                value: item.country === null ? 'empty' : item.country,
                                label: `${item.country === null ? 'Non renseigné' : item.country}`
                            };
                        }}
                        search_property="search"
                        customSearch={{ name: 'country' }}
                    />
                </div>
            }
        </div>
    )
}

import React, { useEffect, useState } from 'react';
import { Link, useParams, Redirect } from 'react-router-dom'
import { Breadcrumb, Button, Card, Col, Modal, message, Row, Switch } from 'antd'

import FaIcon from '../components/FaIcon.js';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner'
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle'

import { useApiContext } from '../providers/ApiProvider.js';
import ContactDash from '../components/Contact/ContactDash.js';
import ContractDash from '../components/Contract/ContractDash.js';
import ScrapperDash from "../components/Scrapper/ScrapperDash";
import LogDash from '../components/Log/LogDash.js';
import PlaceCard from "../components/Place/PlaceCard";
import CommentDash from "../components/Comment/CommentDash";
import { faTrash } from "@fortawesome/pro-solid-svg-icons/faTrash";


const Place = (props) => {

  const [apiState, apiDispatch] = useApiContext();
  const { apiFetchEntity, apiDeleteEntity } = apiDispatch;

  const [place, setPlace] = useState(null)
  const [loading, setLoading] = useState(true)
  const [redirection, setRedirection] = useState(false);
  const [updateElement, setUpdateElement] = useState(0)

  const [displayScrapperModal, setDisplayScrapperModal] = useState(false)
  const params = useParams();
  useEffect(() => {
    // fetch at startup and whenever needUpdate is changed
    fetchData();
  }, [])

  function closeScrapperModal() {
    setDisplayScrapperModal(false)
    setUpdateElement((new Date()).getTime())
  }

  async function fetchData() {
    console.log('fetching data')
    const place_response = await apiFetchEntity('mmc_places', params.place_id);
    if (place_response['@type'] === "hydra:Error") {
      setRedirection(true)
    }
    setPlace(place_response)
    setLoading(false);
  }

  const deletePlace = async () => {
    console.log(place)
    const response = await apiDeleteEntity('mmc_places', place.id)
    if (response['@type'] === "hydra:Error") {
      message.error('Impossible de suprimmer cet élément')
      return
    }
    message.success('Hotel supprimé avec succes, Vous allez être redirigé dans 3 secondes')
    setTimeout(() => {
      window.location.href = '/places';
    }, 3000)

    console.log(response)
  }

  if (loading)
    return <FaIcon icon={faSpinner} spin />

  if (redirection)
    return <Redirect to={{
      pathname: "/erreur",
      search: "",
      state: { status: '404', subtitle: 'Cette hotel n\'existe pas', page: './places' }
    }} />

  if (place['@id']) {
    let cardTitle = (
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/places/">Hotels</Link>
        </Breadcrumb.Item>
        {
          place.account && place.account.cluster ?
            <Breadcrumb.Item>
              <Link to={"/cluster/" + place.account.cluster.id}>{place.account.cluster.name}</Link>
            </Breadcrumb.Item>
            : null
        }
        {
          place.account ?
            <Breadcrumb.Item>
              <Link to={"/account/" + place.account.id}>{place.account.name}</Link>
            </Breadcrumb.Item>
            : null
        }
        <Breadcrumb.Item>
          <Link to={"/place/" + place.id}>{place.name}</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
    );


    const showDeleteConfirm = () => {
      Modal.confirm({
        title: 'Confirmer la suppression?',
        icon: <FaIcon icon={faExclamationTriangle} />,
        okText: 'Oui',
        okType: 'danger',
        cancelText: 'Non',
        onOk() {
          deletePlace();
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }

    const extraActions = (
      <>
        {place.website ?
          <Button type="primary" onClick={() => setDisplayScrapperModal(true)} style={{ marginRight: '10px' }}>Auto Detect</Button> : null}
        <Button type={"danger"} onClick={showDeleteConfirm}>
          <FaIcon icon={faTrash} />
        </Button>
      </>
    )


    return (
      <div>
        <Card title={cardTitle}
          extra={extraActions}
        >
          <Row type="flex" gutter={16}>
            <Col span={16}>
              <Row type="flex" gutter={[16, 16]}>
                <Col span={12}>
                  <PlaceCard item={place} onUpdate={updatedItem => setPlace(updatedItem)} />
                </Col>
                <Col span={12}>
                  <ContractDash place_id={place.id} pageSize={15} updateElement={updateElement} />
                </Col>
                <Col span={12}>
                  <ContactDash id={place.id} endpoint="mmc_places" />
                  {
                    place.account ?
                      <ContactDash id={place.account.id} endpoint="mmc_accounts"
                        cardTitle={"Contacts " + place.account.name} />
                      : null
                  }
                  {
                    place.account && place.account.cluster ?
                      <ContactDash id={place.account.cluster.id} endpoint="mmc_clusters"
                        cardTitle={"Contacts " + place.account.cluster.name} />
                      : null
                  }
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <LogDash place_id={place.id} updateElement={updateElement} />
              <CommentDash id={place.id} endpoint="mmc_places" />
            </Col>
          </Row>

        </Card>
        <ScrapperDash place={place} modal={true} isModalVisible={displayScrapperModal} onClose={closeScrapperModal} />
      </div>
    );
  }
  return <FaIcon icon={faExclamationTriangle} />
};

export default Place;

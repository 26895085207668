import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import { Button } from 'antd';
import {useApiContext} from '../../../providers/ApiProvider.js';
import ApiCollectionLatest from '../../ApiCollectionLatest.js';
import { faLink } from '@fortawesome/pro-solid-svg-icons';
import FaIcon from '../../FaIcon.js';
import { faEdit } from '@fortawesome/pro-solid-svg-icons/faEdit';
import ProductForm from './ProductForm.js';
import { slugify } from '../helpers.js';

const ProductDash = ({ ...rest }) => {

    const [apiState, apiDispatch] = useApiContext();
    const { apiFetchCollection } = apiDispatch;
    const history = useHistory();
    const [contentVersion, setContentVersion] = useState(0)
    const [selectedItem, setSelectedItem] = useState(null)
    const [totalArticles, setTotalArticles] = useState(0)

    useEffect(() => {
        fetchTotalArticle()
    }, [])

    const fetchTotalArticle = async () => {
        let response = await apiFetchCollection('articles', {});
        console.log('response', response)
        if (response['hydra:totalItems']) {
            setTotalArticles(response['hydra:totalItems'])
        }
    }


    const goToSupport = (e, record) => {
        e.stopPropagation()
        history.push(`/fr/support/${record.id}-${slugify(record.name)}`)
    }

    const columns = [
        {
            title: 'Articles',
            dataIndex: 'articles',
            render: (articles, record) => <React.Fragment>{articles.length} articles</React.Fragment>
        },
        {
            title: 'Nom',
            dataIndex: 'name',
            render: (name, record) => <React.Fragment>{name}</React.Fragment>
        },
        {
            title: 'Catégories',
            dataIndex: 'categories',
            render: (categories, record) => <>{categories && categories?.length}</>
        },
        {
            title: 'Actions',
            dataIndex: 'id',
            render: (id, record) => <><FaIcon icon={faLink} onClick={(e) => goToSupport(e, record)} /> <FaIcon icon={faEdit} onClick={(e) => { handleEdit(e, id) }} /></>
        },
    ];

    const handleCreationFormClose = () => {
        setSelectedItem(null);
    }

    const handleEdit = (e, id) => {
        e.stopPropagation()
        setSelectedItem(id)
    }

    if (selectedItem)
        return <ProductForm modal item_id={selectedItem} onClose={handleCreationFormClose} />;

    return (
        <React.Fragment>
            <p>{totalArticles} articles ont été crées</p>
            <ApiCollectionLatest
                title={(currentPageData) => <React.Fragment>
                    <b>Produits</b>
                    <div style={{ float: "right" }}>
                        <Button size="small" onClick={(e) => { handleEdit(e, 'new') }}>+</Button>
                    </div>
                </React.Fragment>}
                fetch_endpoint={"support_products"}
                customSearch={{}}
                showHeader
                delete_endpoint="support_products"
                columns={columns}
                pageSize={10}
                onClick={(item) => history.push(`/documentation/${item.id}-${slugify(item.name)}`)}
                contentVersion={contentVersion}
                {...rest}
            />
        </React.Fragment>
    );
};

export default ProductDash;

import React, {useState} from 'react'
import {Button, message} from 'antd';
import {useApiContext} from "../../providers/ApiProvider";

export function Reload({filetime, total, hydrateVars}) {

  const [apiState, apiDispatch] = useApiContext();
  const {apiPostRequest} = apiDispatch;
  const [loading, setLoading] = useState(false);

  async function handleReload() {
    setLoading(true);
    const response = await apiPostRequest("run-check-duplicate", {});

    if (response.message)
      message.success(response.message)

    if (response.status) {
      hydrateVars(response.content)
    }

    setLoading(false)
  }

  return (
    <div style={{display: "flex", alignItems: "center"}}>
      <div>Total: {total}</div>
      <div style={{marginLeft: 15}}>Dernier check: {filetime}</div>
      <Button
        loading={loading}
        style={{marginLeft: 15}}
        type="primary"
        onClick={handleReload}
      >
        Relancer
      </Button>
    </div>
  )
}

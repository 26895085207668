import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Breadcrumb, Card } from 'antd'

import LogFilters from '../components/Log/LogFilters.js';
import LogList from '../components/Log/LogList.js';
import {checkLocalStorage, saveFilterInStorage } from "../components/_helpers";

const Places = () => {

  const [customSearch, setCustomSearch] = useState(checkLocalStorage('logFilters', {}))

  useEffect(() => {
    if (customSearch) {
      saveFilterInStorage('logFilters', customSearch)
    }
  }, [customSearch])

  let cardTitle = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Link to="/">Home</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Link to="/logs/">Logs</Link>
      </Breadcrumb.Item>
    </Breadcrumb>
  );


  return (
    <>
      <Card title={cardTitle}>
        <LogFilters setCustomSearch={setCustomSearch} customSearch={customSearch} resetFilter={()=>setCustomSearch({})}/>
        <LogList scroll_x={1200} customSearch={customSearch}/>
      </Card>
    </>
  );
};

export default Places;

import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, Input, message } from 'antd'

import FaIcon from '../FaIcon.js';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner'

import { useApiContext } from '../../providers/ApiProvider.js';

const UserForm = ({ item, item_id, entity_id, endpoint, onClose, onChange, modal, form, ...rest }) => {

  entity_id = entity_id || false;
  item_id = item ? item.id : item_id;
  onClose = onClose || function () {
  };
  onChange = onChange || function () {
  };

  const { getFieldDecorator, setFields } = form;

  const [apiState, apiDispatch] = useApiContext();
  const { apiFetchEntity, apiUpdateEntity, apiCreateEntity } = apiDispatch;

  const [_itemId, setItemId] = useState(item_id)
  const [_item, setItem] = useState(item ? { ...item } : null)
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [confirmDirty, setConfirmDirty] = useState(false)

  useEffect(() => {
    // fetch at startup and whenever needUpdate is changed
    if (_itemId && !_item)
      fetchItem();
    else
      setLoading(false);
  }, [])

  async function fetchItem() {
    setLoading(true);
    if (_itemId === 'new') {
      setItem({})
    } else {
      const response = await apiFetchEntity('users', _itemId);
      setItem({ ...response })
    }
    setLoading(false);
  }

  async function handlePropertyChange(chunk) { // {property:value}
    // console.log(chunk);
    setItem(Object.assign(_item, chunk));
  }

  const handleCancel = () => {
    onClose();
  }

  const handleSave = async () => {
    setSaving(true);
    if (_itemId === 'new') {
      _item.email = typeof _item.email === "string" ? _item.email : '';
      _item.username = typeof _item.username === "string" ? _item.username : '';
      _item.password = typeof _item.password === "string" ? _item.password : '';

      // console.log(_item)
      const response = await apiCreateEntity('users', _item);
      if (response && response.id) {
        setItemId({ ...response.id })
        setItem({ ...response })
      }
    } else {
      let data = { ..._item }
      if (typeof _item.password === "string" && _item.password.trim() === '') {
        delete data.password
      }
      await apiUpdateEntity('users', _itemId, data);
    }
    setSaving(false);
    onChange(Object.assign({}, _item));
    onClose();
  }

  const handleSaveAndClose = async () => {
    await handleSave();
    onClose();
  }

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      console.log(values)
      if (values.password !== values.confirm) {
        return message.error('Les mots de passe ne correspondent pas')
      }
      if (!err) {
        // console.log('Received values of form: ', values);
        handleSave()
      }
    });
  };

  const handleBlur = e => {
    const { value, id } = e.target;
    handlePropertyChange({ [id]: value })

  }

  const handleConfirmBlur = e => {
    const { value, id } = e.target;
    setConfirmDirty(confirmDirty || !!value)
    handlePropertyChange({ [id]: value })
  };

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== form.getFieldValue('password')) {
      callback('Les deux mots de passe ne correspondent pas!');
    } else {
      callback();
    }
  };

  const validateToNextPassword = (rule, value, callback) => {
    if (value && confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };


  const formContent = (
    !_item || loading || saving
      ? <React.Fragment><FaIcon icon={faSpinner} spin /> traitement en cours...</React.Fragment>
      : <Form layout="vertical" onSubmit={handleSubmit}>
        <Form.Item label="E-mail">
          {getFieldDecorator('email', {
            initialValue: item?.email,
            rules: [
              {
                type: 'email',
                message: 'Cet email n\est pas valide!',
              },
              {
                required: true,
                message: 'L\'email est requis!',
              },
            ],
          })(<Input onBlur={handleBlur} />)}
        </Form.Item>
        <Form.Item
          label={
            <span>
              Nickname
            </span>
          }
        >
          {getFieldDecorator('username', {
            initialValue: item?.username,
            rules: [{ required: true, message: 'Le nom est requis!', whitespace: true }],
          })(<Input onBlur={handleBlur} />)}
        </Form.Item>

        <Form.Item label="Password" hasFeedback>
          {getFieldDecorator('password', {
            rules: [
              {
                required: !!item ? false : true,
                message: 'Please input your password!',
              },
              {
                validator: validateToNextPassword,
              },
            ],
          })(<Input.Password onBlur={handleBlur} />)}
        </Form.Item>
        <Form.Item label="Confirm Password" hasFeedback>
          {getFieldDecorator('confirm', {
            rules: [
              {
                required: !!item ? false : true,
                message: 'Please confirm your password!',
              },
              {
                validator: compareToFirstPassword,
              },
            ],
          })(<Input.Password onBlur={handleConfirmBlur} />)}
        </Form.Item>

        <Form.Item>
          <Button key="cancel" onClick={handleCancel}>
            Close
          </Button>
          <Button style={{ marginLeft: 8 }} key="save" type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>

      </Form>
  );

  if (modal) {
    return <Modal
      title={_itemId !== "new" ? _item?.email + ' - ' + _item?.username : "Créer un nouveau contact"}
      visible={true}
      onCancel={handleCancel}
      footer={null}
    >
      {formContent}
    </Modal>
  } else {
    return formContent;
  }
};

export default Form.create()(UserForm);

import React from 'react';
import {Route} from 'react-router-dom';
import SupportLayout from "./SupportLayout";

const SupportRoute = ({component: Component, layout, ...rest}) => {

  const renderer = (layout, props) => {
    return <SupportLayout layout={layout} private={false} {...props}><Component {...props} /></SupportLayout>
  }

  return <Route {...rest} render={renderer.bind(null, layout)}/>
};

export default SupportRoute;

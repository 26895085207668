import React from 'react'
import FormSelect from '../Form/FormSelect'
import { Button, InputNumber, Card } from 'antd'

export default function CityConditions({ city, setCity }) {

    const handlePropertyChange = (value) => {
        console.log(value)
        let newCity = value.city ? { ...city, ...value.city } : {}
        setCity(newCity)
    }

    const handleRadius = (value) => {
        console.log(value)
        value = typeof value === 'number' ? value : 10
        setCity(prev => { return { ...prev, radius: value } })
    }
    if (!city) return null;

    return (
        <div style={{margin : "1em 0"}}>
            <h4>Dans une ville: </h4>
            {
                city &&
                    <div>
                        <FormSelect
                            name="city"
                            value={city.city}
                            style={{ width: "200px" }}
                            allowClear
                            onChange={handlePropertyChange}
                            options_endpoint={'mmc_places/values'}
                            option_formater={(item) => {
                                return {
                                    value: item.city === null ? 'empty' : item.city,
                                    label: `${item.city === null ? 'Non renseigné' : item.city}`
                                };
                            }}
                            search_property="search"
                            customSearch={{ name: 'city' }}
                        />
                        {!city.radius && city.radius !== 0 ?
                            <Button onClick={handleRadius}>+</Button>
                            : <> et dans un rayon de {<InputNumber min={1} value={city.radius} onChange={handleRadius} />} km</>
                        }
                    </div>
            }
        </div>
    )
}

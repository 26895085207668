import React, { useState } from 'react';
import { Link } from 'react-router-dom'

import { Avatar, Button } from 'antd';

import FaIcon from '../FaIcon.js';
import { faEdit } from '@fortawesome/pro-solid-svg-icons/faEdit'
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser';

import AccountForm from './AccountForm.js'
import ApiCollectionTable from '../ApiCollectionTable.js';
import {faHotel} from "@fortawesome/pro-solid-svg-icons/faHotel";

const ClusterList = ({ scroll_x, cluster_id, ...rest }) => {

  const [creationMode, setCreationMode] = useState(false)
  const [total, setTotal] = useState(0);

  const handleCreationFormClose = () => {
    setCreationMode(false);
  }
  const handleCreationFormChange = () => {
  }

  const columns = [
    {
      title: 'Logo',
      dataIndex: 'logo',
      width: 100,
      align: 'center',
      render: (logo, record) => { return logo ? <Avatar src={logo.contentUrl} /> : <Avatar>{record.name[0].toUpperCase()}</Avatar> },
    },
    {
      title: 'Nom',
      dataIndex: 'name',
      render: (name, record) => <Link to={`/account/${record.id}`}><Button><FaIcon icon={faEdit} /> {name && !!name.length > 0 && name }</Button></Link>,
    },
    {
      title: 'Ville',
      dataIndex: 'city',
      render: (city, record) => <React.Fragment>{city}<br/><small>{record.zip}</small></React.Fragment>,
    },
    {
      title: 'Hotels',
      dataIndex: 'places',
      render: (places, record) =>
        !!places.length && <React.Fragment><FaIcon icon={faHotel} /> {places.length}</React.Fragment>
    },
    {
      title: 'Contacts',
      dataIndex: 'contacts',
      render: (contacts, record) =>
        !!contacts.length && <React.Fragment><FaIcon icon={faUser} /> {contacts.length}</React.Fragment>
    },
  ];
  if (!cluster_id) {
    columns.push({
      title: 'Groupe',
      dataIndex: 'cluster.name',
      render: (name, record) => {
        return record.cluster
          ? <Link to={`/cluster/${record.cluster.id}`}><Button><FaIcon icon={faEdit} />{record.cluster.name}</Button></Link>
          : null
      },
    });
  }

  if (creationMode)
    return <AccountForm modal cluster_id={cluster_id} item_id="new" onClose={handleCreationFormClose} onChange={handleCreationFormChange} />;

  return (
    <ApiCollectionTable
      title={(currentPageData) => <React.Fragment><b>{total} Sociétés</b> <Button style={{ float: "right" }} type="primary" size="small" onClick={() => { setCreationMode(true) }}>+</Button></React.Fragment>}
      fetch_endpoint={cluster_id ? `mmc_clusters/${cluster_id}/accounts` : "mmc_accounts"}
      columns={columns}
      setTotal={setTotal}
      {...rest}
    />
  );
};

export default ClusterList;

import React, { useEffect, useRef, useState } from 'react';
import { Input, AutoComplete } from 'antd'

import FaIcon from '../FaIcon.js';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons/faExclamationCircle'
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck'
import { useApiContext } from "../../providers/ApiProvider";

const { Option } = AutoComplete;


const AutoCompleteInput = ({ options, options_endpoint, option_formater, search_property, customSearch = {}, handleSave, name, value, onChange,
  placeholder = null, enterButton = false, resetInput = 0, ...rest }) => {

  const originalValue = '' + (value || '')
  const [internalValue, setInternalValue] = useState(originalValue)
  const [internalOptions, setInternalOptions] = useState(options || [])
  const isMounted = useRef(null);

  const [fetching, setFetching] = useState(false)

  const [apiState, apiDispatch] = useApiContext();
  const { apiFetchCollection, apiDeleteEntity } = apiDispatch;

  useEffect(() => {
    isMounted.current = true;
    setInternalValue('')
    setInternalOptions([])

    return () => {
      // executed when unmount
      isMounted.current = false;
    }
  }, [resetInput])

  //Appelé à chaque changement de l'input, lance la recherche de correspondance
  const handleSearch = (value) => {
    setInternalValue(value)
    value = value.trim().toLowerCase()
    value ? fetch(value) : setInternalOptions([])
  }


  const handleInputSearch = async (value, event) => {
    event.stopPropagation()
    if (event && event.key === 'Enter' && internalOptions.length > 0) {
      return
    }
    if (value.trim() === '') {
      return
    }

    let iri = value === internalValue ? null : internalValue;
    await handleSave({ iri, name: value })
    setInternalValue('');
    setInternalOptions(options || [])
  }

  const handleInputChange = (value) => {
    let chunk = {};
    console.log(value)
    let newValue = value.trim();

    if (options_endpoint) {
      for (let i = 0; i < internalOptions.length; i++) {
        console.log(internalOptions[i])
        if (internalOptions[i].value === newValue && internalOptions[i]._rawItem)
          chunk = internalOptions[i]._rawItem
      }
    }

    onChange(chunk);
    console.log(newValue)
    setInternalValue(newValue);
  }

  const fetch = async (search) => {
    if (options_endpoint) {
      setFetching(true)
      let params = {
        itemsPerPage: 100,
        page: 0,
        search: customSearch
      };
      if (search)
        params.search[search_property] = search;

      let hydra_response = await apiFetchCollection(options_endpoint, params);
      if (isMounted.current) {
        console.log(hydra_response)

        if (hydra_response) {
          let data = hydra_response['hydra:member'];
          setInternalOptions(data.map((item, i) => {
            let o = option_formater(item, i)
            o._rawItem = item;
            return o;
          }));
        }
        setFetching(false);
      }
    }

  }

  const displayOptions = internalOptions.map(option => (
    <Option key={option.value} text={option.label}>
      {option.label}
    </Option>
  ))

  return (
    <AutoComplete
      dropdownClassName="certain-category-search-dropdown"
      dataSource={displayOptions}
      value={internalValue}
      onSearch={handleSearch}
      optionLabelProp="text"
      onSelect={handleInputChange}
      size="small"
      {...rest}
    >
      <Input.Search
        placeholder={placeholder ? placeholder : 'Ajouter un tag'}
        enterButton={enterButton}
        onSearch={handleInputSearch}
        onClick={(e) => e.stopPropagation()}
        size="small"
        {...rest}
      />
    </AutoComplete>
  )

};

export default AutoCompleteInput;

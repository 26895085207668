import React, { useState, useEffect } from 'react';
import {useParams, Link, Redirect} from 'react-router-dom'
import { Breadcrumb, Card } from 'antd'

import FaIcon from '../components/FaIcon.js';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner'
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle'

import { useApiContext } from '../providers/ApiProvider.js';

import ContactList from '../components/Contact/ContactList.js';

const Cluster = (props) => {

  const [apiState, apiDispatch] = useApiContext();
  const { apiFetchEntity } = apiDispatch;

  const [cluster, setCluster ] = useState(null)
  const [loading, setLoading ] = useState(true)
  const [redirection, setRedirection] = useState(false);

  const params = useParams();

  useEffect(() => {
    // fetch at startup and whenever needUpdate is changed
    fetchData();
  },[])

  async function fetchData() {
    const cluster_response = await apiFetchEntity('mmc_clusters',params.cluster_id);

    if (cluster_response['@type'] ==="hydra:Error") {
      setRedirection(true)
    }

    setCluster(cluster_response)
    setLoading(false);
  }

  if(loading)
    return <FaIcon icon={faSpinner} spin />

  if (redirection)
    return <Redirect to={{
      pathname: "/erreur",
      search: "?utm=your+face",
      state: {status:'404', subtitle:'Ce groupe n\'existe pas', page:'./clusters' }
    }} />

  if(cluster['@id'])
  {
    let cardTitle = (
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/clusters/">Groupes</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={"/cluster/"+cluster.id}>{cluster.name}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={"/cluster/"+cluster.id+"/contacts"}>Contacts</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
    );

    return (
      <div>
        <Card title={cardTitle}>
          <ContactList scroll_x={1000} id={cluster.id} endpoint="mmc_clusters"/>
        </Card>
      </div>
    );
  }
  return <FaIcon icon={faExclamationTriangle} />
};

export default Cluster;

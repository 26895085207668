import React, { useEffect, useState } from 'react';
import { Card, Collapse, Divider, List } from 'antd';

import { useApiContext } from '../../../providers/ApiProvider';
import {getLanguage, sortArticles, translate} from '../helpers';
import { useParams, Link } from "react-router-dom";
import Search from './Search';

const { Panel } = Collapse;

export default function ArticleSidebar({ defaultActiveKey = [] }) {

  const [apiState, apiDispatch] = useApiContext();
  const { apiFetchCollection } = apiDispatch;
  const params = useParams();

  const [categories, setCategories] = useState([]);
  const [relatedArticles, setRelatedArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const lng = getLanguage(params.lng)

  useEffect(() => {
    const productId = params.product.split('-').shift();
    setRelatedArticles([])
    fetch(productId);
    fetchRelated(productId)
  }, [params])

  async function fetch(productId) {
    setLoading(true);
    const data = { anonymously: true, search: { 'exists[parent]': false, 'pagination': false } }
    const response = await apiFetchCollection(`support_products/${productId}/categories`, data);
    setCategories(response['hydra:member']);
    setLoading(false);
  }

  async function fetchRelated(productId) {
    const data = {
      noExtension: true,
      anonymously: true,
      search: { 'productId': productId, 'articleId': params.id, 'lng': lng }
    }
    const response = await apiFetchCollection(`public/related-articles/`, data);
    setRelatedArticles(response);
  }

  function buildPanel(categories = []) {

    return (
      !!categories.length &&
      <Collapse
        bordered={false} expandIconPosition="right"
        defaultActiveKey={defaultActiveKey}
      >
        {
          categories.sort(sortArticles).map(item =>
            (!!item.children.length || !!item.articles.length) &&
            <Panel
              header={translate(item, 'name', lng)} key={item.id}
              showArrow={!!item.children.length || !!item.articles.length} style={{ border: 0 }}
              key={item.id}
            >
              {buildPanel(item.children)}

              {!!item.articles.length &&
                <List
                  size="small"
                  dataSource={item.articles.sort(sortArticles)}
                  renderItem={item =>
                    item.activeLngs && item?.activeLngs?.includes(lng) ?
                      <List.Item><Link to={`/${lng}/support/${params.product}/article/${item.id}`}>{translate(item, 'title', lng)}</Link></List.Item>
                      : <></>
                  }
                />}
            </Panel>
          )
        }
      </Collapse>
    )
  }

  return (
    <Card title={<Search sidebar />} style={{ marginTop: '61px' }} loading={loading}>
      {
        buildPanel(categories)
      }
      {
        !!relatedArticles.length &&
        <>
          <Divider style={{ marginTop: 40 }}>Articles liés</Divider>
          {
            <List
              size="small"
              dataSource={relatedArticles}
              renderItem={item => <List.Item><Link to={`/${lng}/support/${params.product}/article/${item.id}`}>{translate(item, 'title', lng)}</Link></List.Item>}
            />
          }
        </>
      }
    </Card>
  );
}

import React, {useEffect, useState}  from 'react';
import { Link } from 'react-router-dom'
import { Breadcrumb, Card } from 'antd'

import AccountList from '../components/Account/AccountList.js';
import AccountFilters from '../components/Account/AccountFilters.js';
import { checkLocalStorage, saveFilterInStorage } from '../components/_helpers.js';

const Accounts = () => {

  const [customSearch, setCustomSearch] = useState(checkLocalStorage('accountFilters', {}))

  useEffect(() => {
    if (customSearch) {
      saveFilterInStorage('accountFilters', customSearch)
    }
  }, [customSearch])

  let cardTitle = (
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/clusters/">Sociétés</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
    );

  return (
    <Card title={cardTitle}>
      <AccountFilters customSearch={customSearch} setCustomSearch={setCustomSearch}/>
      <AccountList customSearch={customSearch}/>
    </Card>
  );
};

export default Accounts;

import React, {useEffect, useState} from "react";
import {useApiContext} from "../../providers/ApiProvider";
import {Select, Table} from "antd";

export default function Catalogue({products, setProducts}) {

  const [apiState, apiDispatch] = useApiContext();
  const {apiFetchCollection} = apiDispatch;

  const [options, setOptions] = useState([]);
  const [catalogue, setCatalogue] = useState([]);

  useEffect(() => {
    fetch();
  }, [])

  async function fetch() {
    const response = await apiFetchCollection("sellsy/get-catalogue", {noExtension: true, search: {name: ""}})

    if (response.success === 1)
      makeChildren(response.result);
  }

  function makeChildren(values) {
    let children = [];

    Object.keys(values).map((key, index) => {
      children.push(<Select.Option key={index} value={values[key].id} data={{
        row_type: "item",
        row_linkedid: values[key].id,
        row_name: values[key].tradename,
        row_notes: values[key].notes,
        row_unit: values[key].unit,
        row_unitAmount: values[key].unitAmount,
        row_taxid: values[key].taxid,
        row_qt: 1,
        row_accountingCode: values[key].accountingCode
      }}>{values[key].tradename} ({values[key].name})</Select.Option>)
    })

    setOptions(children);
  }

  function handleChange(values, other) {
    setProducts(other.map(other => other.props.data))
  }

  return (
    <div style={{padding: 10}}>
      <Select
        mode="multiple"
        style={{width: 600}}
        placeholder="Selectionner le/les produit(s)"
        onChange={handleChange}
        value={products.map(product => product.row_linkedid)}
      >
        {options}
      </Select>
    </div>
  )
}

import { Button, Card } from 'antd'
import React from 'react'
import FormSelect from '../Form/FormSelect.js'


export default function TagsConditions({ tags, setTags }) {


    const handlePropertyChange = (value) => {
        console.log(value)
        let condition = value?.condition || tags.condition
        let queryTags = value?.queryTags ? [...value.queryTags] : [...tags.queryTags]
        setTags({ condition: condition, queryTags: queryTags })
    }

    if (!tags) return null

    return (
        <div style={{margin : "1em 0"}}>
            <h4>Avec ou sans tags: </h4>
            {
                tags &&
                <div>
                    <FormSelect
                        name="condition"
                        placeHolder="Avec"
                        value={tags.condition}
                        onChange={handlePropertyChange}
                        options={[{ value: 'avec', label: 'Avec' }, { value: 'sans', label: 'Sans' }]}
                        style={{ marginRight: '1em', width: 100 }}
                    />
                    <FormSelect
                        name="queryTags"
                        value={tags.queryTags}
                        style={{ width: "300px" }}
                        mode="multiple"
                        allowClear
                        onChange={handlePropertyChange}
                        options_endpoint={'tags'}
                        option_formater={(item) => {
                            return { value: item.id, label: `${item.name}` };
                        }}
                        search_property="name"
                        customSearch={{ type: 1 }}
                    //   contentVersion={contentVersion}
                    />
                </div>
            }
        </div>
    )

}

import React, { useState } from 'react';
import {Button, Avatar} from 'antd';
import ApiCollectionLatest from '../ApiCollectionLatest.js';

import AccountForm from './AccountForm.js'
import FaIcon from "../FaIcon";
import {faLink} from "@fortawesome/pro-solid-svg-icons/faLink";
import AccountLinkGroup from "./AccountLinkGroup";


const AccountDash = ({cluster_id, ...rest}) => {

  const [selectedItem, setSelectedItem ] = useState(null)
  const [creationMode, setCreationMode ] = useState(false)
  const [linkMode, setLinkMode ] = useState(false)

  const [contentVersion, setContentVersion ] = useState(0)

  const handleCreationFormClose = () => {
    setCreationMode(false);
  }
  const handleCreationFormChange = () => {
  }

  const handleEditFormClose = () => {
    setSelectedItem(null);
  }
  const handleEditFormChange = () => {
    setContentVersion((new Date()).getTime());
  }

  const columns = [
    {
      title: 'Logo',
      dataIndex: 'logo',
      width:100,
      align:'center',
      render: (logo,record) => { return logo ? <Avatar src={logo.contentUrl} /> : <Avatar>{record.name[0].toUpperCase()}</Avatar> },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      search: true,
      sorter: true,
      render: (name,record) => name
    },
  ];

  if(creationMode)
    return <AccountForm modal cluster_id={cluster_id} item_id="new" onClose={handleCreationFormClose} onChange={handleCreationFormChange}/>;

  if(linkMode)
    return <AccountLinkGroup modal group_iri={`api/mmc_accounts/${cluster_id}`} onClose={() => setLinkMode(false)}  />

  return (
    <React.Fragment>
      <ApiCollectionLatest
        title={(currentPageData) => <React.Fragment>
          {
            cluster_id ? <Button size="small" onClick={()=>{setCreationMode(true)}}>+</Button> : null
          }
          {
            cluster_id ? <Button style={{marginLeft: '5px'}} size="small" onClick={()=>{setLinkMode(true)}}><FaIcon icon={faLink} /></Button> : null
          }
          <b> Sociétés</b>
        </React.Fragment>}
        fetch_endpoint={cluster_id ? `mmc_clusters/${cluster_id}/accounts`:"mmc_clusters"}
        columns={columns}
        pageSize={5}
        onClick={(item) =>  window.location.href = `/account/${item.id}` }
        contentVersion={contentVersion}
        {...rest}
      />
    </React.Fragment>
  );
};

export default AccountDash;

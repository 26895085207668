import React, {useEffect, useState} from "react";
import {Input, Select} from "antd";
import {useApiContext} from "../../providers/ApiProvider";

export default function Options({data, setData, sellsyId, setContactProposition}) {

  const [apiState, apiDispatch] = useApiContext();
  const {apiFetchCollection} = apiDispatch;

  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    fetch();
  }, [])

  async function fetch() {
    const response = await apiFetchCollection(`sellsy/get-client/${sellsyId}`, {noExtension: true});

    if (response.success)
      makeOptions(response.result.contacts);
  }

  function makeOptions(values) {
    let children = [];
    let cp = [];

    Object.keys(values).map((key, index) => {
      children.push(<Select.Option key={index} value={values[key].id}>
        {values[key].forename} {values[key].name} - {values[key].email}
      </Select.Option>)

      cp.push({
        id: values[key].id,
        firstname: values[key].forename,
        lastname: values[key].name,
        email: values[key].email
      })
    })

    setContactProposition(contactProposition => [...contactProposition, ...cp]);
    setContacts(children);
  }

  function handleChange(key, value) {
    setData({...data, [key]: value});
  }

  return (
    <div style={{padding: 10}}>
      <div>
        <Select
          style={{marginBottom: 15, width: 400}}
          placeholder="Selectionner un contact (optionel)"
          onChange={value => handleChange('contactId', value)}
          allowClear
        >
          {contacts}
        </Select>
      </div>

      <div>
        <Input
          style={{marginBottom: 15, width: 400}}
          placeholder="Objet (optionel)"
          onChange={e => handleChange('subject', e.target.value)}
          value={data.subject}
        />
      </div>

      <div>
        <Input.TextArea
          style={{marginBottom: 15, width: 400}}
          placeholder="Notes (optionel)"
          onChange={e => handleChange('notes', e.target.value)}
          value={data.notes}
        />
      </div>
    </div>
  )
}

import { Breadcrumb, Card } from 'antd';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import ArticlesDash from '../components/Support/Admin/ArticlesDash';
import CategoriesDash from '../components/Support/Admin/CategoriesDash';
import ProductDash from '../components/Support/Admin/ProductDash';
import { useApiContext } from '../providers/ApiProvider';

export default function Documentation() {

    const [apiState, apiDispatch] = useApiContext();
    const { apiFetchCollection } = apiDispatch;
    const [loading, setLoading] = useState(false)
    const [product, setProduct] = useState(null)

    useEffect(() => {
        // fetch at startup and whenever needUpdate is changed
        fetch();
    }, [])

    const fetch = async () => {
        setLoading(true)
        let hydra_response = await apiFetchCollection('support_products', {});
        if (hydra_response["@type"] !== "hydra:Error") {
            console.log(hydra_response['hydra:member'])
            setProduct(hydra_response['hydra:member'])
        }

        setLoading(false);
    }
    
    let cardTitle = (
        <Breadcrumb>
            <Breadcrumb.Item>
                <Link to="/">Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                <Link to="/documentation/">Documentation</Link>
            </Breadcrumb.Item>
        </Breadcrumb>
    );

    return (
        <Card title={cardTitle}>
            <ProductDash />
        </Card>
    );
}

// Called in ContractDrawer
import React, { useState, useEffect } from 'react';
import {Modal, Form, Button } from 'antd'

import FaIcon from '../FaIcon.js';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner'

import { useApiContext } from '../../providers/ApiProvider.js';

import FormInput from '../Form/FormInput.js'
import FormSelect from '../Form/FormSelect.js'

import {externalOptions, externalFormatter} from "./_helpers";


const ProductForm = ({item, item_id, onClose, onChange, modal, ...rest}) => {

  item_id = item ? item.id : item_id;
  onClose = onClose || function(){};
  onChange = onChange || function(){};
  const [apiState, apiDispatch] = useApiContext();
  const { apiFetchEntity, apiUpdateEntity, apiCreateEntity } = apiDispatch;

  const [_itemId, setItemId ] = useState(item_id)
  const [_item, setItem ] = useState(item ? {...item} : null)
  const [loading, setLoading ] = useState(true)
  const [saving, setSaving ] = useState(false)
  useEffect(() => {
    // fetch at startup and whenever needUpdate is changed
    if(_itemId && !_item)
      fetchItem();
    else
      setLoading(false);
  },[])

  async function fetchItem() {
    setLoading(true);
    if(_itemId==='new')
    {
        setItem({})
    }
    else
    {
      const response = await apiFetchEntity('products',_itemId);
      setItem({...response})
    }
    setLoading(false);
    return ;
  }

  async function handlePropertyChange(chunk) { // {property:value}
    setItem(Object.assign(_item, chunk));
  }

  const handleCancel = () => {
    onClose();
  }

  const handleSave = async () => {
    setSaving(true);
    console.log(_item)
    _item.name = typeof _item.name==="string" ? _item.name : '';
    _item.endpoint = typeof _item.endpoint==="string" ? _item.endpoint : '';
    _item.apiKey = typeof _item.apiKey==="string" ? _item.apiKey : '';
    _item.external = parseInt(_item.external) || 0;
    _item.detections = typeof _item.detections==="object" ? _item.detections : [];


    if(_itemId==='new')
    {
      console.log('item', _item)
      const response = await apiCreateEntity('products',_item);
      if(response && response.id)
      {
        setItemId({...response.id})
        setItem({...response})
      }
    }
    else
    {
      let response = await apiUpdateEntity('products',_itemId,_item);
      console.log('r', response)
    }
    onChange(Object.assign({},_item));
    setSaving(false);
  }

  const handleSaveAndClose = async () => {
    await handleSave();
    onClose();
  }


  const formContent = (
    !_item || loading || saving
      ? <React.Fragment><FaIcon icon={faSpinner} spin /> traitement en cours...</React.Fragment>
      : <Form layout="vertical">

        <Form.Item label="Name">
          <FormInput placeholder="" name="name" value={_item.name} onChange={handlePropertyChange}/>
        </Form.Item>

        <Form.Item label="External">
          <FormSelect
            name="external"
            value={_item.external}
            onChange={handlePropertyChange}
            options={externalOptions}
            option_formater={externalFormatter(_item.external)}
          />
        </Form.Item>

         <Form.Item label="Catégorie">
          <FormSelect
            name="tag"
            value={_item.tag}
            onChange={handlePropertyChange}
            options_endpoint={"tags?type=0"}
            search_property="name"
            option_formater={(item) => { return {value:item['@id'], label:item.name}; }}
          />
        </Form.Item>

        <Form.Item label="Detections pattern">
          <FormSelect
            name="detections"
            value={_item.detections}
            onChange={handlePropertyChange}
            mode='tags'
          />
        </Form.Item>

        <Form.Item label="Endpoint" tooltip="Permet la connexion avec cette plateforme">
          <FormInput placeholder="" name="endpoint" value={_item.endpoint} onChange={handlePropertyChange}/>
        </Form.Item>

        <Form.Item label="apiKey" tooltip="Permet l'authentification sur cette plateforme">
          <FormInput placeholder="" name="apiKey" value={_item.apiKey} onChange={handlePropertyChange}/>
        </Form.Item>


        <Form.Item>
          <Button key="cancel" onClick={handleCancel}>
            Close
          </Button>
          <Button style={{ marginLeft: 8 }} key="save" type="primary" onClick={handleSave}>
            Save
          </Button>
          <Button style={{ marginLeft: 8 }} key="saveAndClose" type="primary" onClick={handleSaveAndClose}>
            Save & Close
          </Button>
        </Form.Item>

      </Form>
  );

  if(modal)
  {
    return <Modal
      title={_item && _item.name ? _item.name : "Ajouter un produit"}
      visible={true}
      onCancel={handleCancel}
      footer={null}
    >
      {formContent}
    </Modal>
  }
  else
  {
    return formContent;
  }
};

export default ProductForm;

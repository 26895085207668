import React, { useState, useEffect } from 'react';
import { Row, Col, Button, DatePicker } from 'antd'

import moment from 'moment';

import FaIcon from '../FaIcon.js';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons/faExclamationCircle'
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons/faExclamationTriangle'
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck'

import { useApiContext } from '../../providers/ApiProvider.js';

import Countdown from '../../components/Countdown.js';

const FormDatePicker = ({children, onChange, name, value, ...rest}) => {

  const originalValue = value;
  const [internalValue, setInternalValue ] = useState(originalValue)
  const [saveNeeded, setSaveNeeded ] = useState(false)

  const handleInputChange = (date, dateString) => {
    let chunk = {};
    let newValue = dateString;
    chunk[name] = newValue
    onChange(chunk);

    setInternalValue(newValue);
    setSaveNeeded(newValue!==originalValue);
  }

  return <DatePicker style={{width:"100%"}} name={name} defaultValue={internalValue ? moment(internalValue, 'YYYY-MM-DD') : null} onChange={handleInputChange} />
};

export default FormDatePicker;

import React from 'react';
import {Collapse} from 'antd'

const ScrapperCard = ({results}) => {
  console.log(results)
  return (
    <React.Fragment>
      <h3>Résultat d'analyse du site</h3>
      <p>Status: {results.type}</p>
      {results.msg ?
        <p>Erreur : {results.msg}</p> : null
      }
      {results.found && results.found.length > 0 ?
        <>
          <h4>Contrat trouvé : </h4>
          <ul>
            {
              results.found.map(item =>
                <li key={'found_'+item}>{item}</li>
              )
            }
          </ul>
        </>
        : 'Aucun contrat trouvé'
      }
      {results.missing && results.missing.length > 0 ?
        <>
          <h4>Contrat manquant : </h4>
          <ul>
            {
              results.missing.map(item => 
                <li key={'missing_'+item}>{item}</li>
              )
            }
          </ul>
        </>
        : null
      }

    </React.Fragment>
  );
};

export default ScrapperCard;

import React, { useState } from 'react';
import { Link } from 'react-router-dom'

import { Button } from 'antd';

import FaIcon from '../FaIcon.js';
import { faEdit } from '@fortawesome/pro-solid-svg-icons/faEdit'

import UserForm from './UserForm.js'
import ApiCollectionTable from '../ApiCollectionTable.js';

const UserList = ({scroll_x, ...rest}) => {

  const [editing, setEditing ] = useState(null)

  const handleCreationFormClose = () => {
    setEditing(null);
  }
  const handleCreationFormChange = () => {
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'username',
      search: true,
      sorter: true,
      render: (username,record) => username && username.length > 0 ? username : '???'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: (email,record) => email && email.length > 0 ? email : '???'
    },
    {
      title: '',
      dataIndex: 'id',
      render: (id,record) => <FaIcon icon={faEdit} onClick={()=> setEditing(record)}/>
    },
  ];

  if(editing)
    return <UserForm modal item_id={editing === 'new' ? editing : null} item={editing === 'new' ? null : editing} onClose={handleCreationFormClose} onChange={handleCreationFormChange}/>;

  return (
    <ApiCollectionTable
      title={(currentPageData) => <React.Fragment><b>Utilisateurs</b> <Button style={{float:"right"}} type="primary" size="small" onClick={()=>{setEditing('new')}}>+</Button></React.Fragment>}
      fetch_endpoint="users"
      columns={columns}
      scroll_x={scroll_x}
      {...rest}
    />
  );
};

export default UserList;

import React, { useState } from 'react'

import { useApiContext } from '../../providers/ApiProvider.js';
import FormSelect from '../Form/FormSelect.js'
import FormInput from '../Form/FormInput.js'

import { Button, Col, Form, Row } from 'antd'

export default function ContactFilters({ customSearch, setCustomSearch }) {

  const [apiState, apiDispatch] = useApiContext();
  const [_item, setItem] = useState(customSearch)
  const [contentVersion, setContentVersion] = useState(0)

  async function handlePropertyChange(chunk) { // {property:value}
    console.log('ck', chunk)
    setItem(Object.assign(_item, chunk));
    handleFilter()

  }


  const handleFilter = () => {
    console.log('filter', _item)
    let customFilters = {}

    if (typeof _item.contactRole === 'object')
      customFilters["contactRole.id"] = _item.contactRole.id;

    if (typeof _item.author === 'string')
      customFilters.author = _item.author;

      customFilters.firstname = typeof _item.firstname === 'string' ? _item.firstname : '';
      customFilters.lastname = typeof _item.lastname === 'string' ? _item.lastname : '';
      customFilters.email = typeof _item.email === 'string' ? _item.email : '';

    if (typeof _item['targetUsers.id'] === 'object')
      customFilters['targetUsers.id'] = _item['targetUsers.id'].id;


    console.log('custo', customFilters)
    setCustomSearch(customFilters);

  }


  const resetFilters = () => {
    setItem({})
    setCustomSearch({})
    setContentVersion((new Date()).getTime());
  }

  return (
    <div style={{ marginBottom: '1em' }}>
      <h3>Filters</h3>
      <Row type="flex" gutter={[16, 16]}>
        <Col span={6}>
          <Form.Item label="Nom : " hasFeedback={false}>
            <FormInput
              name="lastname"
              value={_item.lastname}
              style={{ width: "300px" }}
              onChange={handlePropertyChange}
              contentVersion={contentVersion}
              allowClear
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Prénom : ">
            <FormInput
              name="firstname"
              value={_item.firstname}
              style={{ width: "300px" }}
              onChange={handlePropertyChange}
              contentVersion={contentVersion}
              allowClear
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Email : ">
          <FormInput
              name="email"
              value={_item.email}
              style={{ width: "300px" }}
              onChange={handlePropertyChange}
              contentVersion={contentVersion}
              allowClear
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Role : ">
          <FormSelect
              name="contactRole"
              value={_item.contactRole}
              onChange={handlePropertyChange}
              style={{marginRight: '1em', width: "300px"}}
              contentVersion={contentVersion}
              options_endpoint={'contact_roles'}
              search_property="name"
              allowClear
              option_formater={(item) => {
                return {value: item['id'], label: `${item.name}`};
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row type="flex" gutter={[16, 16]}>
        <Col span={24}>
          <Button style={{ marginRight: '1em' }} onClick={resetFilters}>Reset</Button>
          <Button type="primary" onClick={handleFilter}>Filter</Button>
        </Col>
      </Row>

    </div>
  )
}

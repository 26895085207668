import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import { Avatar, Tag, Button, Rate } from 'antd';

import ApiCollectionTable from '../ApiCollectionTable.js';

import FaIcon from '../FaIcon.js';
import { faPhoneRotary } from '@fortawesome/pro-solid-svg-icons/faPhoneRotary'
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope'
import { faFileCsv } from "@fortawesome/pro-solid-svg-icons/faFileCsv";
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser';
import { faStar } from '@fortawesome/pro-solid-svg-icons/faStar'

import PlaceForm from './PlaceForm.js';
import CsvImport from "../Form/CsvImport";
import ExportCsv from "../ExportCsv";
import { faArchive } from '@fortawesome/pro-solid-svg-icons/faArchive';
import ApiCollectionTags from '../ApiCollectionTags.js';

const PlaceList = ({ scroll_x, account_id, customSearch = {}, currentPage, setCurrentPage, ...rest }) => {

  const [creationMode, setCreationMode] = useState(false)
  const [total, setTotal] = useState(0);
  const [importVisible, setImportVisible] = useState(false)

  const [contentVersion, setContentVersion] = useState(0)

  const handleCreationFormClose = () => {
    setCreationMode(false);
  }
  const handleCreationFormChange = () => {
  }
  const closeImport = () => {
    setImportVisible(false);
    setContentVersion((new Date()).getTime());
  }

  const weekday = [
    'Lundi', 'Mardi',
    'Mercredi', 'Jeudi',
    'Vendredi', 'Samedi', 'Dimanche',
  ]

  const columns = [
    {
      title: 'Hotel name',
      dataIndex: 'name',
      width: 250,
      render: (name, record) => <Link to={`/place/${record.id}`}>{record.isArchived && <FaIcon icon={faArchive} />} {name}
        <br />{record.stars && <span>
          <Rate disabled defaultValue={record.stars}
            character={<FaIcon icon={faStar} style={{ width: 10 }} />}
            style={{ fontSize: 15 }} />
        </span>}
      </Link>,
    },
    {
      title: 'Ville',
      dataIndex: 'city',
      render: (city, record) => <React.Fragment>{city}<br /><small>{record.address} ({record.zip})</small></React.Fragment>,
    },
    {
      title: 'Groupe / Société',
      dataIndex: 'account',
      render: (account, record) => <React.Fragment>
        {
          account && account.cluster ?
            <React.Fragment>Groupe : {account.cluster.name}<br /></React.Fragment> : null
        }
        {account ?
          <React.Fragment>Société : {account.name} </React.Fragment> : null

        }
      </React.Fragment>,
    },
    {
      title: 'Contracts',
      dataIndex: 'contracts',
      render: (contracts, record) => contracts.length ? <div style={{ width: '100%', textAlign: 'center' }}>{contracts.length}<br /></div> : null,
    },
    {
      title: 'Contact',
      dataIndex: 'phone',
      width: 250,
      render: (phone, record) => <React.Fragment>
        {!!record.phone && <React.Fragment><FaIcon icon={faPhoneRotary} /> {phone}<br /></React.Fragment>}
        {!!record.email && <React.Fragment><FaIcon icon={faEnvelope} /> {record.email}<br /></React.Fragment>}
        {!!record.contacts.length && <React.Fragment><FaIcon icon={faUser} /> {record.contacts.length}<br /></React.Fragment>}
      </React.Fragment>,
    },
    {
      title: 'Location',
      dataIndex: 'gps',
      align: 'center',
      width: 110,
      render: (gps, record) => <Avatar shape="square" size={64}
        src={`http://tools.mmcreation.com/osmap/static/64/96/3/${gps[0]}/${gps[1]}/tiny/blue.png?t=1`} />,
    },
  ];

  if (creationMode)
    return <PlaceForm modal item_id="new" account_id={account_id} onClose={handleCreationFormClose}
      onChange={handleCreationFormChange} />;



  return (
    <React.Fragment>
      {RegExp('/places/?$').test(window.location.pathname) ?
        <CsvImport modal={true} visible={importVisible} onClose={closeImport} />
        : null
      }
      <ApiCollectionTable
        title={(currentPageData) =>
          <React.Fragment>
            <b>{total} Hotels</b>
            <div style={{ float: "right" }}>
              <Button title="Ajouter un hôtel" style={{ marginRight: '1em' }} type="primary" size="small" onClick={() => {
                setCreationMode(true)
              }}>+</Button>
              {RegExp('/places/?$').test(window.location.pathname) ?
                <Button title="Importer un csv" style={{ marginRight: '1em' }} size="small" type="primary" onClick={() => setImportVisible(true)}><FaIcon
                  icon={faFileCsv} /></Button>
                : null
              }
              {RegExp('/places/?$').test(window.location.pathname) ?
                <ExportCsv fetch_endpoint={`mmc_places/exportData`} params={{ noExtension: true, search: { ...customSearch } }} />
                : null
              }
            </div>
          </React.Fragment>}
        fetch_endpoint={account_id ? `mmc_accounts/${account_id}/places` : "mmc_places"}
        expandedRowRender={item => <React.Fragment>
          <p><strong>Place ID:</strong> {item.gPlaceId}</p>
          {item.rating ? <p>Note : {item.rating}/5 ({item.userRatingsTotal} avis)</p> : null}
          {item.types && item.types.length ? <p>{item.types.map((item, i) => <Tag key={i}>{item}</Tag>)}</p> : null}
          <div>
          <p><strong>Tags :</strong></p>
          {
              <ApiCollectionTags
                fetch_endpoint={`mmc_places/${item.id}/tags`}
                tag_enpoint='tags'
                customSearch={{ type: 1 }}
                baseData={{ type: 1 }}
                delete_endpoint='tags'
                relation='places' item={item}
              />
            }
          </div>
        </React.Fragment>
        }
        columns={columns}
        contentVersion={contentVersion}
        customSearch={customSearch}
        setTotal={setTotal}
        delete_endpoint={"mmc_places"}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        {...rest}
      />
    </React.Fragment>
  );
};

export default PlaceList;

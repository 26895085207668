import React, { useEffect, useState } from "react";
import { useApiContext } from "../providers/ApiProvider";
import { Link, useParams } from "react-router-dom";
import { Spin, Typography } from "antd";
import Categories from "../components/Support/Frontend/Categories";
import Search from "../components/Support/Frontend/Search";
import { getLanguage, translate } from "../components/Support/helpers";

const SupportProduct = () => {
  const [apiState, apiDispatch] = useApiContext();
  const { apiFetchCollection } = apiDispatch;
  const params = useParams();
  const lng = getLanguage(params.lng);

  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [promotions, setPromotions] = useState([]);

  useEffect(() => {
    const id = params.product.split("-").shift();

    fetch(id);
    fetchPromotion(id);
  }, [params.id]);

  async function fetch(id) {
    setLoading(true);
    const data = { anonymously: true, search: { "exists[parent]": false, 'pagination': false } };
    const response = await apiFetchCollection(
      `support_products/${id}/categories`,
      data
    );
    setCategories(response["hydra:member"]);
    setLoading(false);
  }

  async function fetchPromotion(id) {
    const response = await apiFetchCollection(
      `support_products/${id}/articles`,
      {
        anonymously: true,
        search: { "exists[promotionDate]": true, "activeLngs": lng },
      }
    );
    console.log(response);
    setPromotions(response["hydra:member"]);
    setLoading(false);
  }

  return (
    <div>
      <Search />
      {!!promotions.length && (
        <div className="articles-container" style={{marginBottom: '2em'}}>
          <div className="header-articles">
              <div className="text-block">
                <h1>Nouveautés :</h1>
              </div>
            </div>

          <div className="articles">
            {promotions.map((article) => (
              <div key={article.id} className="article">
                <Link
                  to={`/${lng}/support/${params.product}/article/${article.id}`}>
                  <h2>{translate(article, "title", lng)}</h2>
                  {translate(article, "description", lng) ? (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: translate(article, "description", lng),
                      }}
                    />
                  ) : (
                    <Typography.Paragraph ellipsis={{ rows: 2 }}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: translate(article, "content", lng),
                        }}
                      />
                    </Typography.Paragraph>
                  )}
                </Link>
              </div>
            ))}
          </div>
        </div>
      )}
      <Spin spinning={loading}>
        <Categories categories={categories} />
      </Spin>
    </div>
  );
};

export default SupportProduct;

import React, { useState, useEffect } from 'react';
import {useParams, Link, Redirect} from 'react-router-dom'
import {  Breadcrumb, Card } from 'antd'

import FaIcon from '../components/FaIcon.js';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner'
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle'

import { useApiContext } from '../providers/ApiProvider.js';

import ContactList from '../components/Contact/ContactList.js';

const Place = (props) => {

  const [apiState, apiDispatch] = useApiContext();
  const { apiFetchEntity } = apiDispatch;

  const [place, setPlace ] = useState(null)
  const [loading, setLoading ] = useState(true)
  const [redirection, setRedirection] = useState(false);

  const params = useParams();

  useEffect(() => {
    // fetch at startup and whenever needUpdate is changed
    fetchData();
  },[])

  async function fetchData() {
    const place_response = await apiFetchEntity('mmc_places',params.place_id);
    if (place_response['@type'] ==="hydra:Error") {
      setRedirection(true)
    }
    setPlace(place_response)
    setLoading(false);
  }

  if(loading)
    return <FaIcon icon={faSpinner} spin />

  if (redirection)
    return <Redirect to={{
      pathname: "/erreur",
      search: "?utm=your+face",
      state: {status:'404', subtitle:'Cette hotel n\'existe pas', page:'./places' }
    }} />


  if (place['@id']) {
    let cardTitle = (
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/places/">Hotels</Link>
        </Breadcrumb.Item>
        {
          place.account && place.account.cluster ?
            <Breadcrumb.Item>
              <Link to={"/cluster/" + place.account.cluster.id}>{place.account.cluster.name}</Link>
            </Breadcrumb.Item>
            : null
        }
        {
          place.account ?
            <Breadcrumb.Item>
              <Link to={"/account/" + place.account.id}>{place.account.name}</Link>
            </Breadcrumb.Item>
            : null
        }
        <Breadcrumb.Item>
          <Link to={"/place/" + place.id}>{place.name}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={"/place/"+place.id+"/contacts"}>Contacts</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
    );

    return (
      <div>
        <Card title={cardTitle}>
          <ContactList scroll_x={1000} id={place.id} endpoint="mmc_places"/>
        </Card>
      </div>
    );
  }
  return <FaIcon icon={faExclamationTriangle} />
};

export default Place;

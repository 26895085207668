import React, {useEffect, useState} from 'react';
import {useApiContext} from "../providers/ApiProvider";
import {Spin} from "antd";
import Search from "../components/Support/Frontend/Search";
import {Link, useParams} from "react-router-dom";
import FaIcon from "../components/FaIcon";
import {faQuestionCircle} from "@fortawesome/pro-light-svg-icons/faQuestionCircle";
import {getLanguage, slugify} from "../components/Support/helpers";

const Support = () => {

  const [apiState, apiDispatch] = useApiContext();
  const {apiFetchCollection} = apiDispatch;
  const params = useParams();
  const lng = getLanguage(params.lng);

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch();
  }, [])

  async function fetch() {
    setLoading(true);
    const data = {anonymously: true}
    const response = await apiFetchCollection('support_products', data);
    setProducts(response['hydra:member']);
    setLoading(false);
  }

  return (
    <div>
      <Spin spinning={loading}>
        <div className="categories-container">

          {products.map(product => (
            <div key={product.id} className="category">
              <Link to={`/${lng}/support/${product.id}-${slugify(product.name)}`}>
                <div className="category-content">
                  <div className="img-block">
                    {product.image ? <img src={product.image}/>
                      : <FaIcon icon={faQuestionCircle}/>}
                  </div>
                  <div className="text-block">
                    <h2>{product.name}</h2>
                    <div>
                      <span>{product.categories.length} catégorie(s) dans cette collection, </span>
                      <span>{product.articles.length} article(s) dans cette collection</span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}

        </div>
      </Spin>

    </div>
  );
};

export default Support;

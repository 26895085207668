import React, {useState} from 'react'

import FormSelect from '../Form/FormSelect.js'
import FormInput from '../Form/FormInput.js'

import {Button, Col, Form, Row} from 'antd'

export default function LogFilters({customSearch, setCustomSearch}) {

  const [_item, setItem] = useState({...customSearch})
  const [contentVersion, setContentVersion] = useState(0)

  async function handlePropertyChange(chunk) { // {property:value}
    // console.log(chunk)
    setItem(Object.assign(_item, chunk));
    if (chunk.hasOwnProperty('place.name')) {
      handleFilter()
    }
  }

  const handleFilter = () => {

    let customFilters = {}
    customFilters['place.name'] = typeof _item['place.name'] === 'string' ? _item['place.name'] : '';
    if (typeof _item.action === 'string')
      customFilters.action = _item.action;
    if (typeof _item['contract.product.id'] === 'object')
      customFilters['contract.product.id'] = _item['contract.product.id'].id;

    setCustomSearch(customFilters);
  }

  const resetFilters = () => {
    setItem({})
    setCustomSearch({})
    setContentVersion((new Date()).getTime());
  }

  return (
    <div style={{marginBottom: '1em'}}>
      <h3>Filters</h3>
      <Row type="flex" gutter={[16, 16]}>
        <Col span={8}>
          <Form.Item label="Nom de l'hotel : ">
            <FormInput
              name="place.name"
              value={_item['place.name']}
              style={{width: "300px"}}
              onChange={handlePropertyChange}
              contentVersion={contentVersion}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Produit : ">
            <FormSelect
              name="contract.product.id"
              value={_item["contract.product.id"]}
              style={{width: "300px"}}
              allowClear
              onChange={handlePropertyChange}
              options_endpoint={'products'}
              option_formater={(item) => {
                return {value: item.id, label: `${item.name}  (${item.tag.name})`};
              }}
              search_property="name"
              contentVersion={contentVersion}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Action ">
            <FormSelect
              name="action"
              value={_item.action}
              allowClear
              placeHolder={'Action'}
              onChange={handlePropertyChange}
              options={[{value: 'create', label: 'create'}, {value: 'missing', label: 'missing'}]}
              style={{width: 100, marginRight: "1em"}}
            />
          </Form.Item>
        </Col>
      </Row>
      <Button style={{marginRight: '1em'}} onClick={resetFilters}>Reset</Button>
      <Button type="primary" onClick={handleFilter}>Filter</Button>
    </div>
  )
}

import React from 'react';
import ErrorPlaceHolder from '../components/ErrorPlaceHolder'
import {Breadcrumb, Card} from "antd";
import {Link} from "react-router-dom";

const Error = (props) => {

  let cardTitle = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Link to="/">Home</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        Error
      </Breadcrumb.Item>
    </Breadcrumb>
  );

  return (
    <>
      <Card title={cardTitle}>
        <ErrorPlaceHolder code={props.location.state !== null ? props.location.state.status : null}
                          titre={props.location.state !== null ? props.location.state.title : null}
                          message={props.location.state !== null ? props.location.state.subtitle : null}
                          page={props.location.state !== null ? props.location.state.page : null}
                          action={props.history.goBack}
        />
      </Card>
    </>

  );

};

export default Error;

import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom'
import {Button} from 'antd';

import {useApiContext} from '../../providers/ApiProvider.js';
import ApiCollectionTable from '../ApiCollectionTable.js';


const SegmentList = ({scroll_x, account_id, customSearch = {},currentPage, setCurrentPage, ...rest}) => {

  const [creationMode, setCreationMode] = useState(false)
  const [totalPlaces, setTotalPlaces] = useState(0);
  const [totalContacts, setTotalContacts] = useState(0);
  const [importVisible, setImportVisible] = useState(false)
  const [apiState, apiDispatch] = useApiContext();
  const {apiFetchCollection} = apiDispatch;

  const [contentVersion, setContentVersion] = useState(0)

  const handleCreationFormClose = () => {
    setCreationMode(false);
  }
  const handleCreationFormChange = () => {
  }

  useEffect(() => {
    getTotal()

  }, [])

  const getTotal = async () => {

    const response = await apiFetchCollection('check-emails', {noExtension: true})
    console.log(response)
    console.log(typeof response.contacts)
    if (response.status === 'success') {
      setTotalContacts(parseFloat(response.contacts))
      setTotalPlaces(parseFloat(response.places))
    }
  }



  const columns = [
    {
      title: 'Nom',
      dataIndex: 'name',
      width: 250,
      render: (name, record) => <Link to={`/segment/${record.id}`}>{name}</Link>,
    },
    {
      title: 'Id Mail Jet',
      dataIndex: 'mailjetId',
      render: (mailjetId, record) => mailjetId,
    },
  ];
                      
  return (
    <>
      <ApiCollectionTable
        title={(currentPageData) =>
          <React.Fragment>
            <b>Segments <span title={`Contacts: ${totalContacts}; Hotels: ${totalPlaces}`}>({totalContacts + totalPlaces})</span></b>
            <div style={{float: "right"}}>
              <Button title="Créer un segment" style={{marginRight: '1em'}} type="primary" size="small" href={'/segment/new'}>+</Button>
            </div>
          </React.Fragment>}
        fetch_endpoint={"segments"}
        columns={columns}
        contentVersion={contentVersion}
        customSearch={customSearch}
        delete_endpoint={"segments"}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        {...rest}
      />
    </>
  );
};

export default SegmentList;

import React from 'react';
import {Layout} from "antd";
import Sidebar from "../../Sidebar";

const SupportLayout = ({ children, ...rest }) => {
  return (
    <Layout className="root-layout" style={{ minHeight: '100vh' }}>
      {rest.sidebar && <Sidebar />}
      <Layout style={{padding:'1em'}}>
        {children}
      </Layout>
    </Layout>
  );
};

export default SupportLayout;

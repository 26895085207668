import React, {useEffect, useState} from 'react';
import {Link, Redirect, useParams} from 'react-router-dom'
import {Breadcrumb, Card, Col, Row} from 'antd'

import FaIcon from '../components/FaIcon.js';
import {faSpinner} from '@fortawesome/pro-solid-svg-icons/faSpinner'
import {faExclamationTriangle} from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle'

import {useApiContext} from '../providers/ApiProvider.js';
import ClusterCard from '../components/Cluster/ClusterCard.js';
import AccountDash from '../components/Account/AccountDash.js';
import ContactDash from '../components/Contact/ContactDash.js';
import CommentDash from '../components/Comment/CommentDash.js';

const Cluster = (props) => {

  const [apiState, apiDispatch] = useApiContext();
  const {apiFetchEntity} = apiDispatch;

  const [cluster, setCluster] = useState(null)
  const [loading, setLoading] = useState(true)
  const [redirection, setRedirection] = useState(false);

  const params = useParams();

  useEffect(() => {
    // fetch at startup and whenever needUpdate is changed
    fetchData();
  }, [])

  async function fetchData() {
    let hydra_response = await apiFetchEntity('mmc_clusters', params.cluster_id);

    if (hydra_response['@type'] ==="hydra:Error") {
      setRedirection(true)
    }
    setCluster(hydra_response)
    setLoading(false);
  }

  if (loading || !cluster)
    return <FaIcon icon={faSpinner} spin/>

  if (redirection)
    return <Redirect to={{
      pathname: "/erreur",
      search: "?utm=your+face",
      state: {status:'404', subtitle:'Ce groupe n\'existe pas', page:'./clusters' }
    }} />

  if (cluster.id) {
    let cardTitle = (
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/clusters/">Groupe</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={"/cluster/" + cluster.id}>{cluster.name}</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
    );


    return (
      <Card title={cardTitle}>
        <Row type="flex" gutter={[16, 16]}>
          <Col span={16}>
            <Row type="flex" gutter={[16, 16]}>
              <Col span={12}>
                <ClusterCard item={cluster} onUpdate={updatedItem => setCluster(updatedItem)}/>
              </Col>
              <Col span={12}>
                <AccountDash cluster_id={params.cluster_id}/>
              </Col>
              <Col span={12}>
                <ContactDash id={cluster.id} endpoint="mmc_clusters"/>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <CommentDash id={cluster.id} endpoint="mmc_clusters"/>
          </Col>
        </Row>
      </Card>
    );
  }
  return <FaIcon icon={faExclamationTriangle}/>
};

export default Cluster;

import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, Row, Col, AutoComplete, Input } from 'antd'

import FaIcon from '../FaIcon.js';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner'
import {faSearch} from '@fortawesome/pro-solid-svg-icons/faSearch'

import { useApiContext } from '../../providers/ApiProvider.js';

import FormInput from '../Form/FormInput.js'
import FormSelect from '../Form/FormSelect.js'
import { endpointResolver } from './_helpers.js'

const ContactForm = ({ item, item_id, entity_id, endpoint, onClose, onChange, modal, ...rest }) => {

  entity_id = entity_id || false;
  item_id = item ? item.id : item_id;
  onClose = onClose || function () {};
  onChange = onChange || function () {};

  const [apiState, apiDispatch] = useApiContext();
  const { apiFetchEntity, apiUpdateEntity, apiCreateEntity, apiFetchCollection } = apiDispatch;

  const [_itemId, setItemId] = useState(item_id)
  const [_item, setItem] = useState(item ? { ...item } : null)
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [relation, setRelation] = useState(endpointResolver(endpoint))
  const [contentVersion, setContentVersion] = useState(0)

  useEffect(() => {
    // fetch at startup and whenever needUpdate is changed
    if (_itemId && !_item)
      fetchItem();
    else
      setLoading(false);
  }, [])

  async function fetchItem() {
    setLoading(true);
    if (_itemId === 'new') {
      if (entity_id)
        setItem({
          [relation]: {
            '@id': '/api/' + endpoint + '/' + entity_id,
            'id': entity_id
          }
        })
      else
        setItem({})
    } else {
      const response = await apiFetchEntity('mmc_contacts', _itemId);
      setItem({ ...response })
    }
    setLoading(false);
    return;
  }

  async function handlePropertyChange(chunk) { // {property:value}
    // console.log(chunk);
    setItem(Object.assign(_item, chunk));
  }

  const handleCancel = () => {
    onClose();
  }

  const handleSave = async () => {
    setSaving(true);
    if (_itemId === 'new') {
      _item.firstname = typeof _item.firstname === "string" ? _item.firstname : '';
      _item.lastname = typeof _item.lastname === "string" ? _item.lastname : '';
      _item.address = typeof _item.address === "string" ? _item.address : '';
      _item.phone = typeof _item.phone === "string" ? _item.phone : '';
      _item.mobilePhone = typeof _item.mobilePhone === "string" ? _item.mobilePhone : '';

      _item.email = typeof _item.email === "string" ? _item.email : '';
      _item.contactRole = typeof _item.contactRole === 'object' ? _item.contactRole['@id'] : null

      console.log(_item)
      const response = await apiCreateEntity('mmc_contacts', _item);
      if (response && response.id) {
        setItemId({ ...response.id })
        setItem({ ...response })
      }
    } else {
      _item.contactRole = typeof _item.contactRole === 'object' ? _item.contactRole['@id'] : null

      await apiUpdateEntity('mmc_contacts', _itemId, _item);
    }
    setSaving(false);
    onChange(Object.assign({}, _item));
  }

  const handleSaveAndClose = async () => {
    await handleSave();
    onClose();
  }

  const [contactsPropositions, setContactsPropositions] = useState([])
  const [searchTimeout, setSearchTimeout] = useState(null)

  const handleSearch = (q) => {
    setContactsPropositions([])
    clearTimeout(searchTimeout);
    setSearchTimeout(setTimeout(async () => {
      const response = await apiFetchCollection('sellsy/get-contacts', { noExtension: true, search: { q: q } })
      console.log(response)
      if (response && response.success == "1") {
        const contacts = Object.values(response.contacts) || []
        const predictions = contacts.map(function (v, i) {
          return {
            ...v,
            value: v.id,
            text: `${v.fullName}, ${v.email}`
          };
        })
        //console.log(predictions);
        setContactsPropositions(predictions)
      }
    }, 500));
  }

  const handleSelect = (id) => {
    setLoading(true)
    console.log(id)
    console.log(contactsPropositions)
    let contact = contactsPropositions.find(item => item.id == id)
    console.log(contact)
    let newContact = {
      ..._item,
      firstname: contact.forename,
      lastname: contact.name,
      email: contact.email,
      civil: contact.civil,
      phone: contact.tel,
      mobilePhone: contact.mobile,
      description: contact.position,
      sellsyId: contact.id
    }

    setItem({...newContact});
    setContentVersion((new Date()).getTime())
    setLoading(false)
  }


  const formContent = (
    !_item || loading || saving
      ? <React.Fragment><FaIcon icon={faSpinner} spin /> traitement en cours...</React.Fragment>
      : <Form layout="vertical">
        <Form.Item
          style={{ background: '#eee', padding: '0.5em' }}
          label={
            <Row gutter={16}>
              <Col span={8}>
                <strong>Recherche Sellsy </strong>
              </Col>
              <Col span={16}>
              <AutoComplete
                  onSelect={handleSelect}
                  onSearch={handleSearch}
                  dataSource={contactsPropositions}
                  dropdownMatchSelectWidth={false}
                >
                  <Input suffix={<FaIcon icon={faSearch} />} />
                </AutoComplete>
              </Col>
            </Row>
          }
        >
          <FormInput contentVersion={contentVersion} placeholder="" name="sellsyId" value={_item.sellsyId} onChange={(chunk) => handleSelect(chunk.sellsyId)}/>
        </Form.Item>
        <Form.Item label="Prénom">
          <FormInput contentVersion={contentVersion} name="firstname" value={_item.firstname} onChange={handlePropertyChange} />
        </Form.Item>
        <Form.Item label="Nom">
          <FormInput contentVersion={contentVersion} name="lastname" value={_item.lastname} onChange={handlePropertyChange} />
        </Form.Item>
        <Form.Item label="Téléphone">
          <FormInput contentVersion={contentVersion} name="phone" value={_item.phone} onChange={handlePropertyChange} />
        </Form.Item>
        <Form.Item label="Portable">
          <FormInput contentVersion={contentVersion} name="mobilePhone" value={_item.mobilePhone} onChange={handlePropertyChange} />
        </Form.Item>
        <Form.Item label="Email">
          <FormInput contentVersion={contentVersion} name="email" value={_item.email} onChange={handlePropertyChange} />
        </Form.Item>
        <Form.Item label="Rôle">
          <FormSelect name="contactRole"
            value={_item.contactRole}
            option_formater={(item) => {
              return { value: item['@id'], label: item.name };
            }}
            onChange={handlePropertyChange}
            options_endpoint={"contact_roles"}
            search_property="name" />
        </Form.Item>
        <Form.Item label="Description">
          <FormInput textarea contentVersion={contentVersion} name="description" value={_item.description} onChange={handlePropertyChange} />
        </Form.Item>
        <Form.Item>
          <Button key="cancel" onClick={handleCancel}>
            Close
          </Button>
          <Button style={{ marginLeft: 8 }} key="save" type="primary" onClick={handleSave}>
            Save
          </Button>
          <Button style={{ marginLeft: 8 }} key="saveAndClose" type="primary" onClick={handleSaveAndClose}>
            Save & Close
          </Button>
        </Form.Item>

      </Form>
  );

  if (modal) {
    return <Modal
      title={_itemId !== "new" ? _item.firstname + ' ' + _item.lastname : "Créer un nouveau contact"}
      visible={true}
      onCancel={handleCancel}
      footer={null}
    >
      {formContent}
    </Modal>
  } else {
    return formContent;
  }
};

export default ContactForm;

import React, { useState, useEffect } from 'react';
import { Row, Col, Avatar, Card, Collapse, Skeleton, Modal, Tag, Form, Button, AutoComplete, Input } from 'antd'

import FaIcon from '../FaIcon.js';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner'

import { useApiContext } from '../../providers/ApiProvider.js';

import FormInput from '../Form/FormInput.js'

const ClusterForm = ({item, item_id, onClose, onChange, modal, ...rest}) => {

  item_id = item ? item.id : item_id;
  onClose = onClose || function(){};
  onChange = onChange || function(){};

  const [apiState, apiDispatch] = useApiContext();
  const { apiFetchEntity, apiUpdateEntity, apiCreateEntity } = apiDispatch;

  const [_itemId, setItemId ] = useState(item_id)
  const [_item, setItem ] = useState(item ? {...item} : null)
  const [loading, setLoading ] = useState(true)
  const [saving, setSaving ] = useState(false)

  useEffect(() => {
    // fetch at startup and whenever needUpdate is changed
    if(_itemId && !_item)
      fetchItem();
    else
      setLoading(false);
  },[])

  async function fetchItem() {
    setLoading(true);
    if(_itemId==='new')
    {
      setItem({})
    }
    else
    {
      const response = await apiFetchEntity('mmc_clusters',_itemId);
      setItem({...response})
    }
    setLoading(false);
    return;
  }

  async function handlePropertyChange(chunk) { // {property:value}
    //console.log(chunk);
    setItem(Object.assign(_item, chunk));
  }

  const handleCancel = () => {
    onClose();
  }

  const handleSave = async () => {
    setSaving(true);
    if(_itemId==='new')
    {
      const response = await apiCreateEntity('mmc_clusters',_item);
      if(response && response.id)
      {
        setItemId({...response.id})
        setItem({...response})
      }
    }
    else
    {
      await apiUpdateEntity('mmc_clusters',_itemId,_item);
    }
    setSaving(false);
    onChange(Object.assign({},_item));
  }

  const handleSaveAndClose = async () => {
    await handleSave();
    onClose();
  }

  const formContent = (
    !_item || loading || saving
    ? <React.Fragment><FaIcon icon={faSpinner} spin /> traitement en cours...</React.Fragment>
    : <Form layout="vertical">
        <Form.Item label="Name">
          <FormInput clusterholder="" name="name" value={_item.name} onChange={handlePropertyChange}/>
        </Form.Item>
        <Form.Item label="Description">
          <FormInput textarea={true} rows={8} clusterholder="" name="description" value={_item.description} onChange={handlePropertyChange}/>
        </Form.Item>
        <Form.Item>
          <Button key="cancel" onClick={handleCancel}>
            Close
          </Button>
          <Button style={{ marginLeft: 8 }} key="save" type="primary" onClick={handleSave}>
            Save
          </Button>
          <Button style={{ marginLeft: 8 }} key="saveAndClose" type="primary" onClick={handleSaveAndClose}>
            Save & Close
          </Button>
        </Form.Item>
      </Form>
  );

  if(modal)
  {
    return <Modal
      title={_item && _item.name ? _item.name : "..."}
      visible={true}
      onCancel={handleCancel}
      footer={null}
      >
      {formContent}
    </Modal>
  }
  else
  {
    return formContent;
  }
};

export default ClusterForm;

import React, { useEffect, useState } from 'react';
import { message, Modal, Tree } from "antd";
import { useApiContext } from "../../../providers/ApiProvider";
import { useParams } from "react-router-dom";
import { sortArticles } from "../helpers";

const ModalRankingCategory = ({ visible, handleClose }) => {

  const [apiState, apiDispatch] = useApiContext();
  const { apiFetchCollection, apiPostRequest } = apiDispatch;
  const params = useParams();

  const [categories, setCategories] = useState([])
  const [treeData, setTreeData] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    fetch();
  }, [])

  const fetch = async () => {
    setLoading(true)

    const productId = params.product.split('-').shift();
    const data = {
      search: { "exists[parent]": false, 'order[ranking]': 'asc', 'pagination': false }
    };
    const hydra_response = await apiFetchCollection(`support_products/${productId}/categories`, data);

    setCategories(hydra_response['hydra:member'])
    setTreeData(makeTreeData(hydra_response['hydra:member'], '0-', []))
    setLoading(false);
  }

  function makeTreeData(data, key, exp) {
    var tab = []

    data.sort(sortArticles).map((d, id) => {
      var row = { title: d.nameFr, key: key + id, id: d.id }
      if (d.children.length) {
        row.children = makeTreeData(d.children, key + id + "-", exp)
        exp.push(key + id)
      }
      tab.push(row)
    });

    return tab
  }

  function onDrop(info) {
    const dropKey = info.node.props.eventKey;
    const dragKey = info.dragNode.props.eventKey;
    const dropPos = info.node.props.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

    const loop = (data, key, callback) => {
      data.forEach((item, index, arr) => {
        if (item.key === key) {
          return callback(item, index, arr);
        }
        if (item.children) {
          return loop(item.children, key, callback);
        }
      });
    };
    const data = [...treeData];

    // Find dragObject
    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到尾部，可以是随意位置
        item.children.push(dragObj);
      });
    } else if (
      (info.node.props.children || []).length > 0 && // Has children
      info.node.props.expanded && // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
      });
    } else {
      let ar;
      let i;
      loop(data, dropKey, (item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }

    setTreeData(data)
  }

  const save = async () => {
    let data = extractData(treeData, null)
    let params = {
      entity: 'category',
      data: data
    }
    let response = await apiPostRequest('support-update-ranking', params)
    console.log(response)
    if (response.success) {
      message.success('Ordre mis à jour avec succes')
      handleClose(true)
    } else {
      message.error('Il y a eu une erreur')
    }

  }

  const extractData = (tree, parent) => {
    let data = []
    tree.map((item, index) => {
      console.log(item)
      let childrenInfo = []
      if (item?.children?.length) {
        childrenInfo = extractData(item.children, item.id);
      }
      let itemInfo = {
        id: item.id,
        ranking: index,
        parent: parent
      }
      data = [...data, ...childrenInfo, itemInfo]
    })
    return data;
  }

  console.log(treeData)

  return (
    <Modal
      title="Trier les catégories"
      visible={visible}
      onOk={save}
      okText="Enregistrer" cancelText='Fermer' onCancel={handleClose}
    >
      <Tree
        draggable
        treeData={treeData}
        onDrop={onDrop}
      />
    </Modal>
  );
};

export default ModalRankingCategory;

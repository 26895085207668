import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Breadcrumb, Card, Button } from 'antd'

import CommentList from '../components/Comment/CommentList.js';
import CommentFilters from "../components/Comment/CommentFilters";
import {checkLocalStorage, saveFilterInStorage} from "../components/_helpers";
import PlaceList from "../components/Place/PlaceList";

const Comments = () => {

  const [customSearch, setCustomSearch] = useState(checkLocalStorage('commentFilters', {}))

  useEffect(() => {
    if (customSearch) {
      saveFilterInStorage('commentFilters', customSearch)
    }
  }, [customSearch])

  let cardTitle = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Link to="/">Home</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Link to="/comments/">Commentaires</Link>
      </Breadcrumb.Item>
    </Breadcrumb>
  );

  return (
    <Card title={cardTitle}>
      <CommentFilters customSearch={customSearch} setCustomSearch={setCustomSearch}/>
      <CommentList customSearch={customSearch}/>
    </Card>
  );
};

export default Comments;

import React from 'react'
import FormSelect from '../Form/FormSelect'
import { Button, message } from 'antd'
import FormInput from '../Form/FormInput.js'
import FaIcon from '../FaIcon'
import { faCheck, faTrash } from '@fortawesome/pro-solid-svg-icons'

export default function HasContractConditions({ contracts, setContracts, addHasContract }) {

    const updateContracts = (id, newItem) => {
        setContracts(prevState => prevState.map(item => item.key === id ? newItem : item))
    }


    const handlePropertyChange = (id, chunk) => {
        console.log(chunk)
        let prevContracts = [...contracts]

        let newContracts = prevContracts.map(item => {
            if (item.key === id) {
                return { ...item, ...chunk }
            }
            return item
        })
        console.log(newContracts)
        setContracts([...newContracts])

    }



    const removeItem = (key) => {
        setContracts(prev => prev.filter(item => item.key !== key))
    }

    if (!contracts) return null;

    return (
        <div style={{ margin: "1em 0" }}>
            <h4>Avec des contrats: </h4>
            {
                contracts && contracts.length > 0 &&
                contracts.map(item =>
                    <div style={{ marginBottom: '1em' }} key={item.key}>
                        <Button onClick={() => removeItem(item.key)} style={{ marginRight: "1em" }} size="small"><FaIcon icon={faTrash} /></Button>
                        {
                            item.id !== null && <FaIcon icon={faCheck} style={{ marginRight: '1em' }} />
                        }
                        {
                            <FormSelect
                                name="contracts"
                                value={item.id}
                                style={{ width: "200px", marginRight: '1em' }}
                                onChange={(chunk) => handlePropertyChange(item.key, chunk.contracts)}
                                options_endpoint={'products'}
                                option_formater={(item) => {
                                    return { value: item['id'], label: `${item.name} ( ${item.tag.name} )` };
                                }}
                                search_property="name"
                            // contentVersion={contentVersion}
                            />
                        }
                        {
                            !item.status ?
                                <Button onClick={() => updateContracts(item.key, { ...item, status: 'start', since: 'more' })}>+</Button>
                                :
                                <>
                                    <FormSelect
                                        name="status"
                                        value={item.status}
                                        placeHolder="Qui a"
                                        onChange={(chunk) => handlePropertyChange(item.key, chunk)}
                                        options={[{ value: 'start', label: 'Démarré' }, { value: 'end', label: 'Fini' }]}
                                        style={{ marginRight: "1em", width: 100 }}
                                    />
                                    <FormSelect
                                        name="since"
                                        value={item.since}
                                        placeHolder="Depuis"
                                        onChange={(chunk) => handlePropertyChange(item.key, chunk)}
                                        options={[{ value: 'less', label: 'Moins de' }, { value: 'more', label: 'Plus de' }]}
                                        style={{ marginRight: "1em", width: 100 }}
                                    />
                                    <FormInput
                                        name="months"
                                        value={item.months}
                                        style={{ width: "100px" }}
                                        onChange={(chunk) => handlePropertyChange(item.key, chunk)}
                                    // contentVersion={contentVersion}
                                    />
                                    &nbsp; mois
                                </>
                        }
                    </div>
                )
            }

            <Button className="mr-1" onClick={addHasContract}>Ajouter un autre contrat</Button>
        </div>
    )

}

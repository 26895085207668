import React from 'react'
import { Button, Checkbox, Card } from 'antd'

export default function StarsConditions({ stars, setStars }) {

    const options = [
        { label: '1 étoile', value: 1 },
        { label: '2 étoiles', value: 2 },
        { label: '3 étoiles', value: 3 },
        { label: '4 étoiles', value: 4 },
        { label: '5 étoiles', value: 5 },
    ];
    const onChange = (value) => {
        console.log(value)
        setStars(value)
    }

    if (!stars) return null

    return (
        <div style={{margin : "1em 0"}}>
            <h4>Selon les étoiles: </h4>
            {
                stars &&
                    <div>
                        <h4>Les hotels ayant: </h4>
                        <Checkbox.Group options={options} value={stars} onChange={onChange} />
                    </div>
            }
        </div>
    )
}

import React, { useState} from 'react';
import {Button, message} from 'antd'
import FaIcon from "./FaIcon";
import {faFileDownload} from "@fortawesome/pro-solid-svg-icons/faFileDownload";
import {useApiContext} from "../providers/ApiProvider";

const ExportCsv = ({fetch_endpoint, params}) => {

  const [loading, setLoading] = useState(false)
  const [apiState, apiDispatch] = useApiContext();
  const {apiFetchCollection} = apiDispatch;


  const fetch = async () => {

    setLoading(true)

    let hydra_response = await apiFetchCollection(fetch_endpoint, params);
    if (hydra_response['@type'] === 'hydra:Error') {
      return message.error('Il y a eu une erreur');
    }
    const filename = 'export-wallet.csv'
    const link = document.createElement('a');
    link.href = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(hydra_response);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setLoading(false);
  }

  return (
    <>
      <Button title={"Exporter en csv"} size="small" type="primary" loading={loading} onClick={fetch}><FaIcon
        icon={faFileDownload}/></Button>
    </>
  );


};

export default ExportCsv;

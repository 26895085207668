import React, { useState } from 'react';
import { faChevronDown, faChevronRight, faEdit, faLink, faTrash } from '@fortawesome/pro-solid-svg-icons';
import FaIcon from '../../FaIcon';
import { Link } from 'react-router-dom';
import { slugify, sortArticles } from '../helpers';

export default function CategoryItem({ item, level, setSelected, selected, display = true, setEditing, handleDelete }) {

    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = (e) => {
        e.stopPropagation()
        setIsOpen(prev => !prev);
    }

    const handleEdit = (e) => {
        e.stopPropagation()
        setEditing(item.id)
    }

    const handleDeleteItem = (e) => {
        e.stopPropagation()
        handleDelete(item.id)
    }

    const getTotalarticles = (item) => {
        let totalArticles = item.articles.length || 0;

        if (item.children && item.children.length > 0) {
            for (let i = 0; i < item.children.length; i++) {
                totalArticles += getTotalarticles(item.children[i]);
            }
        }

        return totalArticles;
    };

    return (
        <ul className='categoryContainer'
            style={display ? { display: 'block' } : { display: 'none' }}>
            <div className={`categoryItem flex between ${selected === item.id && 'isSelected'}`}
                style={{ paddingLeft: level * 16 + "px", }}
                onClick={() => setSelected(item.id)}
            >
                <div className='ml-1 flex align-center' title={item.nameFr}>
                    {
                        isOpen ?
                            <FaIcon style={item.children.length ? null : { opacity: 0 }} icon={faChevronDown} className="mr-1" onClick={handleOpen} />
                            : <FaIcon style={item.children.length ? null : { opacity: 0 }} icon={faChevronRight} className="mr-1" onClick={handleOpen} />
                    }
                    <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', height: 30 }}>{item.nameFr}</div>
                </div>
                <span className='amount mr-1'>{getTotalarticles(item)}</span>
                <span className='actions' >
                    <FaIcon icon={faEdit} onClick={handleEdit} className="mr-sm" />
                    <Link to={`/fr/support/${item.product.id}-${slugify(item.product.name)}/category/${item.id}`} target="_blank" rel="noopener noreferrer">
                        <FaIcon icon={faLink} onClick={(e) => e.stopPropagation()} className="mr-sm" />
                    </Link>
                    {
                        !item.children.length && !item.articles.length &&
                        <FaIcon icon={faTrash} onClick={handleDeleteItem} style={{ marginRight: '.5em', color: 'red' }} />
                    }

                </span>
            </div>
            {
                item.children.sort(sortArticles).map(child =>
                    <CategoryItem level={level + 1} item={child} selected={selected} setSelected={setSelected} display={isOpen} setEditing={setEditing} key={child.id} handleDelete={handleDelete}/>
                )
            }
        </ul>
    );
}

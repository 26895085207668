import {Modal, Form, AutoComplete, Input, Button, message} from "antd";
import React, {useState} from "react";

import {useApiContext} from '../../providers/ApiProvider.js';
import {faSearch} from '@fortawesome/pro-solid-svg-icons/faSearch'
import FaIcon from '../FaIcon.js';
import {faSpinner} from "@fortawesome/pro-solid-svg-icons/faSpinner";


export default function SellsyConnect({account, onCancel}) {

  const [selected, setSelected] = useState(null)
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState()
  const [apiState, apiDispatch] = useApiContext();
  const {apiFetchCollection, apiPostRequest, apiUpdateEntity} = apiDispatch;
  const [appSearchPropositions, setAppSearchPropositions] = useState([])
  const [appSearchTimeout, setAppSearchTimeout] = useState(null)
  const [createSellsyFromWallet, setCreateSellsyFromWallet] = useState(false)

  const handleChange = async (q) => {
    setLoading(true)
    setSelected(null)
    setValue(q)
    setAppSearchPropositions([])
    clearTimeout(appSearchTimeout);
    setAppSearchTimeout(setTimeout(async () => {
      const response = await apiFetchCollection('sellsy/get-clients', {noExtension: true, search: {name: q}})

      if (response && response.success == "1") {
        const clients = Object.values(response.clients) || []
        const predictions = clients.map(function (v, i) {
          return {value: v.id, text: v.name};
        })
        //console.log(predictions);
        setAppSearchPropositions(predictions)
      }
      setLoading(false)
    }, 500));

  }

  const handleSelect = (id) => {
    const selection = appSearchPropositions.find(item => item.value == id)
    if (selection) setSelected(selection)
  }

  const handleValidation = async (action) => {
    setLoading(true)
    let response;

    if (action === 'link') {
      response = await apiUpdateEntity('mmc_accounts', account.id, {sellsyId: selected.value})
    } else if (action === 'create') {
      const responsePost = await apiPostRequest('sellsy/post-client', {id: account.id, name: value.trim()})

      if (responsePost.success) {
        const postContact = await apiPostRequest('sellsy/link-contact', {accountId: account.id});
      }

      response = responsePost.account || null
    }

    if (response.sellsyId) {
      message.success(`Compte ${action === 'link' ? 'lié' : 'crée'} avec success`)
      setLoading(false)
      return onCancel()
    } else {
      message.error('Il y a eu une erreur')
    }
    setLoading(false)
  }

  function handleModeChange() {
    if (createSellsyFromWallet) {
      setCreateSellsyFromWallet(false);
      setValue("")
    } else {
      setCreateSellsyFromWallet(true);
      setValue(account.name)
      setSelected(null)
    }
  }

  return (
    <Modal title="Relier cette société à Sellsy" visible
           onCancel={onCancel}
           footer={[
             <Button key="back" onClick={onCancel}>
               Retour
             </Button>
           ]}>
      {
        !account?.id ? "Une société est obligatoire pour la liaison Sellsy" :
          <>
            {!createSellsyFromWallet &&
            <Form.Item hasFeedback={false}>
              <AutoComplete
                placeholder="Rechercher compte existant"
                onSelect={handleSelect}
                onSearch={handleChange}
                dataSource={appSearchPropositions}
                dropdownMatchSelectWidth={false}
                style={{width: 300}}
              >
                <Input suffix={loading ? <FaIcon icon={faSpinner} spin/> : <FaIcon icon={faSearch}/>}/>
              </AutoComplete>
            </Form.Item>}

            {createSellsyFromWallet &&
            <Input
              value={value}
              onChange={e => setValue(e.target.value)}
            />}

            {
              selected && !createSellsyFromWallet &&
              <div>
                <p>Compte sélectionné : {selected.text}</p>
                <Button onClick={() => handleValidation('link')} disabled={loading} type="primary">
                  Lier
                </Button>
              </div>
            }

            {
              !selected && createSellsyFromWallet &&
              <div>
                <p>Créer le compte avec le nom : {value}</p>
                <Button
                  type="primary"
                  onClick={() => handleValidation('create')}
                  disabled={loading}
                >
                  Créer
                </Button>
              </div>
            }

            <div style={{marginTop: 50}}>
              <Button
                type="primary"
                onClick={handleModeChange}
              >
                {createSellsyFromWallet ?
                  "Ou rechercher un compte existant sur Sellsy" :
                  "Ou creer un compte sur Sellsy depuis cette société"}
              </Button>
            </div>
          </>
      }
    </Modal>
  )
}

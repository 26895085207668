import React, {useState} from 'react';
import {Link} from 'react-router-dom'
import {Button} from 'antd';

import ApiCollectionLatest from '../ApiCollectionLatest.js';
import TagForm from "./TagForm";
import {typeFormatter} from "./_helpers";

const TagDash = ({account_id, type, ...rest}) => {

  const [selectedItem, setSelectedItem] = useState(null)
  const [creationMode, setCreationMode] = useState(false)

  const [contentVersion, setContentVersion] = useState(0)

  const handleCreationFormClose = () => {
    setCreationMode(false);
  }
  const handleCreationFormChange = () => {
  }

  const handleEditFormClose = () => {
    setSelectedItem(null);
  }
  const handleEditFormChange = () => {
    setContentVersion((new Date()).getTime());
  }

  const columns = [
    {
      title: '',
      dataIndex: 'name',
      search: true,
      sorter: true,
      render: (name, record) => <React.Fragment>
        {name}
      </React.Fragment>
    },
    {
      title: '',
      dataIndex: 'type',
      search: true,
      sorter: true,
      render: (type, record) => <React.Fragment>
        {typeFormatter(type, 'display')}
      </React.Fragment>
    },
  ];

  if(creationMode)
    return <TagForm modal item_id="new" onClose={handleCreationFormClose} onChange={handleCreationFormChange} type={type}/>;

  return (
    <React.Fragment>
      <ApiCollectionLatest
        title={(currentPageData) => <React.Fragment>
          <Button size="small" onClick={() => {setCreationMode(true)}}>+</Button>
          <b> {typeFormatter(type, true)}</b>
          <Link style={{float: "right"}} to={"/tags/"+type}>See all</Link>
        </React.Fragment>}
        fetch_endpoint="tags"
        delete_endpoint="tags"
        columns={columns}
        pageSize={5}
        onClick={(item) => setSelectedItem({...item})}
        contentVersion={contentVersion}
        customSearch={type != null ? {type:type} : {}}
        {...rest}
      />
      {/*{selectedItem ? <ContractDrawer item={selectedItem} onClose={handleEditFormClose} onChange={handleEditFormChange} /> : null}*/}
    </React.Fragment>
  );
};

export default TagDash;

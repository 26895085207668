import React, { useState, useEffect } from 'react';
import { Row, Col, Avatar, Drawer, Card, Collapse, Skeleton, Modal, Tag, Form, Button, AutoComplete, Input } from 'antd'

import FaIcon from '../FaIcon.js';
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser'
import { faEdit } from '@fortawesome/pro-solid-svg-icons/faEdit'

import { useApiContext } from '../../providers/ApiProvider.js';

import ProductForm from './ProductForm.js';
import {externalFormatter} from "./_helpers";
import moment from "moment";


const ProductDrawer = ({item, item_id, edit, onChange, onClose, ...rest}) => {

  onChange = onChange || function(){};
  onClose = onClose || function(){};
  item_id = item ? item.id : item_id || null

  const [apiState, apiDispatch] = useApiContext();
  const { apiFetchEntity, apiUpdateEntity, apiDeleteEntity } = apiDispatch;

  const [_itemId, setItemId ] = useState(item_id)
  const [_item, setItem ] = useState({...item})
  const [loading, setLoading ] = useState(true)
  const [visible, setVisible ] = useState(true)
  const [displayForm, setDisplayForm ] = useState(edit)
  useEffect(() => {
    // fetch at startup and whenever needUpdate is changed
    if(_itemId && !_item)
      fetchItem();
    else
      setLoading(false);
  },[])

  async function fetchItem() {
    setLoading(true);
    const response = await apiFetchEntity('products',_itemId);
    setItem({...response})
    setLoading(false);
    return response;
  }

  async function deleteItem() {
    await apiDeleteEntity('products',_itemId);
    onChange();
    setVisible(false);
  }

  const handleChange = async (updatedItem) => {
    setItem(updatedItem)
    onChange(updatedItem);
  }

  if(!_item || loading)
  {
    return (
      <Card><Skeleton loading={true} active /></Card>
    );
  }

  let cardTitle = (
    <React.Fragment>
      <FaIcon icon={faUser} /> {_item.name}
    </React.Fragment>
  )

  return (
    <Drawer
      className="drawerWithDeleteButton"
      width={'min(90vw, 640px)'}
      placement="left"
      closable={false}
      visible={visible}
      onClose={()=>{ setVisible(false); }}
      afterVisibleChange={(visible)=>{ visible ? null : onClose(); }}
      title={
        <span>
              {_item.name}
          {displayForm ? null : <a onClick={event => setDisplayForm(true)} style={{float:'right'}}><FaIcon icon={faEdit} key="edit" /></a>}
            </span>
      }
    >
      {
        displayForm
          ? <ProductForm item={_item} onClose={() => { edit ? setVisible(false) : setDisplayForm(false); }} onChange={handleChange}/>
          : <React.Fragment>
            <p>Catégorie : {_item.tag.name}</p>
            <p>{externalFormatter(_item.external, 'display')}</p>
            <p>Detections : </p>
            {
              _item.detections.length > 0 ?
                <ul>
                  {item.detections.map(term => <li key={term}>"{term}"</li>) }
                </ul> :
                <p>Aucune règle de détections enregistrée</p>
             }
            <p>Endpoint : {_item.endpoint}</p>
            <p>Clé api : {_item.apiKey}</p>

            <Button className="drawerDeleteButton" type="danger" block onClick={deleteItem}>Delete</Button>
          </React.Fragment>
      }
    </Drawer>
  );
};

export default ProductDrawer;

import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import { Tag, Button } from 'antd';

import ApiCollectionLatest from '../ApiCollectionLatest.js';

import PlaceForm from './PlaceForm.js'
import FaIcon from '../FaIcon.js';
import {faLink} from "@fortawesome/pro-solid-svg-icons/faLink";
import PlaceLinkAccount from "./PlaceLinkAccount";


const PlaceDash = ({account_id, ...rest}) => {

  const [selectedItem, setSelectedItem ] = useState(null)
  const [creationMode, setCreationMode ] = useState(false)
  const [linkMode, setLinkMode ] = useState(false)

  const [contentVersion, setContentVersion ] = useState(0)

  const handleCreationFormClose = () => {
    setCreationMode(false);
  }
  const handleCreationFormChange = () => {
  }


  const columns = [
    {
      title: '',
      dataIndex: 'name',
      search: true,
      sorter: true,
      render: (name,record) => <React.Fragment>
        {name}<br/>
        <small>{record.address}, {record.zip} {record.city} - {record.country}</small><br/>
      </React.Fragment>
    }
  ];

  if(creationMode)
    return <PlaceForm modal item_id="new" account_id={account_id}  onClose={handleCreationFormClose} onChange={handleCreationFormChange}/>;

  if(linkMode)
    return <PlaceLinkAccount modal account_iri={`api/mmc_accounts/${account_id}`} onClose={() => setLinkMode(false)}  />

  return (
    <React.Fragment>
      <ApiCollectionLatest
        title={(currentPageData) => <React.Fragment>
          {
            account_id ? <Button size="small" onClick={()=>{setCreationMode(true)}}>+</Button> : null
          }
          {
            account_id ? <Button style={{marginLeft: '5px'}} size="small" onClick={()=>{setLinkMode(true)}}><FaIcon icon={faLink} /></Button> : null
          }
          <b> Hotels</b>
          <Link style={{float:"right"}} to={"/account/"+account_id+"/places"}>See all</Link>
        </React.Fragment>}
        fetch_endpoint={account_id ? `mmc_accounts/${account_id}/places`:"mmc_places"}
        delete_endpoint="mmc_places"
        columns={columns}
        pageSize={5}
        onClick={(item) =>  window.location.href = `/place/${item.id}` }
        contentVersion={contentVersion}
        {...rest}
        />
    </React.Fragment>
  );
};

export default PlaceDash;

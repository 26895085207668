import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Breadcrumb, Card, Button } from 'antd'

import ClusterList from '../components/Cluster/ClusterList.js';

const Clusters = () => {

  let cardTitle = (
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/clusters/">Groupes</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
    );

  return (
    <Card title={cardTitle}>
      <ClusterList pageSize={10} scroll_x={1200} />
    </Card>
  );
};

export default Clusters;

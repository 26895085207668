import React, { useState, useEffect } from 'react';
import { Drawer, Card, Skeleton, Button, message } from 'antd'

import FaIcon from '../FaIcon.js';
import { faPhoneRotary } from '@fortawesome/pro-solid-svg-icons/faPhoneRotary'
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope'
import { faEdit } from '@fortawesome/pro-solid-svg-icons/faEdit'
import { faSyncAlt } from '@fortawesome/pro-solid-svg-icons';


import { useApiContext } from '../../providers/ApiProvider.js';

import ContactForm from './ContactForm.js';

import { placeResume } from '../Place/_helpers.js'
import {faMobile} from "@fortawesome/pro-solid-svg-icons/faMobile";

const ContactDrawer = ({item, item_id, edit, onChange, onClose, ...rest}) => {

  onClose = onClose || function(){};
  onChange = onChange || function(){};
  item_id = item ? item.id : item_id || null

  const [apiState, apiDispatch] = useApiContext();
  const { apiFetchEntity, apiPostRequest, apiDeleteEntity, apiFetchCollection } = apiDispatch;

  const [_itemId, setItemId ] = useState(item_id)
  const [_item, setItem ] = useState({...item})
  const [loading, setLoading ] = useState(true)
  const [visible, setVisible ] = useState(true)
  const [mode, setMode ] = useState('resume')
  const [needUpdate, setNeedUpdate] = useState(0)
  const [sellsyChecking, setSellsyChecking] = useState(false)

  useEffect(() => {
    // fetch at startup and whenever needUpdate is changed
    if((_itemId && !item) || needUpdate)
      fetchItem();
    else
      setLoading(false);
  },[needUpdate])

  async function fetchItem() {
    setLoading(true);
    const response = await apiFetchEntity('mmc_contacts',_itemId);
    setItem({...response})
    setLoading(false);
    return response;
  }

  async function deleteItem() {
    await apiDeleteEntity('mmc_contacts',_itemId);
    onChange();
    setVisible(false);
  }

  const handleChange = async (updatedItem) => {
    setItem(updatedItem)
    onChange(updatedItem);
  }

  if(!_item || loading)
  {
    return (
        <Card><Skeleton loading={true} active></Skeleton></Card>
    );
  }

  const createOnSellsy = async () => {
    const responsePost = await apiPostRequest('sellsy/post-contact', {id: _item.id})
    console.log(responsePost)
    if (responsePost.success === 1) {
      message.success('Contact crée avec succes')
      setNeedUpdate((new Date()).getTime())
    }

  }

  const checkSellsy = async () => {
    setSellsyChecking(true)
    const result = await apiFetchCollection('sellsy/get-contact', { noExtension: true, search: { id: _item.id }})
    console.log(result)

    if (result.success && result?.exist) {
      message.success(result.message)
    } else {
      message.error(result.message)
      setNeedUpdate((new Date()).getTime())

    }
    setSellsyChecking(false)
  }

  return (
      <Drawer
          className="drawerWithDeleteButton"
          width={'min(90vw, 640px)'}
          placement="left"
          closable={false}
          visible={visible}
          onClose={()=>{ setVisible(false); }}
          afterVisibleChange={(visible)=>{ visible ? null : onClose(); }}
          title={<span>{_item.firstname} {_item.lastname} <a onClick={event => setMode('edit')} style={{float:'right'}}><FaIcon icon={faEdit} key="edit" /></a></span>}
        >
        {_itemId && mode==='edit'
          ? <ContactForm item_id={_itemId} onClose={() => { setMode('resume'); }} onChange={handleChange}/>
          : <React.Fragment>
              <p>
                {_item.contactRole ? <> {_item.contactRole.name} <br /> </> : null}
                {_item.description ? <> {_item.description} <br /> </> : null}
                <FaIcon icon={faPhoneRotary} /> {_item.phone}<br />
                <FaIcon icon={faMobile} /> {_item.mobilePhone}<br />
                <FaIcon icon={faEnvelope} /> {_item.email}
              </p>
              <p>SellsyId: {_item.sellsyId ?  <>{_item.sellsyId} <FaIcon icon={faSyncAlt} onClick={checkSellsy} spin={sellsyChecking}/></> : <Button onClick={createOnSellsy}>Créer sur sellsy</Button>}</p>

              {
                _item.places && _item.places.length
                ? _item.places.map(function(place,i){
                  return <blockquote key={"place"+i} style={{background:'#eee',padding:'5px 10px 1px',borderRadius:'5px'}}>
                    {placeResume(place)}
                  </blockquote>
                })
                : null
              }

              <Button className="drawerDeleteButton" type="danger" block onClick={deleteItem}>Delete</Button>
            </React.Fragment>
        }
      </Drawer>
  );
};

export default ContactDrawer;

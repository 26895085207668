import React, { useState, useEffect } from 'react';
import { Skeleton, Avatar, Card, Modal, Form, Button, Input } from 'antd'

import FaIcon from '../FaIcon.js';
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { faEdit } from '@fortawesome/pro-solid-svg-icons/faEdit'

import { useApiContext } from '../../providers/ApiProvider.js';

import ClusterForm from './ClusterForm.js'

const ClusterCard = (props) => {

  const onUpdate = props.onUpdate || function(){};
  const item_id = props.item ? props.item.id : props.item_id || null

  const [apiState, apiDispatch] = useApiContext();
  const { apiFetchEntity } = apiDispatch;

  const [item, setItem ] = useState({...props.item})
  const [loading, setLoading ] = useState(true)
  const [mode, setMode ] = useState('resume')

  useEffect(() => {
    // fetch at startup and whenever needUpdate is changed
    if(item_id && !item)
      fetchItem();
    else
      setLoading(false);
  },[])

  async function fetchItem() {
    const response = await apiFetchEntity('mmc_clusters',item_id);
    setItem({...response})
    setLoading(false);
    return response;
  }

  const handleChange = async (updatedItem) => {
    setItem(updatedItem)
    onUpdate(updatedItem);
  }

  let cardTitle = (
    <React.Fragment>
      {item.name}
    </React.Fragment>
  )

  let cardExtra = null;
  if(mode=='edit')
    cardExtra = <a onClick={event => setMode('resume')}><FaIcon icon={faTimes} key="close" /></a>;
  else
    cardExtra = <a onClick={event => setMode('edit')}><FaIcon icon={faEdit} key="edit" /></a>;

  return (
      <Card
        className={'entity-mode_'+mode}
        type="inner"
        size="small"
        title={cardTitle}
        extra={cardExtra}
        style={{marginBottom:'16px'}}
        >
        {item && mode==='edit' ? <ClusterForm modal item_id={item.id} onClose={() => { setMode('resume'); }} onChange={handleChange}/> : null}
        <Skeleton loading={!item || loading}>
          <React.Fragment>
            <p>{!item || !item.description ? null : item.description.split('\n').map((part, key) => <React.Fragment key={key}>{part}<br/></React.Fragment>)}</p>
          </React.Fragment>
        </Skeleton>

      </Card>
  );
};

export default ClusterCard;

import React, { useEffect, useState } from 'react';
import { useApiContext } from "../providers/ApiProvider";
import { Affix, Col, Row, Spin, Tag } from "antd";
import { Link, useParams } from "react-router-dom";
import SupportBreadcrumb from "../components/Support/Frontend/SupportBreadcrumb";
import { getLanguage, slugify, translate } from "../components/Support/helpers";
import ArticleSidebar from '../components/Support/Frontend/ArticleSidebar';

const SupportArticle = () => {

  const [apiState, apiDispatch] = useApiContext();
  const { apiFetchEntity } = apiDispatch;
  const params = useParams();
  const lng = getLanguage(params.lng)

  const [article, setArticle] = useState({});
  const [loading, setLoading] = useState(true);
  const [breadcrumb, setBreadcrumb] = useState([]);

  useEffect(() => {
    fetch();
  }, [params.id])

  async function fetch() {
    setLoading(true);
    const response = await apiFetchEntity('articles', params.id, { anonymously: true });
    setBreadcrumb(buildBreadcrumb(response))
    setArticle(response);
    setLoading(false);
  }

  function buildBreadcrumb(_article) {
    let _breadcrumb = [];

    _breadcrumb.push({ id: _article.id, name: translate(_article, 'title', lng) });

    if (_article.category)
      _breadcrumb = _breadcrumb.concat(addCategory(_article.category))

    _breadcrumb.push({ id: _article.product.id, name: _article.product.name });

    return _breadcrumb.reverse();
  }

  function addCategory(_category) {
    let _breadcrumb = [];

    _breadcrumb.push({ id: _category.id, name: translate(_category, 'name', lng) });

    if (_category.parent)
      _breadcrumb = _breadcrumb.concat(addCategory(_category.parent))

    return _breadcrumb;
  }
  console.log((new Date(article.updated)).toLocaleDateString())

  return (
    <div>

      <Spin spinning={loading}>
        <Row>

          <Col span={6}>
            <Affix>
              <ArticleSidebar defaultActiveKey={article.category?.id} />
            </Affix>
          </Col>

          <Col span={18}>
            <SupportBreadcrumb breadcrumb={breadcrumb} />
            <div className="article-container">
              <h1>{translate(article, 'title', lng)}</h1>
              <div className='article-credits'>
                {
                  article?.author &&
                  <span>
                    Ecrit par <strong>{article.author.username}</strong>
                  </span>
                }
                {
                  !!article.updated &&
                  <span>, mis à jour le {(new Date(article.updated)).toLocaleDateString()}</span>
                }
              </div>
              {
                article.supportTags &&
                <div className="article-credits">
                  <span>Tags : </span>
                  {
                    article.supportTags.filter(item => item.lng === lng).map(item =>
                      <Tag ><Link to={`../search/${item.id}-${encodeURIComponent(item.name)}?tag=true`}>{item.name}</Link></Tag>
                    )
                  }
                </div>
              }
              {
                translate(article, 'description', lng) &&
                <div className="description">
                  {translate(article, 'description', lng)}
                </div>
              }

              {
                article.video && article.video[lng] &&
                <div className="video">
                  <iframe
                    src={article.video[lng]}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
              }
              <div dangerouslySetInnerHTML={{ __html: article.content?.fr }} />
            </div>
          </Col>

        </Row>
      </Spin>
    </div>
  );
};

export default SupportArticle;

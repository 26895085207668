import React, { useState } from "react";
import ApiCollectionTable from "../../ApiCollectionTable";
import { Button, Input } from "antd";
import { Link, useHistory } from "react-router-dom";
import { slugify } from "../helpers";
import FaIcon from "../../FaIcon";
import { faLink, faSort } from "@fortawesome/pro-solid-svg-icons";
import OrderModal from "./OrderModal";

export default function ArticleList({ product, category = null, rest }) {
  const history = useHistory();
  const [indexing, setIndexing] = useState(null);
  const [contentVersion, setContentVersion] = useState(0);
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState(0);

  const columns = [
    {
      title: "Titre",
      dataIndex: "titleFr",
      sorter: true,
      render: (titleFr, record) => (
        <React.Fragment>
          <Link
            to={`/fr/support/${product.id}-${slugify(product.name)}/article/${
              record.id
            }`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaIcon icon={faLink} onClick={(e) => e.stopPropagation()} />
          </Link>{" "}
          {titleFr}
        </React.Fragment>
      ),
    },
    {
      title: "Catégorie",
      dataIndex: "category.nameFr",
      sorter: true,
      render: (category, record) => (
        <React.Fragment>
          {category}
        </React.Fragment>
      ),
    },
    {
      title: "Date de mise à jour",
      dataIndex: "updated",
      render: (updated, record) => (
        <React.Fragment>
          {new Date(updated).toLocaleDateString()}
        </React.Fragment>
      ),
    },
    {
      title: "Promu depuis",
      dataIndex: "promotionDate",
      render: (promotionDate, record) => (
        <React.Fragment>
          {promotionDate && new Date(promotionDate).toLocaleDateString()}
        </React.Fragment>
      ),
    },
    {
      title: "Version publié",
      dataIndex: "activeLngs",
      render: (activeLngs, record) => (
        <React.Fragment>
          {activeLngs.map((item, index) => (index > 0 ? ", " + item : item))}
        </React.Fragment>
      ),
    },
  ];

  const handleClose = (refresh) => {
    setIndexing(false);
    if (refresh === true) {
      setContentVersion(new Date().getTime());
    }
  };

  return (
    <>
      <ApiCollectionTable
        title={() => (
          <React.Fragment>
            <b>{total} Articles</b>
            <div className="flex" style={{ float: "right" }}>
              <span className="mr-1">
                <Input
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Recherche un article"
                  size="small"
                />
              </span>
              {category && (
                <Button
                  title="Modifier l'ordre des articles"
                  className="mr-1"
                  size="small"
                  onClick={() => setIndexing(true)}
                >
                  <FaIcon icon={faSort} />
                </Button>
              )}
              <Link to={`${product.id}-${slugify(product.name)}/article/new`}>
                <Button
                  title="Créer un article"
                  className="mr-1"
                  type="primary"
                  size="small"
                >
                  +
                </Button>
              </Link>
            </div>
          </React.Fragment>
        )}
        fetch_endpoint={`support_products/${product.id}/articles`}
        delete_endpoint={`articles`}
        columns={columns}
        pageSize={30}
        setTotal={setTotal}
        customSearch={
          category
            ? {
                "category.id": category,
                "order[ranking]": "asc",
                titleFr: search,
              }
            : { titleFr: search }
        }
        onClick={(item) =>
          history.push(
            `${product.id}-${slugify(product.name)}/article/${item.id}`
          )
        }
        {...rest}
      />
      {!!indexing && (
        <OrderModal
          endpoint={`support_products/${product.id}/articles`}
          category={category}
          visible={indexing}
          handleClose={handleClose}
        />
      )}
    </>
  );
}

import React, { useEffect, useState } from 'react';
import { Avatar, Button, Card, Collapse, Skeleton, Tag, Switch, Typography, Rate } from 'antd'

import FaIcon from '../FaIcon.js';
import { faPhoneRotary } from '@fortawesome/pro-solid-svg-icons/faPhoneRotary'
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope'
import { faLink } from '@fortawesome/pro-solid-svg-icons/faLink'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { faEdit } from '@fortawesome/pro-solid-svg-icons/faEdit'
import { faStar } from '@fortawesome/pro-solid-svg-icons/faStar'

import { useApiContext } from '../../providers/ApiProvider.js';

import PlaceForm from './PlaceForm.js';
import ApiCollectionTags from "../ApiCollectionTags";
import { cleanUrl } from '../_helpers.js';
import AccountForm from '../Account/AccountForm.js';
import { Link } from 'react-router-dom';


const PlaceCard = (props) => {

  const onUpdate = props.onUpdate || function () {
  };
  const item_id = props.item ? props.item.id : props.item_id || null

  const [apiState, apiDispatch] = useApiContext();
  const { apiFetchEntity, apiUpdateEntity } = apiDispatch;

  const [item, setItem] = useState({ ...props.item })
  const [loading, setLoading] = useState(true)
  const [mode, setMode] = useState('resume')
  const [needUpdate, setNeedUpdate] = useState(0)

  const weekday = [
    'Lundi',
    'Mardi',
    'Mercredi',
    'Jeudi',
    'Vendredi',
    'Samedi',
    'Dimanche',
  ]

  useEffect(() => {
    // fetch at startup and whenever needUpdate is changed
    if ((item_id && !item) || needUpdate)
      fetchItem();
    else
      setLoading(false);
  }, [needUpdate])

  async function fetchItem() {
    setLoading(true);
    const response = await apiFetchEntity('mmc_places', item_id);
    setItem({ ...response })
    setLoading(false);
    return response;
  }

  const handleChange = async (updatedItem) => {
    setItem(updatedItem)
    onUpdate(updatedItem);
  }

  const handleArchive = async (checked) => {
    setLoading(true);
    let response = await apiUpdateEntity('mmc_places', item.id, { isArchived: !checked })
    console.log(response)
    setItem(response)
    setLoading(false);

  }

  let cardTitle = (
    <React.Fragment>
      {item.name}
      {item.stars ? <span>&nbsp;&nbsp;
        <Rate disabled defaultValue={item.stars}
          character={<FaIcon icon={faStar} style={{ width: 10 }} />}
          style={{ fontSize: 15 }} />
      </span> : null}
      {item.isArchived && <span style={{ marginLeft: '10px', color: '#d0d0d0' }}>Archivé</span>}
    </React.Fragment>
  )

  let cardExtra = [
    <Switch key="archived" disabled={loading} title={item.isArchived ? 'Archivé' : 'Actif'} checked={!item.isArchived} onChange={handleArchive} size="small" style={{ marginRight: '10px' }} />
  ];
  if (mode == 'edit')
    cardExtra.push(<a key="mode" onClick={event => setMode('resume')}><FaIcon icon={faTimes} key="close" /></a>);
  else
    cardExtra.push(<a key="mode" onClick={event => setMode('edit')}><FaIcon icon={faEdit} key="edit" /></a>);


  const [baseAccount, setBaseAccount] = useState(null);

  const createAccount = () => {
    let account = {
      name: item.name ?? '',
      address: item.address ?? '',
      zip: item.zip ?? '',
      city: item.city ?? '',
      country: item.country ?? '',
      countryCode: item.countryCode ?? '',
      lat: item.lat ?? '',
      lng: item.lng ?? '',
      places: [item['@id']]
    }

    setBaseAccount(account)
  }

  const createAccountClose = () => {
    setBaseAccount(null)
    setNeedUpdate((new Date()).getTime())
  }

  return (
    <Card
      className={'entity-mode_' + mode}
      type="inner"
      size="small"
      title={cardTitle}
      extra={cardExtra}
      style={{ marginBottom: '16px' }}
    >
      {item && mode === 'edit' ? <PlaceForm modal item_id={item.id} onClose={() => {
        setMode('resume');
      }} onChange={handleChange} /> : null}
      <Skeleton loading={!item || loading}>
        <Avatar style={{ float: 'right', filter: 'grayscale(0.5)', marginLeft: '8px' }} shape="square" size={64}
          src={`http://tools.mmcreation.com/osmap/static/64/96/7/${item.gps[0]}/${item.gps[1]}.png?t=1`} />
        <div>
          <p>
            {item.address}, {item.zip} {item.city}, {item.country} <small>({item.countryCode})</small>
            <br />
            {item.phone && <><FaIcon icon={faPhoneRotary} /> {item.phone}<br /></>}
            {item.email && <><FaIcon icon={faEnvelope} /> {item.email}<br /></>}
            {item.website && <p><FaIcon icon={faLink} /> <a href={cleanUrl(item.website)}
              target="_blank">{cleanUrl(item.website)}</a></p>}
            {item.rooms && <p>Nombre de chambres : {item.rooms}</p>}
            {item.scrapResult && item.scrapResult.type === 'error' &&
              <p>
                Erreur lors du scrap : &nbsp;
                <Typography.Text type="danger" >{item.scrapResult.msg}</Typography.Text>
              </p>
            }
          </p>
        </div>
        <div>
          {item.account ?
            <>
              Société : <Link to={`/account/${item.account.id}`} > {item.account.name} </Link>
            </>
            : <>
              <Button onClick={createAccount}>Créer la Société</Button>
            </>
          }
        </div>
        <Collapse bordered={false} style={{ marginTop: '1em' }}>
          {
            item.gPlaceId &&
            <Collapse.Panel
              style={{ border: 'none', background: '#f7f7f7' }}
              header={<React.Fragment><strong>Place ID:</strong> {item.gPlaceId}</React.Fragment>}
              key="1"
            >
              {item.rating ? <p>Note : {item.rating}/5 ({item.userRatingsTotal} avis)</p> : null}
              {item.types && item.types.length ? <p>{item.types.map((item, i) => <Tag key={i}>{item}</Tag>)}</p> : null}
              {
                item.openingHours
                  ? <table className="openingHours">
                    <tbody>
                      {item.openingHours.map((item, i) => {
                        if (item === true)
                          return <tr key={i}>
                            <th>{weekday[i]}</th>
                            <td>Ouvert 24/24</td>
                          </tr>
                        else if (item.length)
                          return <tr key={i}>
                            <th>{weekday[i]}</th>
                            <td>{item.join(', ')}</td>
                          </tr>
                        else
                          return <tr key={i}>
                            <th>{weekday[i]}</th>
                            <td>Fermé</td>
                          </tr>
                      })}
                    </tbody>
                  </table>
                  : null
              }
            </Collapse.Panel>
          }
          <Collapse.Panel key={'tags'}
            header={<React.Fragment><strong>Tags :</strong></React.Fragment>}>
            <ApiCollectionTags
              fetch_endpoint={`mmc_places/${item.id}/tags`}
              tag_enpoint='tags'
              customSearch={{ type: 1 }}
              baseData={{ type: 1 }}
              delete_endpoint='tags'
              relation='places' item={item}
            />
          </Collapse.Panel>
        </Collapse>
      </Skeleton>
      {!!baseAccount && <AccountForm modal baseAccount={baseAccount} item_id="new" onClose={createAccountClose} />}
    </Card>
  );
};

export default PlaceCard;

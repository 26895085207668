import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import { Layout, Card , Icon, Avatar } from 'antd';

import { useAuthContext } from '../providers/AuthProvider.js';

import NavPublic from './NavPublic.js';
import NavPrivate from './NavPrivate.js';

const Sidebar = () => {

  const [authState, authDispatch] = useAuthContext();
  const { auth } = authState;
  const { authIsLogged } = authDispatch;

  const [collapsed, setCollapsed] = useState(false);

  return (
    <Layout.Sider className="sidebar-layout" collapsible collapsed={collapsed} onCollapse={() => {setCollapsed(!collapsed)}}>
      {
        authIsLogged()
        ? (
          <React.Fragment>
            <Card style={{borderRadius:0, borderWidth:"0 1px 0 0"}}>
              <Avatar>{auth.username[0].toUpperCase()}</Avatar>
              <span className="username">{auth.username}</span>
            </Card>
            <NavPrivate />
          </React.Fragment>
        )
        : <NavPublic />
      }
    </Layout.Sider>
  );
};

export default Sidebar;

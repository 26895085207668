export const contactRelation = [
	{endpoint: 'mmc_accounts', link: 'account'},
	{endpoint: 'mmc_places', link: 'place'},
	{endpoint: 'mmc_clusters', link: 'cluster'}
]

export const endpointResolver = (endpoint) => {
	let result = contactRelation.filter(relation => relation.endpoint === endpoint)
	if (result.length > 0) {
		return result[0].link;
	}
}

export const getRelation = (contact) => {
  let relation = null;

  contactRelation.map(item => {
    if (contact[item.link]) {
      relation = {...contact[item.link], relationType:item.link, relationEndpoint: item.endpoint}
    }
  })
  return relation
}

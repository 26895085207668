import React, { useState, useEffect, useRef } from 'react';
import { Select } from 'antd'


import { useApiContext } from '../../providers/ApiProvider.js';


const FormSelect = ({options, options_endpoint, option_formater, search_property, onChange, name, value, mode, customSearch = {}, placeHolder = null, contentVersion, ...rest}) => {

  // object conversion to an simple api iri list
  let _value = value && Array.isArray(value) && value.length ? value.map(function(v){ return v && v['@id'] ? v['@id'] : v; }) : value;

  const originalValue = _value && _value['@id'] ? _value['@id'] : _value;

  const [internalOptions, setInternalOptions ] = useState(options || [])
  const [internalValue, setInternalValue ] = useState(originalValue ? originalValue : [])
  const [saveNeeded, setSaveNeeded ] = useState(false)
  const isMounted = useRef(null);

  const [fetching, setFetching ] = useState(false)

  const [apiState, apiDispatch] = useApiContext();
  const { apiFetchCollection } = apiDispatch;

  useEffect(() => {
    isMounted.current = true;
    if (contentVersion) {
      setInternalValue([])
    }
    if(options_endpoint)
    {
      // fetch at startup and whenever needUpdate is changed
      fetch();
    }
     return () => {
      // executed when unmount
      isMounted.current = false;
    }
  },[contentVersion])


  const handleInputChange = (value) => {
    let chunk = {};
    let newValue = value;
    chunk[name] = newValue

    if(options_endpoint)
    {
      for(let i=0; i<internalOptions.length; i++)
      {
        //console.log(internalOptions[i])
        if(internalOptions[i].value===newValue && internalOptions[i]._rawItem)
          chunk[name] = internalOptions[i]._rawItem
      }
    }

    onChange(chunk);

    setInternalValue(newValue);
    setSaveNeeded(newValue!==originalValue);
  }

  const fetch = async (search) => {
    // console.log('search', search)
    if(options_endpoint)
    {
      setFetching(true)
      let params = {
        itemsPerPage: 100,
        page: 0,
        search: customSearch
      };
      if(search) {
        params.search[search_property] = search;
      } else {
        params.search[search_property] = '';
      }

      let hydra_response = await apiFetchCollection(options_endpoint, params);
      if (isMounted.current) {

        if(hydra_response)
        {
          let data = hydra_response['hydra:member'];
          setInternalOptions(data.map((item,i) => {
            let o = option_formater(item,i)
            o._rawItem = item;
            return o;
          }));
        }
        setFetching(false);
      }
    }

  }

  return <Select
    name={name}
    value={internalValue}
    onChange={handleInputChange}
    placeholder={options_endpoint ? "Search": placeHolder}
    notFoundContent={options_endpoint && fetching ? 'Searching...' : null}
    showSearch={!!options_endpoint}
    onSearch={options_endpoint ? fetch : null}
    onSelect={()=>fetch('')}
    filterOption={false}
    mode={mode}
    {...rest}
    >
    {
      internalOptions && internalOptions.length ? internalOptions.map((item,i) => {
        return <Select.Option key={i} value={item.value}>
          {item.label}
        </Select.Option>
      }) : []
    }
  </Select>
};

export default FormSelect;

import React from 'react'

import PublicRoute from './PublicRoute.js'
import PrivateRoute from './PrivateRoute.js'
import SupportRoute from "./Support/Frontend/SupportRoute";

import Home from '../pages/Home.js'
import Login from '../pages/Login.js'
import Logout from '../pages/Logout.js'

import Private from '../pages/Private.js'
import Clusters from '../pages/Clusters.js'
import Cluster from '../pages/Cluster.js'
import ClusterContacts from '../pages/ClusterContacts.js'

import Contacts from '../pages/Contacts.js'

import Accounts from '../pages/Accounts.js'
import Account from '../pages/Account.js'
import AccountContacts from '../pages/AccountContacts.js'
import AccountPlaces from '../pages/AccountPlaces.js'

import Error from '../pages/Error';

import PlaceContacts from '../pages/PlaceContacts.js'
import PlaceContracts from '../pages/PlaceContracts.js'
import PlaceLogs from '../pages/PlaceLogs.js'
import Places from '../pages/Places.js'
import Geoloc from '../pages/Geoloc.js'

import Scrapper from "../pages/Scrapper";
import Products from "../pages/Products";
import Settings from "../pages/Settings";
import Tags from "../pages/Tags";
import Logs from "../pages/Logs";
import Place from "../pages/Place";
import Users from "../pages/Users";
import Comments from "../pages/Comments";
import { Compare } from '../pages/Compare.js'
import Estimate from "../pages/Estimate";
import Segments from "../pages/Segments";
import Segment from "../pages/Segment";

import Article from "../pages/Article";
import Documentation from "../pages/Documentation";

import SupportCategory from "../pages/SupportCategory";
import Support from "../pages/Support";
import SupportArticle from "../pages/SupportArticle";
import SupportSearch from "../pages/SupportSearch";
import SupportProduct from "../pages/SupportProduct";
import DocProduit from '../pages/DocProduit.js';

function Router() {
  return (
    <div>
      <PublicRoute exact path="/" component={Home} layout="test"/>
      <PrivateRoute path="/private" component={Private} layout="test"/>

      <PrivateRoute path="/clusters" component={Clusters}/>
      <PrivateRoute exact path="/cluster/:cluster_id" component={Cluster}/>
      <PrivateRoute exact path="/cluster/:cluster_id/contacts" component={ClusterContacts}/>

      <PrivateRoute path="/contacts" component={Contacts}/>
      <PrivateRoute path="/compare" component={Compare}/>

      <PrivateRoute path="/accounts" component={Accounts}/>
      <PrivateRoute exact path="/account/:account_id/estimate" component={Estimate}/>
      <PrivateRoute exact path="/account/:account_id/contacts" component={AccountContacts}/>
      <PrivateRoute exact path="/account/:account_id/places" component={AccountPlaces}/>
      <PrivateRoute exact path="/account/:account_id" component={Account}/>
      <PrivateRoute exact path={"/logs"} component={Logs}/>

      <PrivateRoute path="/places" component={Places}/>
      <PrivateRoute path="/geoloc" component={Geoloc}/>
      <PrivateRoute exact path="/place/:place_id" component={Place}/>
      <PrivateRoute exact path="/place/:place_id/contacts" component={PlaceContacts}/>
      <PrivateRoute exact path="/place/:place_id/contracts" component={PlaceContracts}/>
      <PrivateRoute exact path="/place/:place_id/logs" component={PlaceLogs}/>

      <PrivateRoute exact path={"/scrapper"} component={Scrapper}/>

      <PrivateRoute exact path={"/products"} component={Products}/>

      <PrivateRoute path={"/settings"} component={Settings}/>
      <PrivateRoute exact path={"/tags/"} component={Tags}/>
      <PrivateRoute path={"/tags/:type"} component={Tags}/>
      <PublicRoute path="/erreur" component={Error}/>

      <PrivateRoute exact path={"/comments"} component={Comments}/>
      <PrivateRoute exact path={"/segments"} component={Segments}/>
      <PrivateRoute exact path={"/segment/:id"} component={Segment}/>

      <PrivateRoute exact path={"/users"} component={Users}/>


      <PrivateRoute exact path={"/documentation"} component={Documentation}/>
      <PrivateRoute exact path={"/documentation/:product"} component={DocProduit}/>
      <PrivateRoute exact path={"/documentation/:product/article/:id"} component={Article}/>

      <SupportRoute exact path="/:lng/support" component={Support}/>
      <SupportRoute exact path="/:lng/support/:product" component={SupportProduct}/>
      <SupportRoute exact path="/:lng/support/:product/category/:id" component={SupportCategory}/>
      <SupportRoute exact path="/:lng/support/:product/article/:id" component={SupportArticle}/>
      <SupportRoute exact path="/:lng/support/:product/search/:query" component={SupportSearch}/>

      <PublicRoute path="/login" component={Login}/>
      <PrivateRoute path="/logout" component={Logout}/>
    </div>
  );
}

export default Router

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Breadcrumb, Card, List, Row, Col, Spin, Pagination, Input } from 'antd'
import { useApiContext } from '../providers/ApiProvider.js';

import PlaceGeoloc from '../components/Place/PlaceGeoloc.js';

const Geoloc = () => {

    const [apiState, apiDispatch] = useApiContext();
    const { apiFetchCollection } = apiDispatch;
    const [loading, setLoading] = useState(true)
    const [places, setPlaces] = useState([])
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 30,
        position: 'both',
        simple: true,
    })
    const [selectedPlace, setSelectedPlace] = useState(null)
    const [search, setSearch] = useState('')


    useEffect(() => {
        fetchPlaces()
    }, [pagination.current, search])

    const fetchPlaces = async () => {
        setLoading(true)
        let params = {
            itemsPerPage: pagination.pageSize,
            page: pagination.current,
            search: { gplaceId: false, name: search }
        };

        const response = await apiFetchCollection('mmc_places', params)
        console.log(response)
        setPagination({...pagination, total: response['hydra:totalItems']})
        setPlaces(response['hydra:member'])
        if (response['hydra:member'].length > 0) {
            setSelectedPlace(response['hydra:member'][0])
        }
        setLoading(false)
    }

    let cardTitle = (
        <Breadcrumb>
            <Breadcrumb.Item>
                <Link to="/">Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                <Link to="/geoloc/">Geoloc</Link>
            </Breadcrumb.Item>
        </Breadcrumb>
    );

    const removePlace = (place) => {
        setPlaces(oldPlace => oldPlace.filter(item => item.id !== place.id))
        setSelectedPlace(null)
    }

    const pageChange = (page) => {
        setPagination({...pagination, current: page})
    }

    return (
        <Spin spinning={loading} >
            <Card title={cardTitle}>
                <h3>{pagination.total} hotel non localisés {search && `contenant la recherche : "${search}"`}</h3>
                <Pagination current={pagination.current} pageSize={pagination.pageSize} onChange={pageChange} total={pagination.total} style={{marginBottom: '1em'}}/>
                <Input.Search onSearch={(value) => setSearch(value)}       enterButton="Search" style={{width: 300, marginBottom: '1em'}}/>
                <Row gutter={[16, 16]}>
                    <Col span={6} >
                        {
                            !!places.length &&
                            <List
                                bordered
                                dataSource={places}
                                renderItem={item => (
                                    <List.Item key={item.id} onClick={() => setSelectedPlace(item)} className={`pointer ${item.id === selectedPlace?.id ? 'selected' : ''}`}>
                                        {item.name}
                                    </List.Item>
                                )}
                            />
                        }
                    </Col>
                    <Col span={18} >
                        {
                            selectedPlace &&
                            <PlaceGeoloc place={selectedPlace} removePlace={removePlace} setLoading={setLoading}/>
                        }
                    </Col>
                </Row>
            </Card>
        </Spin>
    );
};

export default Geoloc;

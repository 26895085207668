import React, { useState, useEffect } from 'react';
import { Drawer, Card, Skeleton, Button } from 'antd'

import FaIcon from '../FaIcon.js';
import { faEdit } from '@fortawesome/pro-solid-svg-icons/faEdit'

import { useApiContext } from '../../providers/ApiProvider.js';

import ContractForm from './ContractForm.js';

import { activesTagResolver } from './_helpers.js'
import moment from "moment";
import ModalLinkApp from './ModalLinkApp.js';


const ContractDrawer = ({ item, item_id, edit, onChange, onClose, ...rest }) => {

  onChange = onChange || function () {
  };
  onClose = onClose || function () {
  };
  item_id = item ? item.id : item_id || null

  const [apiState, apiDispatch] = useApiContext();
  const { apiFetchEntity, apiDeleteEntity } = apiDispatch;

  const [_itemId, setItemId] = useState(item_id)
  const [_item, setItem] = useState({ ...item })
  const [loading, setLoading] = useState(true)
  const [visible, setVisible] = useState(true)
  const [displayForm, setDisplayForm] = useState(edit)
  const [linkApp, setLinkApp] = useState(false)
  const [needUpdate, setNeedupdate] = useState(0)

  useEffect(() => {
    // fetch at startup and whenever needUpdate is changed
    console.log(_itemId,)
    if ((_itemId && !_item) || needUpdate !== 0)
      fetchItem();
    else
      setLoading(false);
  }, [needUpdate])

  async function fetchItem() {
    setLoading(true);
    const response = await apiFetchEntity('mmc_contracts', _itemId);
    setItem({ ...response })
    setLoading(false);
    return response;
  }

  async function deleteItem() {
    await apiDeleteEntity('mmc_contracts', _itemId);
    onChange();
    setVisible(false);
  }

  const handleChange = async (updatedItem) => {
    setItem(updatedItem)
    onChange(updatedItem);
  }

  const onCancel = () => {
    setLinkApp(false);
    setNeedupdate((new Date()).getTime())
    console.log((new Date()).getTime())
  }

  return (
    <Drawer
      className="drawerWithDeleteButton"
      width={'min(90vw, 640px)'}
      placement="left"
      closable={false}
      visible={visible}
      onClose={() => {
        setVisible(false);
      }}
      afterVisibleChange={(visible) => {
        visible ? null : onClose();
      }}
      title={
        <span>
          {activesTagResolver(_item, { compact: true })} {_item.product.name}
          {displayForm ? null :
            <a onClick={event => setDisplayForm(true)} style={{ float: 'right' }}><FaIcon icon={faEdit} key="edit" /></a>}
        </span>
      }
    >
      {
        displayForm
          ? <ContractForm item={_item} onClose={() => {
            edit ? setVisible(false) : setDisplayForm(false);
          }} onChange={handleChange} />
          :
          loading ?
            <Card><Skeleton loading={true} active /></Card>
            :
            <>
              <p>Type de produit :{_item.product.tag.name}</p>
              <p>SIte internet : <a href={_item.place.website} target="_blank">{_item.place.website}</a></p>
              <p>{_item.reference}</p>
              <p>Date de subscription : {moment(_item.subscribedAt, "YYYY-MM-DD").format('DD/MM/YYYY')}</p>
              <p>Date de fin : {moment(_item.endedAt, "YYYY-MM-DD").format('DD/MM/YYYY')}</p>
              {
                _item.description ?
                  <>
                    Description :
                    <blockquote style={{ background: '#eee', padding: '10px 10px', borderRadius: '5px' }}>
                      {_item.description}
                    </blockquote>
                  </>
                  : null
              }
              {
                _item.notDetectedDate && !moment(item.endedAt, "YYYY-MM-DD").isBefore() ?
                  <p style={{ color: 'red' }}>Contrat non détecté depuis le
                    : {moment(_item.notDetectedDate).format('HH:mm - DD/MM/YYYY')}</p> : null
              }
              {
                item.externalId &&
                <p>Id sur la plateforme : {_item.externalId} &nbsp;

                  {
                    _item.product.endpoint && _item.product.apiKey &&
                    <FaIcon icon={faEdit} key="edit" onClick={() => setLinkApp(true)} />
                  }
                </p>
              }
              {
                item.product.endpoint && !item.externalId &&
                <div>
                  <Button onClick={() => setLinkApp(true)} type="primary">Lier à la plateforme</Button>
                </div>
              }

              <Button className="drawerDeleteButton" type="danger" block onClick={deleteItem}>Delete</Button>
            </>
      }
      {
        linkApp &&
        <ModalLinkApp item={item} onCancel={onCancel} />
      }
    </Drawer>
  );
};

export default ContractDrawer;

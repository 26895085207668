import React, { useState, useEffect } from 'react';
import {useParams, Link, Redirect} from 'react-router-dom'
import { Breadcrumb, Card } from 'antd'

import FaIcon from '../components/FaIcon.js';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner'
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle'

import { useApiContext } from '../providers/ApiProvider.js';

import ContactList from '../components/Contact/ContactList.js';

const Account = (props) => {


  const [apiState, apiDispatch] = useApiContext();
  const { apiFetchEntity } = apiDispatch;

  const [account, setAccount ] = useState(null)
  const [loading, setLoading ] = useState(true)
  const [redirection, setRedirection] = useState(false);

  const params = useParams();

  useEffect(() => {
    // fetch at startup and whenever needUpdate is changed
    fetchData();
  },[])

  async function fetchData() {
    const account_response = await apiFetchEntity('mmc_accounts',params.account_id);
    if (account_response['@type'] ==="hydra:Error") {
      setRedirection(true)
    }
    setAccount(account_response)
    setLoading(false);
  }

  if(loading)
    return <FaIcon icon={faSpinner} spin />

  if (redirection)
    return <Redirect to={{
      pathname: "/erreur",
      search: "?utm=your+face",
      state: {status:'404', subtitle:'Cette société n\'existe pas', page:'./accounts' }
    }} />

  if(account['@id'])
  {
    let cardTitle = (
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/clusters/">Groupes</Link>
        </Breadcrumb.Item>
        {
          account.cluster
          ? <Breadcrumb.Item>
              <Link to={"/cluster/"+account.cluster.id}>{account.cluster.name}</Link>
            </Breadcrumb.Item>
          : null
        }
        <Breadcrumb.Item>
          <Link to={"/account/"+account.id}>{account.name}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={"/account/"+account.id+"/contacts"}>Contacts</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
    );

    return (
      <div>
        <Card title={cardTitle}>
          <ContactList scroll_x={1000} id={account.id} endpoint="mmc_accounts"/>
        </Card>
      </div>
    );
  }
  return <FaIcon icon={faExclamationTriangle} />
};

export default Account;

import React, {useEffect, useState} from 'react';
import {AutoComplete, Input} from "antd";
import {useHistory, useParams} from "react-router-dom";
import {getLanguage, translate} from "../helpers";
import FaIcon from "../../FaIcon";
import {faSearch} from "@fortawesome/pro-light-svg-icons";
import {useApiContext} from "../../../providers/ApiProvider";

const {Option, OptGroup} = AutoComplete;

const Search = ({sidebar = false}) => {

  const [apiState, apiDispatch] = useApiContext();
  const {apiFetchCollection} = apiDispatch;

  const history = useHistory();
  const params = useParams();
  const lng = getLanguage(params.lng)

  const windowUrl = window.location.search;
  const urlParams = new URLSearchParams(windowUrl);
  const isTag = urlParams.get('tag') === 'true';

  const [value, setValue] = useState("")
  const [search, setSearch] = useState(0)
  const [options, setOptions] = useState([])

  useEffect(() => {
    if (window.location.pathname.includes('/search/')) {
      if (isTag)
        setValue(decodeURIComponent(params.query.split('-').pop()))
      else
        setValue(decodeURIComponent(params.query))
    }
  }, [])

  async function goToSearchPage() {
    if (!value) return history.push(`/${lng}/support/${params.product}`);
    return history.push(`/${lng}/support/${params.product}/search/${encodeURIComponent(value)}`)
  }

  async function handleSearch(q) {
    setValue(q)
    setOptions([])
    clearTimeout(search)
    setSearch(setTimeout(async () => {
      if (!q) return;

      let predictions = [];

      const productId = params.product.split('-').shift();

      const data = {search: {query: q, lng: lng, productId: productId}, noExtension: true, anonymously: true}
      const response = await apiFetchCollection('public/search', data)
      if (response.results.length) {
        predictions.push((<OptGroup key="articles" label="Article(s)">
          {response.results.map(article => (
            <Option key={article.id} data="article">{translate(article, 'title', lng)}</Option>
          ))}
        </OptGroup>));

        setOptions([...predictions])
      }

      const dataTag = {search: {lng: lng, ['exists[articles]']: true, name: q, product: productId}}
      const responseTag = await apiFetchCollection('support_tags', dataTag)
      if (responseTag['hydra:member'].length) {
        predictions.push((<OptGroup key="tags" label="Tag(s)">
          {responseTag['hydra:member'].map(tag => (
            <Option key={tag.id} data="tag">{tag.name}</Option>
          ))}
        </OptGroup>));

        setOptions([...predictions])
      }

    }, 500))
  }

  function onSelect(a, b) {
    switch (b.props.data) {
      case 'article':
        history.push(`/${lng}/support/${params.product}/article/${a}`)
        break;
      case 'tag':
        history.push(`/${lng}/support/${params.product}/search/${a}-${encodeURIComponent(b.props.children)}?tag=true`)
        break;
      default:
        break;
    }
  }

  return (
    <div className={`support-search ${sidebar && 'sidebar'}`}>
      <AutoComplete
        style={{width: sidebar ? '100%' : 300}}
        value={value}
        onSearch={handleSearch}
        dataSource={options}
        onSelect={onSelect}
      >
        <Input.Search
          placeholder="Rechercher"
          onSearch={goToSearchPage}
          enterButton={<FaIcon icon={faSearch}/>}
        />
      </AutoComplete>
    </div>
  );
};

export default Search;

import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Spin, Alert, message } from "antd";
import { useApiContext } from '../../providers/ApiProvider.js';
import PlaceData from "./PlaceData.js";
import FaIcon from "../FaIcon";
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';


export default function ModalCompare({ ids, onCancel }) {

    const [apiState, apiDispatch] = useApiContext();
    const { apiFetchEntity, apiUpdateEntity, apiDeleteEntity } = apiDispatch;
    const [firstEntity, setFirstEntity] = useState(null)
    const [secondEntity, setSecondEntity] = useState(null)
    const [currentKey, setCurrentKey] = useState('general')
    const [loading, setLoading] = useState(true)
    const [needUpdate, setNeedUpdate] = useState(0)

    useEffect(() => {
        setLoading(true)
        let first = fetchEntity(ids[0], setFirstEntity)
        let second = fetchEntity(ids[1], setSecondEntity)

        Promise.all([first, second]).then(() => {
            setLoading(false)
        })
    }, [ids, needUpdate])

    const fetchEntity = async (id, setter) => {
        const result = await apiFetchEntity('mmc_places', id)
        setter(result)
    }

    const switchValues = (ids, endpoint, target) => {
        setLoading(true)

        target = '/api/mmc_places/' + target
        let proms = ids.map(async id => {
            await apiUpdateEntity(endpoint, id, {
                'place': target
            })
        })
        Promise.all(proms).then(()=> {
            setNeedUpdate((new Date()).getTime())
        })
    }

    const showDeleteConfirm = (id) => {
        console.log(id)
        Modal.confirm({
          title: 'Confirmer la suppression?',
          icon: <FaIcon icon={faExclamationTriangle}/>,
          okText: 'Oui',
          okType: 'danger',
          cancelText: 'Non',
          onOk() {
            handleDelete(id);
          },
          onCancel() {
            console.log('Cancel');
          },
        });
      }

    const handleDelete = async (id) => {
        let response = await apiDeleteEntity('mmc_places', id);
        if (response['@type'] ===  "hydra:Error") {
          message.error('Impossible de suprimmer cet élément')
          return
        }
        message.success('Elément supprimé avec succes')
        onCancel()
    }

    console.log(firstEntity)

    return (
        <Modal title="Basic Modal" visible={true} onOk={() => onCancel(false)} onCancel={() => onCancel(false)} width={1000} height={500}>
            {firstEntity && secondEntity && !loading ?
                <Row>
                    <Col span={12}>
                        <PlaceData place={firstEntity} currentKey={currentKey} setCurrentKey={setCurrentKey} switchValues={switchValues} target={secondEntity.id} handleDelete={showDeleteConfirm} />
                    </Col>
                    <Col span={12}>
                        <PlaceData place={secondEntity} currentKey={currentKey} setCurrentKey={setCurrentKey} switchValues={switchValues} target={firstEntity.id} handleDelete={showDeleteConfirm}/>
                    </Col>
                </Row>
                :
                <Spin tip="Loading..." spinning={loading}>
                    <Alert
                        message={loading ? "Chargement en cours" : "Oups il y a eu une erreur"}
                        description="Further details about the context of this alert."
                        type="info"
                    />
                </Spin>

            }
        </Modal>
    )
}
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { Redirect } from 'react-router';

import { Button } from 'antd';

import FaIcon from '../FaIcon.js';
import { faEdit } from '@fortawesome/pro-solid-svg-icons/faEdit'

import ClusterForm from './ClusterForm.js'
import ApiCollectionTable from '../ApiCollectionTable.js';

const ClusterList = ({scroll_x, ...rest}) => {

  const [creationMode, setCreationMode ] = useState(false)

  const handleCreationFormClose = () => {
    setCreationMode(false);
  }
  const handleCreationFormChange = () => {
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      search: true,
      sorter: true,
      render: (name,record) => <Link to={`/cluster/${record.id}`}><Button><FaIcon icon={faEdit} /> {name && name.length > 0 ? name : '???'}</Button></Link>,
    },
    {
      title: 'Account number',
      dataIndex: 'accounts_count',
    },
  ];

  if(creationMode)
    return <ClusterForm modal item_id="new" onClose={handleCreationFormClose} onChange={handleCreationFormChange}/>;

  return (
    <ApiCollectionTable
      title={(currentPageData) => <React.Fragment><b>Groupes</b> <Button style={{float:"right"}} type="primary" size="small" onClick={()=>{setCreationMode(true)}}>+</Button></React.Fragment>}
      fetch_endpoint="mmc_clusters"
      columns={columns}
      scroll_x={scroll_x}
      {...rest}
      />
  );
};

export default ClusterList;

import React, { useState, useEffect } from 'react';
import { Skeleton, Avatar, Card, Button, message } from 'antd'

import FaIcon from '../FaIcon.js';
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { faEdit } from '@fortawesome/pro-solid-svg-icons/faEdit'
import { faSyncAlt } from '@fortawesome/pro-solid-svg-icons';

import { useApiContext } from '../../providers/ApiProvider.js';

import AccountForm from './AccountForm.js'
import SellsyConnect from './SellsyConnect.js';

const AccountCard = (props) => {

  const onUpdate = props.onUpdate || function () { };
  const item_id = props.item ? props.item.id : props.item_id || null

  const [apiState, apiDispatch] = useApiContext();
  const { apiFetchEntity, apiFetchCollection } = apiDispatch;

  const [item, setItem] = useState({ ...props.item })
  const [loading, setLoading] = useState(true)
  const [mode, setMode] = useState('resume')
  const [sellsyMode, setSellsyMode] = useState(null)
  const [needUpdate, setNeedUpdate] = useState(0)
  const [forceUpdate, setForceUpdate] = useState(0)
  const [sellsyChecking, setSellsyChecking] = useState(false)

  useEffect(() => {
    // fetch at startup and whenever needUpdate is changed
    if (item_id)
      fetchItem();
    else
      setLoading(false);
  }, [needUpdate])

  useEffect(() => {
    if (forceUpdate)
      fetchItem();
  }, [forceUpdate])

  async function fetchItem() {
    const response = await apiFetchEntity('mmc_accounts', item_id);
    setItem({ ...response })
    setLoading(false);
    return response;
  }

  const handleChange = async (updatedItem) => {
    setItem(updatedItem)
    onUpdate(updatedItem);
  }

  const handleSellsyClose = () => {
    setLoading(true);
    setForceUpdate((new Date()).getTime())
    setSellsyMode(false)
  }

  const checkSellsy = async () => {
    setSellsyChecking(true)
    const result = await apiFetchCollection('sellsy/get-client', { noExtension: true, search: { id: item.id }})
    console.log(result)

    if (result.success && result?.exist) {
      message.success(result.message)
    } else {
      message.error(result.message)
      setNeedUpdate((new Date()).getTime())

    }
    setSellsyChecking(false)
  }

  let cardTitle = (
    <React.Fragment>
      {item.logo ? <Avatar src={item.logo.contentUrl} /> : <Avatar>{item.name[0].toUpperCase()}</Avatar>} {item.name}
    </React.Fragment>
  )

  let cardExtra = null;
  if (mode == 'edit')
    cardExtra = <a onClick={event => setMode('resume')}><FaIcon icon={faTimes} key="close" /></a>;
  else
    cardExtra = <a onClick={event => setMode('edit')}><FaIcon icon={faEdit} key="edit" /></a>;

  return (
    <Card
      className={'entity-mode_' + mode}
      type="inner"
      size="small"
      title={cardTitle}
      extra={cardExtra}
      style={{ marginBottom: '16px' }}
    >
      {item && mode === 'edit' ? <AccountForm modal item_id={item.id} onClose={() => { setMode('resume'); }} onChange={handleChange} /> : null}
      <Skeleton loading={!item || loading}>
        <p>{!item || !item.description ? null : item.description.split('\n').map((item, key) => <React.Fragment key={key}>{item}<br /></React.Fragment>)}</p>
        <p>{!!item.address && item.address} - {!!item.city && item.city} - {!!item.zip && item.zip}</p>
        <p>{!item || !item.url ? null : <><strong>Site web : </strong><a target="_blank" href={item.url}>{item.url}</a></>}</p>

        <p>Connexion Sellsy : {!!item.sellsyId ? <>{item.sellsyId} <FaIcon icon={faSyncAlt} onClick={checkSellsy} spin={sellsyChecking}/></>
          : <Button onClick={() => setSellsyMode(true)}>Connexion</Button>
        }</p>
      </Skeleton>
      {
        sellsyMode &&
        <SellsyConnect onCancel={handleSellsyClose} account={item} />
      }
    </Card>
  );
};

export default AccountCard;

import React, { useState } from 'react';
import { message } from 'antd'

import { useApiContext } from "../../providers/ApiProvider";
import AutoCompleteInput from "../Form/AutoCompleteInput";


const TagAutoComplete = ({ item, endpoint, search_property, customSearch = {}, baseData, relation, setNeedUpdate, ...rest }) => {

  const [apiState, apiDispatch] = useApiContext();
  const { apiUpdateEntity, apiCreateEntity } = apiDispatch;

  const [saving, setSaving] = useState(false)
  const [resetValue, setResetValue] = useState(0)

  const handleSave = async (tag) => {
    setSaving(true);

    if (!tag.id) {
      await createTag(tag)
    } else {
      await updateTag(tag)
    }
    setResetValue((new Date()).getTime())
    setSaving(false);
    setNeedUpdate((new Date()).getTime())
  }

  const createTag = async (tag) => {
    let data = {
      ...baseData,
      name: tag.name,
    }
    data[relation] = [item['@id']]
    const tag_response = await apiCreateEntity(endpoint, data)
    if (tag_response['@type'] === 'hydra:Error') {
      message.error(tag_response['hydra:description'])
    } else {
      message.success('Tag crée avec succes')
    }

  }

  const updateTag = async (tag) => {
    let data = {
      [relation]: [...tag[relation], item['@id']]
    }
    const updated_response = await apiUpdateEntity(endpoint, tag.id, data)
  }

  const handleChange = (item) => {

    if (item.id) {
      handleSave(item)
    }
  }


  return (
    <AutoCompleteInput
      options_endpoint={endpoint}
      search_property={search_property}
      customSearch={{...customSearch}}
      option_formater={(item) => { return { value: item['@id'], label: item.name }; }}
      handleSave={handleSave}
      onChange={handleChange}
      style={{ width: 150 }}
      loading={saving}
      resetInput={resetValue}
      {...rest}
      onBlur={() => setNeedUpdate((new Date()).getTime())}
    />
  )

};

export default TagAutoComplete;

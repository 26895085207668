const products = [
  {
    value:null, label:"???",
  },
  {
    value:"WEBSITE", label:"Website",
    children: [
      { value:"WEBSITE:"          , label:"-"        , color:null , external:2 } ,
      { value:"WEBSITE:MMC-HAPI1" , label:"HAPI v1"  , color:null , external:0 } ,
      { value:"WEBSITE:MMC-HAPI2" , label:"HAPI v2"  , color:null , external:0 } ,
      { value:"WEBSITE:AVAILPRO"  , label:"Availpro" , color:null , external:2 } ,
    ]
  },
  {
    value:"HOSTING", label:"Hébergement",
    children: [
      { value:"HOSTING:"           , label:"-"          , color:null , external:2 } ,
      { value:"HOSTING:MMC-SERVER" , label:"MMcréation" , color:null , external:0 } ,
    ]
  },
  {
    value:"PRICES", label:"Price Monitoring",
    children: [
      { value:"PRICES:"        , label:"-"                    , color:null , external:2 } ,
      { value:"PRICES:MMC-HPE" , label:"Hotel Price Explorer" , color:null , external:0 } ,
    ]
  },
  {
    value:"REMARKETING", label:"Remarketing",
    children: [
      { value:"REMARKETING:"        , label:"-"                    , color:null , external:2 } ,
      { value:"REMARKETING:MMC-HPM" , label:"Hotel Push Marketing" , color:null , external:0 } ,
    ]
  },
  {
    value:"WEBMARKETING", label:"Web Marketing",
    children: [
      { value:"WEBMARKETING:"    , label:"-"   , color:null , external:2 } ,
      { value:"WEBMARKETING:SEO" , label:"SEO" , color:null , external:0 } ,
      { value:"WEBMARKETING:SEA" , label:"SEA" , color:null , external:0 } ,
    ]
  },
  {
    value:"CRM", label:"Contact Relation Manager",
    children: [
      { value:"CRM:"            , label:"-"            , color:null , external:2 } ,
      { value:"CRM:MMC-HAPICRM" , label:"HAPI CRM"     , color:null , external:0 } ,
      { value:"CRM:SALESFORCE"  , label:"Salesforce"   , color:null , external:2 } ,
      { value:"CRM:SENDINBLUE"  , label:"Send in Blue" , color:null , external:2 } ,
      { value:"CRM:MAILJET"     , label:"Mailjet"      , color:null , external:2 } ,
    ]
  },
  {
    value:"SHOP", label:"Online Shop",
    children: [
      { value:"SHOP:"             , label:"-"          , color:null , external:2 } ,
      { value:"SHOP:MMC-HAPISHOP" , label:"HAPI Shop"  , color:null , external:0 } ,
      { value:"SHOP:PRESTASHOP"   , label:"Prestashop" , color:null , external:2 } ,
    ]
  },
  {
    value:"REVIEWS", label:"Customer reviews",
    children: [
      { value:"REVIEWS:"                , label:"-"               , color:null , external:1 } ,
      { value:"REVIEWS:QUALITELIS"      , label:"Qualitelis"      , color:null , external:1 } ,
      { value:"REVIEWS:GUESTSUITE"      , label:"GuestSuite"      , color:null , external:1 } ,
      { value:"REVIEWS:CUBILIS"         , label:"Cubilis"         , color:null , external:1 } ,
      { value:"REVIEWS:CUSTOMERALIANCE" , label:"CustomerAliance" , color:null , external:1 } ,
      { value:"REVIEWS:EXPERIENCEHOTEL" , label:"ExperienceHotel" , color:null , external:1 } ,
    ]
  },
  {
    value:"BOOKINGENGINE", label:"Booking Engine",
    children: [
      { value:"BOOKINGENGINE:"                , label:"-"               , color:null , external:1 } ,
      { value:"BOOKINGENGINE:AGODA"           , label:"Agoda"           , color:null , external:1 } ,
      { value:"BOOKINGENGINE:ASIWEBRES"       , label:"AsiWebRes"       , color:null , external:1 } ,
      { value:"BOOKINGENGINE:AVAILPRO"        , label:"Availpro"        , color:null , external:1 } ,
      { value:"BOOKINGENGINE:BESTWESTERN"     , label:"BestWestern"     , color:null , external:1 } ,
      { value:"BOOKINGENGINE:BOOKING"         , label:"Booking"         , color:null , external:1 } ,
      { value:"BOOKINGENGINE:BOOKVISIT"       , label:"Bookvisit"       , color:null , external:1 } ,
      { value:"BOOKINGENGINE:CHOICEHOTELS"    , label:"ChoiceHotels"    , color:null , external:1 } ,
      { value:"BOOKINGENGINE:CITOTEL"         , label:"Citotel"         , color:null , external:1 } ,
      { value:"BOOKINGENGINE:CUBILIS"         , label:"Cubilis"         , color:null , external:1 } ,
      { value:"BOOKINGENGINE:EVOLUTION"       , label:"Evolution"       , color:null , external:1 } ,
      { value:"BOOKINGENGINE:EXPEDIA"         , label:"Expedia"         , color:null , external:1 } ,
      { value:"BOOKINGENGINE:FASTBOOKING"     , label:"Fastbooking"     , color:null , external:1 } ,
      { value:"BOOKINGENGINE:FRONTDESK"       , label:"FrontDesk"       , color:null , external:1 } ,
      { value:"BOOKINGENGINE:GHIX"            , label:"Ghix"            , color:null , external:1 } ,
      { value:"BOOKINGENGINE:HETRAS"          , label:"Hetras"          , color:null , external:1 } ,
      { value:"BOOKINGENGINE:HOTELSCOM"       , label:"HotelsCom"       , color:null , external:1 } ,
      { value:"BOOKINGENGINE:IHG"             , label:"IHG"             , color:null , external:1 } ,
      { value:"BOOKINGENGINE:TRAVELCLICK"     , label:"TravelClick"     , color:null , external:1 } ,
      { value:"BOOKINGENGINE:MEWS"            , label:"Mews"            , color:null , external:1 } ,
      { value:"BOOKINGENGINE:MIRAI"           , label:"Mirai"           , color:null , external:1 } ,
      { value:"BOOKINGENGINE:MISTERBOOKING"   , label:"Misterbooking"   , color:null , external:1 } ,
      { value:"BOOKINGENGINE:MYASTERIO"       , label:"MyAsterio"       , color:null , external:1 } ,
      { value:"BOOKINGENGINE:PROTEL"          , label:"Protel"          , color:null , external:1 } ,
      { value:"BOOKINGENGINE:RESERVIT"        , label:"Reservit"        , color:null , external:1 } ,
      { value:"BOOKINGENGINE:ROIBACK"         , label:"Roiback"         , color:null , external:1 } ,
      { value:"BOOKINGENGINE:ROOMCLOUD"       , label:"Roomcloud"       , color:null , external:1 } ,
      { value:"BOOKINGENGINE:SEEKDA"          , label:"Seekda"          , color:null , external:1 } ,
      { value:"BOOKINGENGINE:SIMPLEBOOKING"   , label:"SimpleBooking"   , color:null , external:1 } ,
      { value:"BOOKINGENGINE:SITEMINDER"      , label:"Siteminder"      , color:null , external:1 } ,
      { value:"BOOKINGENGINE:SYNXIS"          , label:"Synxis"          , color:null , external:1 } ,
      { value:"BOOKINGENGINE:THAISHOTEL"      , label:"ThaisHotel"      , color:null , external:1 } ,
      { value:"BOOKINGENGINE:VERTICALBOOKING" , label:"VerticalBooking" , color:null , external:1 } ,
      { value:"BOOKINGENGINE:WEBHOTELIER"     , label:"WebHotelier"     , color:null , external:1 } ,
      { value:"BOOKINGENGINE:WELLBOOKED"      , label:"Wellbooked"      , color:null , external:1 } ,
      { value:"BOOKINGENGINE:WORLDHOTEL"      , label:"WorldHotel"      , color:null , external:1 } ,
      { value:"BOOKINGENGINE:XOTELIA"         , label:"Xotelia"         , color:null , external:1 } ,
      { value:"BOOKINGENGINE:TRUST"           , label:"Trust"           , color:null , external:1 } ,
    ]
  },
]

export var types  = products.map((type) => type);

export const connectedEngines = (function() {
  let r = [];
  for(let i=0; i<products.length; i++)
  {
    let t = products[i];
    if(t.children)
    {
      for(let j=0; j<t.children.length; j++)
      {
        let ce = {...(t.children[j])};
        r.push(ce);
      }
    }
  }
  return r;
})();

export const externals = [
  { value:0 , label:"Internal"  , color:"green"  } ,
  { value:1 , label:"External"  , color:"orange" } ,
  { value:2 , label:"Concurent" , color:"red"    } ,
]

export const productResolver = (item) => {
  let r = {
      type:{value:null,label:'???'},
      connectedEngine:{value:null,label:'???'},
      external:{value:null,label:'???'},
    }
  if (item.product) {
    let type = item.product.tag ? item.product.tag : null;
    let product = item.product;
    r.type = {value:type.id, label:type.name};

    r.connectedEngine = {value:product.id, label:product.name};
    r.external = {value:product.external, label:product.external==1 ? '(External)' : (product.external==2 ? '(Concurent)': 'Interne')};

    r.resume = r.type.label+' / '+r.connectedEngine.label+' '+r.external.label;
    //console.log(r);
    return r;
  }

  return r
}

export const cascaderProductsFormatter = (tags) => {
  let cascaderData = [];

  tags.map(tag => {
    cascaderData.push({
      value: tag.id,
      label: tag.name,
      children: productsFormatter(tag.products)
    })
  })
  // console.log(cascaderData)
  return cascaderData;
}

const productsFormatter = (products) => {
  let cascaderChildren = []

  products.map(product => {
    cascaderChildren.push({
      value: product['@id'],
      label: product.name
    })
  })
  return cascaderChildren
}

export default products

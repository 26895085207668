import React from "react";
import {Breadcrumb, Card} from "antd";
import {Link} from "react-router-dom";
import SegmentList from "../components/Segments/SegmentList";

const Segments = () => {

  let cardTitle = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Link to="/">Home</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Link to="/segments/">Segments</Link>
      </Breadcrumb.Item>
    </Breadcrumb>
  );

  return (
    <Card title={cardTitle}>
      <SegmentList />
    </Card>
  );

}

export default Segments

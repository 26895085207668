import React from 'react';
import { Link, useLocation } from 'react-router-dom'
import { Menu } from 'antd';

import FaIcon from './FaIcon.js';
import { faHome } from '@fortawesome/pro-solid-svg-icons/faHome'
import { faSignInAlt } from '@fortawesome/pro-solid-svg-icons/faSignInAlt'

const NavPublic = () => {

  let location = useLocation()

  return (
      <Menu
        mode="inline"
        selectedKeys={[location.pathname]}
        >
        <Menu.Item key="/">
          <FaIcon icon={faHome} />
          <span>Home</span>
          <Link to="/"></Link>
        </Menu.Item>
        <Menu.Item key="/login">
          <FaIcon icon={faSignInAlt} />
          <span>Login</span>
          <Link to="/login"></Link>
        </Menu.Item>
      </Menu>
  );
};

export default NavPublic;

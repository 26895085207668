import React  from 'react';
import { Link } from 'react-router-dom'
import { Breadcrumb, Card } from 'antd'

import ProductList from '../components/Product/ProductList.js';

const Products = () => {

  let cardTitle = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Link to="/">Home</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Link to="/products/">Produits</Link>
      </Breadcrumb.Item>
    </Breadcrumb>
  );


  return (
    <>
      <Card title={cardTitle}>
        <ProductList scroll_x={1200} />
      </Card>
    </>
  );
};

export default Products;

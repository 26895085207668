import React, {useEffect, useState} from 'react';
import {useApiContext} from "../providers/ApiProvider";
import {Link, useParams} from "react-router-dom";
import {getLanguage, translate} from "../components/Support/helpers";
import Search from "../components/Support/Frontend/Search";
import {Spin, Typography} from "antd";

const SupportSearch = () => {

  const [apiState, apiDispatch] = useApiContext();
  const {apiFetchCollection} = apiDispatch;
  const params = useParams();
  const lng = getLanguage(params.lng);

  const windowUrl = window.location.search;
  const urlParams = new URLSearchParams(windowUrl);
  const isTag = urlParams.get('tag') === 'true';

  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch();
  }, [params])

  async function fetch() {
    setLoading(true);

    if (isTag) {
      const data = {search: {activeLngs: lng, ['order[ranking]']: 'asc'}, noExtension: true, anonymously: true}
      const tagId = params.query.split('-').shift();
      const response = await apiFetchCollection(`support_tags/${tagId}/articles`, data)
      setArticles(response['hydra:member'])
    } else {
      const productId = params.product.split('-').shift();
      const data = {search: {query: params.query, lng: lng, productId: productId}, noExtension: true, anonymously: true}
      const response = await apiFetchCollection('public/search', data)
      setArticles(response.results)
    }

    setLoading(false);
  }

  return (
    <div>
      <Search/>

      <Spin spinning={loading}>
        <div className="articles-container">

          <div className="articles">
            {articles?.map(article => (
              <div key={article.id} className="article">
                <Link to={`/${lng}/support/${params.product}/article/${article.id}`}>
                  <h2>{translate(article, 'title', lng)}</h2>
                  {translate(article, 'description', lng) ?
                    <p dangerouslySetInnerHTML={{__html: translate(article, 'description', lng)}}/>
                    :
                    <Typography.Paragraph ellipsis={{rows: 2}}>
                      <div dangerouslySetInnerHTML={{__html: article.content.fr}}/>
                    </Typography.Paragraph>}
                </Link>
              </div>
            ))}
          </div>

        </div>
      </Spin>
    </div>
  );
};

export default SupportSearch;

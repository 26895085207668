import React, { useEffect, useState } from 'react';
import { Link, Redirect, useParams } from 'react-router-dom'
import { Breadcrumb, Button, Card, Col, Row, Modal, message } from 'antd'

import FaIcon from '../components/FaIcon.js';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner'
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle'
import { faTrash } from "@fortawesome/pro-solid-svg-icons/faTrash";

import { useApiContext } from '../providers/ApiProvider.js';

import AccountCard from '../components/Account/AccountCard.js';

import ContactDash from '../components/Contact/ContactDash.js';
import PlaceDash from '../components/Place/PlaceDash.js';
import CommentDash from "../components/Comment/CommentDash";

const Account = (props) => {

  const [apiState, apiDispatch] = useApiContext();
  const { apiFetchEntity, apiDeleteEntity } = apiDispatch;

  const [account, setAccount] = useState(null)
  const [loading, setLoading] = useState(true)
  const [redirection, setRedirection] = useState(false);

  const params = useParams();
  useEffect(() => {
    // fetch at startup and whenever needUpdate is changed
    fetchData();
  }, [])

  async function fetchData() {
    console.log('fetching data')
    const account_response = await apiFetchEntity('mmc_accounts', params.account_id);
    if (account_response['@type'] === "hydra:Error") {
      setRedirection(true)
    }
    setAccount(account_response)
    setLoading(false);
  }

  
  const deletePlace = async () => {
    console.log(account)
    const response = await apiDeleteEntity('mmc_accounts', account.id)
    if (response['@type'] === "hydra:Error") {
      message.error('Impossible de suprimmer cet élément')
      return
    }
    message.success('Hotel supprimé avec succes, Vous allez être redirigé dans 3 secondes')
    setTimeout(() => {
      window.location.href = '/accounts';
    }, 3000)

    console.log(response)
  }

  const showDeleteConfirm = () => {
    Modal.confirm({
      title: 'Confirmer la suppression?',
      icon: <FaIcon icon={faExclamationTriangle} />,
      okText: 'Oui',
      okType: 'danger',
      cancelText: 'Non',
      onOk() {
        deletePlace();
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  if (loading)
    return <FaIcon icon={faSpinner} spin />

  if (redirection)
    return <Redirect to={{
      pathname: "/erreur",
      search: "?utm=your+face",
      state: { status: '404', subtitle: 'Cette société n\'existe pas', page: './accounts' }
    }} />


  if (account['@id']) {
    let cardTitle = (
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/accounts/">Société</Link>
        </Breadcrumb.Item>
        {
          account.cluster
            ? <Breadcrumb.Item>
              <Link to={"/cluster/" + account.cluster.id}>{account.cluster.name}</Link>
            </Breadcrumb.Item>
            : null
        }
        <Breadcrumb.Item>
          <Link to={"/account/" + account.id}>{account.name}</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
    );

    const extraActions = (
      <>
        {
          account.sellsyId &&
          <Link to={`/account/${account.id}/estimate`}><Button type="primary">Faire un devis</Button></Link>
        }
        <Button type={"danger"} onClick={showDeleteConfirm}>
          <FaIcon icon={faTrash} />
        </Button>
      </>
    )


    return (
      <div>
        <Card
          title={cardTitle}
          extra={extraActions}
        >
          <Row type="flex" gutter={16}>
            <Col span={16}>
              <Row type="flex" gutter={[16, 16]}>
                <Col span={12}>
                  <AccountCard item={account} onUpdate={updatedItem => setAccount(updatedItem)} />
                </Col>
                <Col span={12}>
                  <PlaceDash account_id={account.id} />
                </Col>
                <Col span={12}>
                  <ContactDash id={account.id} endpoint="mmc_accounts" />
                  {
                    account.cluster ?
                      <ContactDash id={account.cluster.id} endpoint="mmc_clusters"
                        cardTitle={"Contacts " + account.cluster.name} />
                      : null
                  }
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <CommentDash id={account.id} endpoint="mmc_accounts" />
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
  return <FaIcon icon={faExclamationTriangle} />
};

export default Account;

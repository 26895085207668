// Called in ContractDrawer
import React, { useState, useEffect } from 'react';
import { Row, Col, Avatar, Card, Collapse, Skeleton, Modal, Tag, Form, Button } from 'antd'

import FaIcon from '../FaIcon.js';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner'

import { useApiContext } from '../../providers/ApiProvider.js';

import FormInput from '../Form/FormInput.js'
import FormCheckbox from '../Form/FormCheckbox.js'
import FormSelect from '../Form/FormSelect.js'
import FormCascader from '../Form/FormCascader.js'
import FormDatePicker from '../Form/FormDatePicker.js'
import FormSwitch from '../Form/FormSwitch.js'

import _products, {productResolver, cascaderProductsFormatter} from './_products.js'
import moment from 'moment'

const ContractForm = ({item, item_id, place_id, onClose, onChange, modal, ...rest}) => {
  console.log(place_id)
  place_id = place_id || false;
  item_id = item ? item.id : item_id;
  onClose = onClose || function(){};
  onChange = onChange || function(){};

  const [apiState, apiDispatch] = useApiContext();
  const { apiFetchEntity, apiUpdateEntity, apiCreateEntity, apiFetchCollection } = apiDispatch;

  const [_itemId, setItemId ] = useState(item_id)
  const [_item, setItem ] = useState(item ? {...item} : null)
  const [loading, setLoading ] = useState(true)
  const [saving, setSaving ] = useState(false)
  const [cascaderOptions, setCascaderOptions] = useState([])
  const [productsLoading, setProductsLoading] = useState(true)
  useEffect(() => {
    fetchProducts()
    // fetch at startup and whenever needUpdate is changed
    if(_itemId && !_item)
      fetchItem();
    else
      setLoading(false);
  },[])

  async function fetchItem() {
    setLoading(true);
    if(_itemId==='new')
    {
      if(place_id)
        setItem({place:{
          '@id':'/api/mmc_places/'+place_id,
          'id':place_id
        }})
      else
        setItem({})
    }
    else
    {
      const response = await apiFetchEntity('mmc_contracts',_itemId);
      setItem({...response})
    }
    setLoading(false);
    return ;
  }

  async function fetchProducts() {
    const response = await apiFetchCollection('tags', {search: {pagination:false, type:0}})
    setCascaderOptions(cascaderProductsFormatter(response['hydra:member']))
    setProductsLoading(false)
  }

  async function handlePropertyChange(chunk) { // {property:value}
    //console.log(chunk);
    setItem(Object.assign(_item, chunk));
  }

  async function handleProductChange(chunk) { // {product:[type,connectedEngine]}
    if(chunk.product)
    {
      _item.product = typeof chunk.product[1] === 'string' ? chunk.product[1] : null;
      setItem(_item);
    }
  }

  const productCascaderRenderer = (labels, selectedOptions) =>
    labels.map((label, i) => {
      const option = selectedOptions[i];
      if (i === labels.length - 1) {
        return (
          <span key={option.value}>
            {label} {option.external==1 ? '(External)': (option.external==2 ? '(Concurent)': '')}
          </span>
        );
      }
      return <span key={option.value}>{label} / </span>;
    });


  const handleCancel = () => {
    onClose();
  }

  const handleSave = async () => {
    setSaving(true);
    let newValues = {}
    newValues.external = parseInt(_item.external) || 0; // [0,1,2]
    // newValues.name = typeof _item.name==="string" ? _item.name : '';
    // newValues.domainName = typeof _item.domainName==="string" ? _item.domainName : '';
    newValues.reference = typeof _item.reference==="string" ? _item.reference : '';

    newValues.periodicity = parseInt(_item.periodicity) || 0;
    newValues.amount = parseFloat(_item.amount) || 0;

    newValues.subscribedAt = _item.subscribedAt==="" ? null : _item.subscribedAt;
    newValues.endedAt = _item.endedAt==="" ? null : _item.endedAt;

    newValues.description = typeof _item.description==='string' ? _item.description : null

    if (typeof _item.product ==='string')
      newValues.product = _item.product

    let currentDate = moment(new Date()).format('');
    newValues.isActive = !_item.endedAt || _item.endedAt > currentDate;
    if (!_item.isActive)
      newValues.notDetectedDate = null;

    console.log(newValues)
    if(_itemId==='new')
    {
      const response = await apiCreateEntity('mmc_contracts', {..._item, ...newValues});
      if(response && response.id)
      {
        setItemId(response.id)
        setItem({...response})
      }
    }
    else
    {
      console.log(_item)
      let updateResponse = await apiUpdateEntity('mmc_contracts',_itemId,newValues);
      setItem({...updateResponse})
    }
    setSaving(false);
    onChange(Object.assign({},_item));
  }

  const handleSaveAndClose = async () => {
    await handleSave();
    onClose();
  }

  let current_place_id = _item && _item.account ? _item.place.id : place_id;

  const formContent = (
    !_item || loading || saving || productsLoading
    ? <React.Fragment><FaIcon icon={faSpinner} spin /> traitement en cours...</React.Fragment>
    : <Form layout="vertical">

        {/*<Form.Item label="Actif">*/}
        {/*  <FormSwitch name="isActive" value={_item.isActive} onChange={handlePropertyChange}/>*/}
        {/*</Form.Item>*/}

        <Form.Item label="Product type">
          <FormCascader name="product" value={_item.product ? [_item.product.tag.id,_item.product['@id']] : null} onChange={handleProductChange} options={cascaderOptions} showSearch displayRender={productCascaderRenderer} />
        </Form.Item>

        {/*<Form.Item label="Name">*/}
        {/*  <FormInput placeholder="" name="name" value={_item.name} onChange={handlePropertyChange}/>*/}
        {/*</Form.Item>*/}

        {/*<Form.Item label="Domain name">*/}
        {/*  <FormInput placeholder="" name="domainName" value={_item.domainName} onChange={handlePropertyChange}/>*/}
        {/*</Form.Item>*/}

        <Form.Item label="Référence">
          <FormInput placeholder="" name="reference" value={_item.reference} onChange={handlePropertyChange}/>
        </Form.Item>

        <Row type="flex" gutter={16}>
          <Col span={6}>
            <Form.Item label="subscribedAt">
              <FormDatePicker placeholder="" name="subscribedAt" value={_item.subscribedAt} onChange={handlePropertyChange}/>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Periodicity (months)">
              <FormInput placeholder="" name="periodicity" value={_item.periodicity} onChange={handlePropertyChange}/>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Amount (€)">
              <FormInput placeholder="" name="amount" value={_item.amount} onChange={handlePropertyChange}/>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="endedAt">
              <FormDatePicker placeholder="" name="endedAt" value={_item.endedAt} onChange={handlePropertyChange}/>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="Description">
          <FormInput textarea={true} placeholder="" name="description" value={_item.description} onChange={handlePropertyChange}/>
        </Form.Item>

        <Form.Item>
          <Button key="cancel" onClick={handleCancel}>
            Close
          </Button>
          <Button style={{ marginLeft: 8 }} key="save" type="primary" onClick={handleSave}>
            Save
          </Button>
          <Button style={{ marginLeft: 8 }} key="saveAndClose" type="primary" onClick={handleSaveAndClose}>
            Save & Close
          </Button>
        </Form.Item>

      </Form>
  );

  if(modal)
  {
    return <Modal
      title={_item && _item.name ? _item.name : "..."}
      visible={true}
      onCancel={handleCancel}
      footer={null}
      >
      {formContent}
    </Modal>
  }
  else
  {
    return formContent;
  }
};

export default ContractForm;

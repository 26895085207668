import React, { useEffect, useState } from 'react';
import { Spin, TreeSelect } from 'antd';
import { useApiContext } from '../../../providers/ApiProvider';

const { TreeNode } = TreeSelect;
import { useParams } from 'react-router-dom';
import CategoryForm from './CategoryForm';
import FaIcon from '../../FaIcon';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';


export default function CategoriesTreeSelect({ value, onChange, multiple = false }) {

    const [apiState, apiDispatch] = useApiContext();
    const { apiFetchCollection } = apiDispatch;
    const [categories, setCategories] = useState([])
    const [internalValue, setInternalValue] = useState(multiple ? [] : undefined)
    const params = useParams();
    const [needUpdate, setNeedUpdate] = useState(0)
    const [editing, setEditing] = useState(null)
    const [loading, setLoading] = useState(true)
    const productId = params.product.split('-').shift()

    useEffect(() => {
        setInternalValue(value)
    }, [value])

    useEffect(() => {
        fetchCategories();
    }, [needUpdate])

    const fetchCategories = async () => {
        console.log(params)
        setLoading(true)
        let productId = params.product.split('-').shift()
        const response = await apiFetchCollection(`support_products/${productId}/categories`, { search: { "exists[parent]": false, pagination: 'false' } });
        console.log(response)
        setCategories(response['hydra:member'])
        setLoading(false)
    }

    const buildTree = (catArray, baseKey = 0) => {
        return catArray.map((category, index) => {
            let key = baseKey + "-" + index;
            return (
                <TreeNode value={category['@id']} title={category.nameFr} key={key}>
                    {
                        category?.children?.length > 0 && buildTree(category.children, key)
                    }
                </TreeNode>
            )
        })
    }

    const handleChange = (val) => {
        setInternalValue(val)
        onChange(val)
    }

    const handleClose = () => {
        setEditing(null)
        setNeedUpdate((new Date()).getTime())
    }

    return (
        <div>
            <p className='flex between'>Catégorie <span ><FaIcon icon={faPlus} onClick={() => setEditing('new')} /></span></p>
            <Spin spinning={loading} >
                <TreeSelect
                    showSearch
                    treeNodeFilterProp='title'
                    style={{ width: '100%' }}
                    value={internalValue}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    placeholder="Please select"
                    allowClear
                    multiple={multiple}
                    treeDefaultExpandAll
                    onChange={handleChange}
                >
                    {
                        buildTree(categories)
                    }
                </TreeSelect>
            </Spin>
            {
                !!editing &&
                <CategoryForm item_id={editing} modal onClose={handleClose} productIri={`/api/support_products/${productId}`} />
            }
        </div>
    );
}

import React, { useEffect, useRef, useState } from 'react'
import { Button, Row, Col, Card, message, Input, PageHeader } from 'antd'
import CityConditions from './CityConditions'
import CountryCondition from './CountryCondition'
import HasContractConditions from './HasContractConditions'
import NoContractCondition from './NoContractCondition'
import TagsConditions from './TagsConditions'
import StarsConditions from './StarsConditions'
import { useApiContext } from '../../providers/ApiProvider.js';
import { Link, useHistory } from 'react-router-dom'
import FaIcon from '../FaIcon'
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons'


export default function SegmentForm({ segment, setSegment, setLoading }) {

    const [apiState, apiDispatch] = useApiContext();
    const { apiFetchCollection, apiPostRequest, apiCreateEntity, apiUpdateEntity } = apiDispatch;
    const [hasContracts, setHasContracts] = useState(null)
    const [noContracts, setNoContracts] = useState(null)
    const [city, setCity] = useState(null)
    const [country, setCountry] = useState(null)
    const [stars, setStars] = useState(null)
    const [tags, setTags] = useState(null)
    const [internalSegment, setInternalSegment] = useState(segment)
    const [products, setProducts] = useState([])
    const [isSegmentValid, setIsSegmentValid] = useState(false)
    const [results, setResults] = useState(null)
    const isMounted = useRef(null);
    const history = useHistory();

    useEffect(() => {
        if (segment) {
            setInternalSegment(segment)
            setHasContracts(segment?.data?.hasContracts || null)
            setNoContracts(segment?.data?.noContract || null)
            setCity(segment?.data?.city || null)
            setCountry(segment?.data?.country || null)
            setStars(segment?.data?.stars || null)
            setTags(segment?.data?.tags || null)
        }
    }, [segment])

    useEffect(() => {
        isMounted.current = true;

        fetchProducts()
        return () => {
            // executed when unmount
            isMounted.current = false;
        }
    }, [])

    useEffect(() => {
        handleSubmit()
    }, [hasContracts, noContracts, city, stars, tags, country])

    const fetchProducts = async () => {
        setLoading(true)
        let params = {
            itemsPerPage: 150,
            page: 0,
        };

        let hydra_response = await apiFetchCollection('products', params);
        if (isMounted.current) {

            if (hydra_response) {
                let data = hydra_response['hydra:member'];
                setProducts(data);
            }
            setLoading(false);
        }
    }


    const handleSubmit = () => {
        let finishConditions = {}
        finishConditions.hasContracts = hasContracts && hasContracts.length > 0 ? [...hasContracts.filter(item => item.id !== null)] : []
        finishConditions.noContracts = noContracts ? noContracts : []
        finishConditions.tags = tags ? tags : { queryTags: [] }
        finishConditions.stars = stars ? stars : []
        if (city && city.city)
            finishConditions.city = city

        if (country && country.country)
            finishConditions.country = country

        setResults(null)
        checkSegment(finishConditions)
        setInternalSegment({ ...internalSegment, data: finishConditions })
    }

    const checkSegment = (segmentCondition) => {
        let isValid = true;
        if (
            (!segmentCondition.hasContracts || segmentCondition.hasContracts.length === 0) &&
            (!segmentCondition.noContracts || segmentCondition.noContracts.length === 0) &&
            (!segmentCondition.tags || segmentCondition.tags.queryTags.length === 0) &&
            (!segmentCondition.stars || segmentCondition.stars.length === 0)
        ) {
            console.log('noValid')
            isValid = false
        }
        setIsSegmentValid(isValid)
    }

    const checkResults = async () => {
        let results = await apiPostRequest('check-segment', { segment: internalSegment.data })
        setResults(results)
    }

    const addHasContract = () => {
        const newContract = { id: null, status: null, key: (new Date()).getTime() }
        const hasNullContract = hasContracts && hasContracts.find(item => item.id === null)
        if (hasNullContract) return message.warning('Compléter les contrats non rempli')
        setHasContracts(prevState => !prevState ? [newContract] : [...prevState, newContract])
    }

    const saveSegment = async (value) => {
        setLoading(true)
        let name = value ? value.trim() : null
        if (!name) return message.error('Veuillez compléter le nom de la liste')
        const listResponse = await apiPostRequest('mailjet/create-list', { name: name })

        if (listResponse.status === 0) {
            setLoading(false)
            return message.error(`Il y a eu une erreur lors de la création de la liste : "${listResponse.message}"`)
        } else {
            let params = { name: name, data: internalSegment.data, mailjetId: listResponse.list.ID + '' }
            const response = await apiCreateEntity('segments', params)

            if (response.id) {
                setSegment(response)
                message.success('Segment crée avec success')
            } else {
                message.error('Il y a eu une erreur sur la création du segment')
            }
            setLoading(false)
        }
    }

    const updateSegment = async () => {
        setLoading(true)
        const response = await apiUpdateEntity('segments', internalSegment.id, { data: internalSegment.data })
        if (response.id) {
            message.success('Segment mis à jour avec succes')
            setSegment(response)
        }
        setLoading(false)
    }

    return (
        <div>
            <PageHeader
                onBack={() => history.goBack()}
                backIcon={<FaIcon icon={faArrowLeft} />}
                title={segment.id ? `Editer le segment : ${segment.name}` : 'Créer un segment'}
            />
            <Row gutter={16}>
                <Col span={16}>
                    <div>
                        {!hasContracts && <Button className="mr-1" onClick={addHasContract}>Contract présent</Button>}
                        {!noContracts && <Button className="mr-1" onClick={() => setNoContracts([])}>Contract absent</Button>}
                        {!city && <Button className="mr-1" onClick={() => setCity({})}>Ville</Button>}
                        {!country && <Button className="mr-1" onClick={() => setCountry({ country: null })}>Pays</Button>}
                        {!tags && <Button className="mr-1" onClick={() => setTags({ condition: 'avec', queryTags: [] })}>Tags</Button>}
                        {!stars && <Button className="mr-1" onClick={() => setStars([])}>Etoiles</Button>}
                    </div>
                    <div>
                        {
                            <HasContractConditions contracts={hasContracts} setContracts={setHasContracts} addHasContract={addHasContract} />
                        }
                        {
                            <NoContractCondition contracts={noContracts} setContracts={setNoContracts} />
                        }
                        {
                            <CityConditions city={city} setCity={setCity} />
                        }
                        {
                            <CountryCondition country={country} setCountry={setCountry} />
                        }
                        {
                            <TagsConditions tags={tags} setTags={setTags} />
                        }
                        {
                            <StarsConditions stars={stars} setStars={setStars} />
                        }
                    </div>
                </Col>
                <Col span={8}>
                    <Card title="Résumé du segment">
                        {
                            isSegmentValid ?
                                <div>
                                    <div>
                                        Sélectionne tous les hotels
                                        <ul>
                                            {
                                                internalSegment.data.hasContracts && internalSegment.data.hasContracts.length > 0 &&
                                                internalSegment.data.hasContracts.map(item => <li key={`has_${item.id}`}>qui ont un contrat "{item.name}"
                                                    {
                                                        item?.months &&
                                                        `qui a ${item.status === 'start' ? 'qui a démarré ' : 'qui s\'est terminé '}
                                                        ${item.since === 'more' ? 'depuis plus ' : 'depuis moins '} de ${item.months} mois.`
                                                    }
                                                </li>
                                                )
                                            }
                                            {
                                                internalSegment.data.noContracts && internalSegment.data.noContracts.length > 0 &&
                                                internalSegment.data.noContracts.map(item => <li key={`no_${item}`}>qui n'ont pas de contrat "{products.find(product => product.id === item)?.name}" </li>)
                                            }
                                            {
                                                internalSegment.data.city && internalSegment.data.city.city &&
                                                <li key={`city`}>qui sont dans la ville: "{internalSegment.data.city.city}" {internalSegment.data.city?.radius > 0 && ` et dans un rayon de ${internalSegment.data.city.radius}km`} </li>
                                            }
                                            {
                                                internalSegment.data.country && internalSegment.data.country.country &&
                                                <li key={`country`}>qui sont dans le pays: "{internalSegment.data.country.country}"</li>
                                            }
                                            {
                                                internalSegment.data.tags && internalSegment.data.tags.queryTags.length > 0 &&
                                                <li key="tags">
                                                    qui {internalSegment.data.tags.condition === 'with' ? 'ont' : 'n\'ont pas'} les tags suivant: {
                                                        internalSegment.data.tags.queryTags.map((item, index) => `${index > 0 ? ', ' : ''}"${item}"`)
                                                    }
                                                </li>
                                            }
                                            {
                                                internalSegment.data.stars && internalSegment.data.stars.length > 0 &&
                                                <li key="stars">
                                                    qui ont : {
                                                        internalSegment.data.stars.map((item, index) => `${index > 0 ? ', ' : ''}"${item}"`)
                                                    } étoiles
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                    {
                                        !results ?
                                            <Button onClick={checkResults}>Verifier les résultats</Button>
                                            : <div>
                                                {results.status === 'succes' &&
                                                    <>
                                                        <h4>Nombre de résultat : {results.totalItems}</h4>
                                                        <div>
                                                            <p><small>Exemple de résultats:</small></p>
                                                            {
                                                                results.places.map(item => <p key={item.id}><Link target="_blank" to={`/place/${item.id}`}>{item.name}</Link></p>)
                                                            }
                                                        </div>
                                                        {
                                                            internalSegment.mailjetId ?
                                                                <Button onClick={updateSegment}>Mettre à jour le segment</Button>
                                                                :
                                                                <div>
                                                                    Créer votre segment :
                                                                    <Input.Search placeholder="Nom de cette liste"
                                                                        enterButton="Créer"
                                                                        onSearch={saveSegment} />
                                                                </div>
                                                        }
                                                    </>
                                                }
                                            </div>
                                    }
                                </div>
                                :
                                <div>Votre segment n'est pas valide, ajouter des conditions.</div>
                        }
                    </Card>
                </Col>
            </Row>
        </div>

    )
}

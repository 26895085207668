const commentRelations = [
  {endpoint: 'mmc_accounts', link: 'account'},
  {endpoint: 'mmc_places', link: 'place'},
  {endpoint: 'mmc_clusters', link: 'cluster'}
]

export const commentRelationResolver = (item) => {
    if (item.place)
      return {endpoint: 'mmc_places', link: 'place', id: item.place.id, name: 'Hotel'}

    if (item.account)
      return {endpoint: 'mmc_accounts', link: 'account', id: item.account.id, name: 'Société'}

    if (item.cluster)
      return {endpoint: 'mmc_clusters', link: 'cluster', id: item.cluster.id, name: 'Groupe'}

    return null

}

export const externalOptions = [
  {label: 'Interne', value: 0},
  {label: 'Externe', value: 1},
  {label: 'Concurent', value: 2},
]


export const externalFormatter = (item, mode = null) => {
  let result = externalOptions.filter(option => option.value === item)[0]
  return mode ? result.label : result
}

import React, { useState, useEffect } from 'react';
import { Upload, message } from 'antd';

import FaIcon from '../FaIcon.js';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons/faSpinner'
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons/faPlusCircle'

import { useApiContext } from '../../providers/ApiProvider.js';

const AvatarUploader = ({imageUrl, onUpload}) => {

  const [loading, setLoading] = useState(false);

  const [apiState, apiDispatch] = useApiContext();
  const { apiPostMediaObject } = apiDispatch;

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const beforeUpload = async (file) => {
    const mimeTypeCheck = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
    if (!mimeTypeCheck) {
      message.error('You can only upload JPG/PNG file!');
    }
    const sizeCheck = file.size / 1024 / 1024 < 2;
    if (!sizeCheck) {
      message.error('Image must smaller than 2MB!');
    }
    if(mimeTypeCheck && sizeCheck)
    {
      setLoading(true);
      onUpload(await apiPostMediaObject(file));
    }
    return false;
  }

  useEffect(() => {
    setLoading(false);
  },[imageUrl])

  const uploadButton = (
    <div>
      {loading ? <FaIcon icon={faSpinner} spin/> : <FaIcon icon={faPlusCircle} />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );
  return (
    <Upload
      name="file"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      beforeUpload={beforeUpload}
    >
      {!loading && imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
    </Upload>
  );
}

export default AvatarUploader;

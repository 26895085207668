import React, {useEffect, useState} from "react";

import {Input, Spin, PageHeader, Button, Modal, collapse, Collapse} from 'antd'
import ScrapperCard from "./ScrapperCard";
import {parseUrl} from "./_parser";
import {useApiContext} from "../../providers/ApiProvider";
import moment from "moment";

const {Search} = Input;


const ScrapperDash = ({modal, isModalVisible, place = null, onClose = () => {}}) => {

  const [apiState, apiDispatch] = useApiContext();
  const {testApiScrap} = apiDispatch;
  const [results, setResults] = useState(null)
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState(null)


  const scrapPlace = async () => {
    setResults(null)
    setLoading(true)
    setStatus('Le scrap est en cours')
    let result = await testApiScrap('mmc_places', place.id);
    console.log(result)
    setResults(result)
    setLoading(false)

  }

  const scrapperContent = (
    <Spin spinning={false}>
      {
        !modal ?
          <PageHeader
            ghost={false}
            title="Scrapper"
            subTitle="Recupérer les informations à partir d'une url"
          >
          </PageHeader>

          : null
      }

      <div style={{margin: '1em'}}>
        {place ?
          <>
            <p>Dernier scrap : {place.lastScrap ? moment(place.lastScrap).format('DD-MM-YY') : 'never'} </p>
            <p>Website : <a href={place.website} target="_blank">{place.website}</a></p>
            {
              place.scrapResult ?
                <Collapse style={{marginBottom: '1em'}}>
                  <Collapse.Panel key={'scrapResults'} header={'Dernier résultats :'} >
                    <ScrapperCard results={place.scrapResult}/>
                  </Collapse.Panel>
                </Collapse>
                : null
            }
            <Button type="primary"
                    loading={loading}
                    onClick={scrapPlace}>Analyser</Button>
          </>
          : null
        }
        {
          loading && status ?
            <p>Etat : {status}</p> : null
        }
      </div>
      {results ?
        <ScrapperCard results={results}/>
        : null
      }
    </Spin>
  )

  if (modal) {
    return <Modal
      title={place ? "Scrapper " + place.name : "Scrapper"}
      visible={isModalVisible}
      onCancel={onClose}
      footer={null}
    >
      {scrapperContent}
    </Modal>
  } else {
    return scrapperContent;
  }
}


export default ScrapperDash;

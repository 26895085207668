import React from 'react';
import FaIcon from '../FaIcon.js';
import { faPhoneRotary } from '@fortawesome/pro-solid-svg-icons/faPhoneRotary'
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope'
import { faLink } from '@fortawesome/pro-solid-svg-icons/faLink'

export const placeResume = (item) => {
  if(item && item.id)
  {
    return <React.Fragment>
      <p>
        <strong>{item.name}</strong><br />
        {item.address}, {item.zip}<br />
        {item.city}, {item.country} <small>({item.countryCode})</small>
        <br />
        {item.phone   ? <React.Fragment><FaIcon icon={faPhoneRotary} /> {item.phone}<br /></React.Fragment> : null}
        {item.email   ? <React.Fragment><FaIcon icon={faEnvelope} /> {item.email}<br /></React.Fragment> : null}
        {item.website ? <React.Fragment><FaIcon icon={faLink} /> <a href={item.website} target="_blank">{item.website.replace(/^(https?:\/\/(www.)?)?([^/]+)(\/.*)$/,'$3')}</a><br /></React.Fragment> : null}
      </p>
    </React.Fragment>;
  }
  return null;
}

export default { placeResume }

export const distanceValues = [
  {value:'25', label:'25km'},
  {value:'50', label:'50km'},
  {value:'100', label:'100km'},
  {value:'150', label:'150km'}
]


export const itemResolver = (customSearch) => {
  // console.log(customSearch)
  let withoutProducts = typeof customSearch.withoutProducts === 'string' && customSearch.withoutProducts !=='' ? customSearch.withoutProducts.split(',') : null
  let withProducts = typeof customSearch.withProducts === 'string' && customSearch.withProducts !==''? customSearch.withProducts.split(',') : null
  let tags = typeof customSearch.tags === 'string' && customSearch.tags !==''? customSearch.tags.split(',') : null

  return {...customSearch,withoutProducts, withProducts, tags}
}

import React, {useState} from "react";
import {Button, Form, message, Modal} from "antd";
import FormSelect from "../Form/FormSelect";
import {useApiContext} from "../../providers/ApiProvider";

export default function PlaceLinkAccount({modal, onClose, account_iri}) {

  onClose = onClose || function(){};
  const [selectedPlace, setSelectedPlace] =useState(null)

  const [apiState, apiDispatch] = useApiContext();
  const { apiUpdateEntity } = apiDispatch;

  const handleCancel = () => {
    onClose();
  }

  const handleSaveAndClose = async () => {

    if (!selectedPlace || !account_iri) {
      return message.error('aucun hotel sélectionné ou le compte n\'est pas fourni')
    }
    let params = {
      account : account_iri
    }
    await updatePlace(params)
    onClose()

  }


  const handleChange = (item) => {
    console.log(item)
    setSelectedPlace(typeof item.place === 'object' ? item.place.id: item.place)
  }

  const updatePlace = async (params) => {
    let result = await apiUpdateEntity('mmc_places', selectedPlace, params)
    if (result['@type'] === 'hydra:Error') {
      return message.error('Il y a eu une erreur')
    }
    return message.success('Compte mis à jour avec succes')
  }

  const content = (
    <>
      <FormSelect options_endpoint={'mmc_places'} search_property={'name'}
                  name={"place"}
                  style={{width: '250px', marginBottom: '1em'}} onChange={handleChange}
                  option_formater={(item) => { return {value:item['id'], label:item.name}; }} />
      <div>
        <Button key="cancel" onClick={handleCancel}>
          Close
        </Button>
        <Button style={{ marginLeft: 8 }} key="saveAndClose" type="primary" onClick={handleSaveAndClose}>
          Save & Close
        </Button>
      </div>

    </>
  )

  if(modal) {
    return (
      <Modal
        title={"Lié un hotel "}
        visible={true}
        onCancel={handleCancel}
        footer={null}
      >
        {content}
      </Modal>
    )
  }
  else {
    return content
  }
}
